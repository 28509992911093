import { Box, SwipeableDrawer } from "@mui/material";

import React from "react";
import { useSwipeableDrawer } from "context/SwipeableDrawerContext/SwipeableDrawerContext";

const GlobalSwipeableDrawer: React.FC = () => {
  const { isDrawerOpen, drawerContent, closeDrawer } = useSwipeableDrawer();

  return (
    <>
      <Box
        sx={{
          transition: "opacity 0.3s ease",
          opacity: isDrawerOpen ? 1 : 0,
          pointerEvents: isDrawerOpen ? "auto" : "none",
        }}
        onClick={closeDrawer}
      />

      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={closeDrawer}
        onOpen={() => {}}
        // Disable the default backdrop
        ModalProps={{
          BackdropProps: {
            style: { backgroundColor: "transparent" },
          },
        }}
        sx={{
          zIndex: 1300, // Position above AppBar// Position above overlay and AppBar
          "& .MuiDrawer-paper": {
            width: {
              xs: "100%",
              sm: "450px",
            },
            boxSizing: "border-box",
          },
        }}
      >
        <Box>{drawerContent}</Box>
      </SwipeableDrawer>
    </>
  );
};

export default GlobalSwipeableDrawer;
