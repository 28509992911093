import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

import { AuthenticationGuard } from "./GuardedRoute";
import Loader from "components/Loader/Loader"; // Assuming you have a Loader component

const AddNewRFQItemMobilePage = lazy(
  () => import("features/RFQ/pages/AddNewRFQItemMobilePage")
);
const AppSettingsPage = lazy(
  () => import("features/AppSettings/pages/AppSettingsPage")
);
const DashboardPage = lazy(
  () => import("features/Dashboard/pages/DashboardPage")
);
const ErrorPage = lazy(() => import("pages/ErrorPage/ErrorPage"));
const LowStockPage = lazy(() => import("features/Stock/pages/LowStockPage"));

const OrderPage = lazy(() => import("features/Orders/pages/OrderPage"));
const OrderReportsPage = lazy(
  () => import("features/Reports/pages/OrderReportsPage")
);
const OrdersPage = lazy(() => import("features/Orders/pages/OrdersPage"));
const RFQPage = lazy(() => import("features/RFQ/pages/RFQPage"));
const RFQReceivedPage = lazy(
  () => import("features/RFQ/pages/RFQReceivedPage")
);
const RFQsPage = lazy(() => import("features/RFQ/pages/RFQsPage"));
const StaffSchedulePage = lazy(
  () => import("features/StaffSchedule/pages/StaffSchedulePage")
);
const StockDetailsPage = lazy(
  () => import("features/Stock/pages/StockDetailsPage")
);
const StockFolderPage = lazy(
  () => import("features/Stock/pages/StockFolderPage")
);
const StockMobileSearchPage = lazy(
  () => import("features/Stock/pages/StockMobileSearchPage")
);
const StockPage = lazy(() => import("features/Stock/pages/StockPage"));
const StockAuditSummariesPage = lazy(
  () => import("features/Stock/pages/StockAuditSummariesPage")
);
const StockAuditPage = lazy(
  () => import("features/Stock/pages/StockAuditPage")
);
const StockReportsPage = lazy(
  () => import("features/Reports/pages/StockReportsPage")
);
const StockSearchPage = lazy(
  () => import("features/Stock/pages/StockSearchPage")
);
const StockServerSearchResultsPage = lazy(
  () => import("features/Stock/pages/StockServerSearchResultsPage")
);
const UserPage = lazy(() => import("features/User/pages/UsersPage"));
const VendorPage = lazy(() => import("features/Vendors/pages/VendorPage"));
const VendorsPage = lazy(() => import("features/Vendors/pages/VendorsPage"));

const BudgetPage = lazy(() => import("features/Budget/pages/BudgetPage"));
const ManageBudgetPage = lazy(
  () => import("features/Budget/pages/ManageBudgetPage")
);

const RoutesComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* TABLET, DESKTOP */}
        <Route
          path="/"
          element={<AuthenticationGuard component={DashboardPage} />}
        />
        <Route path="/callback" element={<DashboardPage />} />
        {/* <Route
          path="/callback"
          element={<AuthenticationGuard component={DashboardPage} />}
        /> */}
        <Route
          path="/dashboard"
          element={<AuthenticationGuard component={DashboardPage} />}
        />

        <Route
          path="orders"
          element={<AuthenticationGuard component={OrdersPage} />}
        />
        <Route
          path="orders/:id"
          element={<AuthenticationGuard component={OrderPage} />}
        />

        <Route
          path="rfqs"
          element={<AuthenticationGuard component={RFQsPage} />}
        />
        <Route
          path="rfqs/:id"
          element={<AuthenticationGuard component={RFQPage} />}
        />
        <Route
          path="rfqs/:id/received"
          element={<AuthenticationGuard component={RFQReceivedPage} />}
        />

        <Route
          path="stock"
          element={<AuthenticationGuard component={StockPage} />}
        />
        <Route
          path="stock-audits"
          element={<AuthenticationGuard component={StockAuditSummariesPage} />}
        />
        <Route
          path="stock-audits/:id"
          element={<AuthenticationGuard component={StockAuditPage} />}
        />

        <Route
          path="stock/search/"
          element={<AuthenticationGuard component={StockSearchPage} />}
        />

        <Route
          path="stock/search/:searchTerm"
          element={
            <AuthenticationGuard component={StockServerSearchResultsPage} />
          }
        />
        <Route
          path="stock/item/:id"
          element={<AuthenticationGuard component={StockDetailsPage} />}
        />
        <Route
          path="stock/:name/:id"
          element={<AuthenticationGuard component={StockFolderPage} />}
        />
        <Route
          path="low-stock"
          element={<AuthenticationGuard component={LowStockPage} />}
        />

        <Route
          path="vendors"
          element={<AuthenticationGuard component={VendorsPage} />}
        />
        <Route
          path="vendors/:id"
          element={<AuthenticationGuard component={VendorPage} />}
        />

        <Route
          path="reports-stock"
          element={<AuthenticationGuard component={StockReportsPage} />}
        />
        <Route
          path="reports-orders"
          element={<AuthenticationGuard component={OrderReportsPage} />}
        />

        <Route
          path="app-settings"
          element={<AuthenticationGuard component={AppSettingsPage} />}
        />
        <Route
          path="user"
          element={<AuthenticationGuard component={UserPage} />}
        />

        <Route
          path="error/:errorCode"
          element={<AuthenticationGuard component={ErrorPage} />}
        />
        <Route
          path="*"
          element={<AuthenticationGuard component={ErrorPage} />}
        />
        <Route
          path="staff-schedule"
          element={<AuthenticationGuard component={StaffSchedulePage} />}
        />

        <Route
          path="budget"
          element={<AuthenticationGuard component={BudgetPage} />}
        />

        <Route
          path="budget/manage-budget"
          element={<AuthenticationGuard component={ManageBudgetPage} />}
        />

        {/* MOBILE ROUTES */}
        <Route
          path="/mobile/stock-search"
          element={<AuthenticationGuard component={StockMobileSearchPage} />}
        />
        <Route
          path="/mobile/add-rfq-item/:id"
          element={<AuthenticationGuard component={AddNewRFQItemMobilePage} />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
