import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

interface LoaderProps {
  message?: string; // Optional loading message
}

const Loader = ({ message }: LoaderProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(0, 0, 0, 0.7)"
            : "rgba(255, 255, 255, 0.8)", // Adjust overlay color based on theme
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <CircularProgress />
        {message && (
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Loader;
