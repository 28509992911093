import AuthenticationErrorPage from "pages/AuthenticationErrorPage/AuthenticationErrorPage";
import CacheBuster from "react-cache-buster";
import CssBaseline from "@mui/material/CssBaseline";
import DemoRegisterPage from "features/DemoRegister/pages/DemoRegister";
import { DialogProvider } from "context/DialogContext/DialogContext";
import GenericDialog from "components/Dialog/GenericDialog";
import GlobalSwipeableDrawer from "components/GlobalSwipeableDrawer/GlobalSwipeableDrawer";
import Loader from "components/Loader/Loader";
import { LocaleProvider } from "context/LocaleContext/LocaleContext";
import LoginWithAuth0 from "components/LoginWithAuth0/LoginWithAuth0";
import MiniDrawer from "components/Drawer/Drawer";
import RoutesComponent from "routes/Routes";
import { SelectedStockItemProvider } from "modules/Stock/context/SelectedStockItemContext";
import { SwipeableDrawerProvider } from "context/SwipeableDrawerContext/SwipeableDrawerContext";
import { TenantProvider } from "modules/User/context/TenantContext";
import { ThemeProvider } from "@mui/material";
import { UserProvider } from "modules/User/context/UserContext";
import VendorRFQResponsePage from "features/VendorRFQResponse/pages/VendorRFQResponsePage";
import packageJson from "../../../package.json";
import { theme } from "styles/theme";
import { useAuth0 } from "@auth0/auth0-react";

const App = () => {
  const version = packageJson.version;
  const isProduction = process.env.NODE_ENV === "production";

  const path = window.location.pathname.split("/")[1];
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <AuthenticationErrorPage />;
  }

  if (path === "rfq") {
    // Unauthenticated users (in this case vendors are responding to RFQ) can view the VendorRFQResponsePage
    return <VendorRFQResponsePage />;
  }

  if (path === "demo-register") {
    // Unauthenticated users can view the DemoRegisterPage (New user onboarding registration)
    return <DemoRegisterPage />;
  }

  if (!isAuthenticated) {
    return <LoginWithAuth0 />;
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <ThemeProvider theme={theme}>
        <LocaleProvider>
          <TenantProvider>
            <UserProvider>
              <SwipeableDrawerProvider>
                <DialogProvider>
                  <SelectedStockItemProvider>
                    <MiniDrawer>
                      <CssBaseline />
                      <RoutesComponent />
                      <GenericDialog />
                    </MiniDrawer>
                    <GlobalSwipeableDrawer />
                  </SelectedStockItemProvider>
                </DialogProvider>
              </SwipeableDrawerProvider>
            </UserProvider>
          </TenantProvider>
        </LocaleProvider>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
