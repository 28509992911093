import { Box, Grid } from "@mui/material";

import ArchivedFilter from "./ArchivedFilter/ArchivedFilter";
import FolderFilter from "./FolderFilter/FolderFilter";
import LowStockFilter from "./LowStockFilter/LowStockFilter";
import QuantityFilter from "./QuantityFilter/QuantityFilter";

interface IStockFiltersProps {
  visible?: boolean;
  isMobile?: boolean;
}

const StockFilters = ({
  visible = true,
  isMobile = false,
}: IStockFiltersProps) => {
  if (!visible) return null;

  // For desktop, we keep the original row layout
  if (!isMobile) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FolderFilter />
          <ArchivedFilter />
          <QuantityFilter />
          <LowStockFilter />
        </Box>
      </Box>
    );
  }

  // For mobile, implement the two-column layout
  return (
    <Grid container spacing={2}>
      {/* First column: Folder and Quantity filters */}
      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <FolderFilter />
          </Box>
          <Box sx={{ width: "100%" }}>
            <QuantityFilter />
          </Box>
        </Box>
      </Grid>

      {/* Second column: Status and Low Stock filters */}
      <Grid item xs={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ArchivedFilter />
          </Box>
          <Box sx={{ width: "100%" }}>
            <LowStockFilter />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StockFilters;
