import ToggleFilter from "../ToggleFilter/ToggleFilter";

const ArchivedFilter = () => {
  return (
    <ToggleFilter
      label="Status"
      paramName="isArchived"
      activeLabel="Archived"
    />
  );
};

export default ArchivedFilter;
