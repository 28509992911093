import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  FilterState,
  StockPageViewType,
  useStockStore,
} from "modules/Stock/store/useStockStore";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import MobileRFQAppBarVerticalMenu from "modules/RFQ/components/MobileRFQAppBarVerticalMenu/MobileRFQAppBarVerticalMenu";
import MobileRFQsAppBarVerticalMenu from "modules/RFQ/components/MobileRFQsAppBarVerticalMenu/MobileRFQsAppBarVerticalMenu";
import MobileStockAppBarVerticalMenu from "modules/Stock/components/MobileStockAppBarVerticalMenu/MobileStockAppBarVerticalMenu";
import MobileStockAuditsAppBarVerticalMenu from "modules/Stock/components/MobileStockAuditsAppBarVerticalMenu/MobileStockAuditsAppBarVerticalMenu";
import MobileVendorsAppBarVerticalMenu from "modules/Vendors/components/MobileVendorsAppBarVerticalMenu/MobileVendorsAppBarVerticalMenu";
import SearchBox from "modules/Stock/components/SearchBox/SearchBox";
import SearchIcon from "@mui/icons-material/Search";
import StockFilters from "modules/Stock/components/Filters/StockFilters";
import colors from "styles/colors";
import { displayPathName } from "./utils";
import { useState } from "react";

interface IMobileAppToolbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const MobileAppToolbar = ({
  open,
  handleDrawerOpen,
}: IMobileAppToolbarProps) => {
  const location = useLocation();
  const [_, setSearchParams] = useSearchParams();
  const { pathname } = location;
  const navigate = useNavigate();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const {
    filters,
    setSearchResults,
    setView,
    setSearchBoxInput,
    setFilters,
    folders,
  } = useStockStore();

  const displayName = displayPathName(pathname);

  // Calculate the number of active filters
  const getActiveFiltersCount = (): number => {
    let count = 0;

    // Check parentId (if it's a string with content)
    if (filters.parentId) {
      count++;
    }

    // Check isArchived (if it's true)
    if (filters.isArchived === true) {
      count++;
    }

    // Check isLowStock (if it's true)
    if (filters.isLowStock === true) {
      count++;
    }

    // Check quantityLessOrEqualTo (if it has a value)
    if (filters.quantityLessOrEqualTo !== undefined) {
      count++;
    }

    return count;
  };

  // Get active filter chips data
  // Check if this was more like a halucination than a proper implementation
  // I don't think we need to push but let's see

  const getActiveFilterChips = () => {
    const activeFilters = [];

    if (filters.parentId) {
      const folder = folders.find((f) => f.id === filters.parentId);
      activeFilters.push({
        key: "parentId",
        label: `Folder: ${folder?.name || "Selected"}`,
      });
    }

    if (filters.isArchived === true) {
      activeFilters.push({
        key: "isArchived",
        label: "Archived",
      });
    }

    if (filters.isLowStock === true) {
      activeFilters.push({
        key: "isLowStock",
        label: "Low Stock",
      });
    }

    if (filters.quantityLessOrEqualTo !== undefined) {
      activeFilters.push({
        key: "quantityLessOrEqualTo",
        label: `Qty ≤ ${filters.quantityLessOrEqualTo}`,
      });
    }

    return activeFilters;
  };

  const activeFilterChips = getActiveFilterChips();
  const activeFiltersCount = activeFilterChips.length;
  const hasActiveFilters = activeFiltersCount > 0;

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const handleCloseSearch = () => {
    setSearchResults([]);
    setSearchParams((prev) => {
      prev.delete("search");
      return prev;
    });
    setView(StockPageViewType.FOLDERS);
    setIsSearchVisible(false);
    setIsFilterDrawerOpen(false);
    setSearchBoxInput("");
  };

  const handleCloseFilters = () => {
    setIsFilterDrawerOpen(false);
  };

  const clearAllFilters = () => {
    setFilters({});
    setSearchParams((prev) => {
      // Keep search parameter if it exists
      const searchTerm = prev.get("search");
      const newParams = new URLSearchParams();
      if (searchTerm) {
        newParams.set("search", searchTerm);
      }
      return newParams;
    });

    setIsFilterDrawerOpen(false);
  };

  // Remove a specific filter
  const removeFilter = (filterKey: keyof FilterState) => {
    const newFilters = { ...filters };
    delete newFilters[filterKey];
    setFilters(newFilters);

    // Update URL params if needed
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete(filterKey);
      return newParams;
    });
  };

  const renderDefaultToolbar = (extraElements?: React.ReactNode) => (
    <>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex">
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography color={colors.black} variant="h3" alignSelf="center">
            {displayName}
          </Typography>
        </Box>
        {extraElements}
      </Toolbar>
    </>
  );

  // Custom Stock toolbar with search functionality
  const renderStockToolbar = () => (
    <>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {!isSearchVisible && (
          <>
            <Box display="flex">
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ marginRight: 1 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography color={colors.black} variant="h3" alignSelf="center">
                {displayName}
              </Typography>
            </Box>
            <Box display="flex">
              <IconButton
                onClick={toggleSearch}
                color={isSearchVisible ? "primary" : "default"}
              >
                <SearchIcon />
              </IconButton>
              <MobileStockAppBarVerticalMenu />
            </Box>
          </>
        )}
        {isSearchVisible && (
          <>
            <IconButton onClick={handleCloseSearch}>
              <ArrowBackIcon />
            </IconButton>
            <Box sx={{ flex: 1, mx: 1 }}>
              <SearchBox autoFocus hideBackButton />
            </Box>
            <IconButton
              onClick={toggleFilterDrawer}
              color={isFilterDrawerOpen ? "primary" : "default"}
            >
              <Badge
                badgeContent={activeFiltersCount}
                color="primary"
                invisible={!hasActiveFilters}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "0.7rem",
                    height: "18px",
                    minWidth: "18px",
                  },
                }}
              >
                <FilterListIcon />
              </Badge>
            </IconButton>
          </>
        )}
      </Toolbar>

      <Drawer
        anchor="bottom"
        open={isFilterDrawerOpen}
        onClose={handleCloseFilters}
        PaperProps={{
          sx: {
            maxHeight: "80vh",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            pb: 2,
          },
        }}
      >
        <Box sx={{ p: 2, borderBottom: "1px solid", borderColor: "divider" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              Filters {hasActiveFilters && `(${activeFiltersCount})`}
            </Typography>

            {hasActiveFilters ? (
              <Button color="secondary" onClick={clearAllFilters} size="small">
                Clear
              </Button>
            ) : (
              <IconButton onClick={handleCloseFilters}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {/* Active Filter Chips: These are great, leaving for future reference but can be deleted then */}
        {/* {hasActiveFilters && (
          <Box
            sx={{ px: 2, py: 1.5, display: "flex", flexWrap: "wrap", gap: 1 }}
          >
            {activeFilterChips.map((chip) => (
              <Chip
                key={chip.key}
                label={chip.label}
                onDelete={() => removeFilter(chip.key as keyof FilterState)}
                color="primary"
                variant="outlined"
                size="medium"
              />
            ))}
          </Box>
        )} */}

        <Box sx={{ p: 2, maxHeight: "calc(80vh - 60px)", overflow: "auto" }}>
          <StockFilters isMobile={true} />
        </Box>
      </Drawer>
    </>
  );

  const toolbars: Record<string, React.ReactNode> = {
    "Stock Search": renderDefaultToolbar(),
    RFQ: renderDefaultToolbar(<MobileRFQAppBarVerticalMenu />),
    RFQs: renderDefaultToolbar(<MobileRFQsAppBarVerticalMenu />),
    "Add RFQ Item": renderDefaultToolbar(),
    "Purchase Orders": renderDefaultToolbar(),
    Order: renderDefaultToolbar(),
    Stock: renderStockToolbar(),
    "Stock Audit": renderDefaultToolbar(),
    "Stock Audits": renderDefaultToolbar(
      <MobileStockAuditsAppBarVerticalMenu />
    ),
    Vendors: renderDefaultToolbar(<MobileVendorsAppBarVerticalMenu />),
  };

  return (
    <>
      {toolbars[displayName] || (
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 1,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography color={colors.black} variant="h3" alignSelf="center">
              {displayName}
            </Typography>
          </Box>
        </Toolbar>
      )}
    </>
  );
};

export default MobileAppToolbar;
