import React, { createContext, useContext } from "react";

import { getCurrencyCodeFromLocale } from "utils/getCurrencyCodeFromLocale";

type LocaleContextType = {
  locale: string;
  currencyCode: string;
};

const LocaleContext = createContext<LocaleContextType>({
  locale: "en-GB",
  currencyCode: "GBP",
});

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider: React.FC<{
  children: React.ReactNode;
  orgLocale?: string; // pass if available
}> = ({ children, orgLocale }) => {
  // const locale = orgLocale || navigator.language || "en-GB";
  // const currencyCode = getCurrencyCodeFromLocale(locale);
  const locale = "en-GB";
  const currencyCode = "GBP";

  return (
    <LocaleContext.Provider value={{ locale, currencyCode }}>
      {children}
    </LocaleContext.Provider>
  );
};
