import gql from "graphql-tag";

export const GET_CURRENT_TENANT = gql`
  query currentTenant {
    currentTenant {
      externalId
      statuses
      id
      logoUrl
      name
      ordersEmail
      ordersEmailName
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query allRoles {
    allRoles {
      id
      name
    }
  }
`;

export const GET_TENANT_MEMBERS = gql`
  query tenantMembers {
    tenantMembers {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
`;

export const GET_TENANT_INVITATIONS = gql`
  query tenantInvitations {
    tenantInvitations {
      userEmail
      invitedBy
      createdAt
      expiresAt
    }
  }
`;
