import ToggleFilter from "../ToggleFilter/ToggleFilter";

const LowStockFilter = () => {
  return (
    <ToggleFilter
      label="Low Stock"
      paramName="isLowStock"
      activeLabel="Low Stock"
    />
  );
};

export default LowStockFilter;
