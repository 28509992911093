import {
  StockPageViewType,
  useStockStore,
} from "modules/Stock/store/useStockStore";
import { useEffect, useState } from "react";

import DropdownFilter from "../DropdownFilter/DropdownFilter";
import { useFoldersLazyQuery } from "interfaces/graphql";
import useScreenSize from "hooks/useScreenSize/useScreenSize";
import { useSearchParams } from "react-router-dom";

const FolderFilter = () => {
  const { isMobile } = useScreenSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, setFilters, setCurrentFolder, setView } = useStockStore();
  const [folderName, setFolderName] = useState<string | null>(null);
  const [fetchFolders, { data: folderData }] = useFoldersLazyQuery();

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders]);

  useEffect(() => {
    const parentId = searchParams.get("parentId");
    if (parentId && folderData?.folders) {
      const preselectedFolder = folderData.folders.find(
        (folder) => folder.id === parentId
      );
      if (preselectedFolder) {
        setFolderName(preselectedFolder.name);
      }
    } else {
      setFolderName(null); // Reset if no parentId
    }
  }, [searchParams, folderData]);

  return (
    <DropdownFilter
      label={"Folders"}
      selectedValue={folderName}
      onClear={() => {
        setFolderName(null);
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.delete("parentId"); // ✅ Remove folder filter, keep others
          setFilters({
            // ✅ Sync with Zustand store
            ...filters,
            parentId: undefined,
          });

          setCurrentFolder(null, null);
          return newParams;
        });
      }}
      onSelect={(id, name) => {
        setFolderName(name);
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set("parentId", id); // ✅ Append instead of replacing
          setView(StockPageViewType.ITEM_DETAILS);
          setFilters({
            // ✅ Sync with Zustand store
            ...filters,
            parentId: id,
          });

          // Looks a bit hacky, because I use this for both name and id, but there's a hook
          // that uses folderId (App thinks user is navigating inside folder).
          // But if name is active, then on navigation we display "Stock > Folder Name"
          setCurrentFolder(null, name);
          return newParams;
        });
      }}
      fetchOptions={async () => folderData?.folders || []}
    />
  );
};

export default FolderFilter;
