import hasPermission, { Action } from "modules/User/utils/checkUserRole";

import useUserDetails from "modules/User/hooks/useUserDetails";

export function useViewPermissions() {
  const userDetails = useUserDetails();
  const roles = userDetails?.roles;
  const canViewDashboard = hasPermission(roles, Action.ViewDashboard);
  const canViewPurchaseOrders = hasPermission(roles, Action.ViewPurchaseOrders);
  const canViewRFQs = hasPermission(roles, Action.ViewRFQs);
  const canViewStock = hasPermission(roles, Action.ViewStock);
  const canViewVendors = hasPermission(roles, Action.ViewVendors);
  const canViewReports = hasPermission(roles, Action.ViewReports);
  const canViewAppSettings = hasPermission(roles, Action.ViewAppSettings);
  const canViewBudget = hasPermission(roles, Action.ViewBudget);
  const canViewUserDetails = hasPermission(roles, Action.ViewUserDetails);

  const hasNoViewPermissions = !(
    canViewDashboard ||
    canViewPurchaseOrders ||
    canViewRFQs ||
    canViewStock ||
    canViewVendors ||
    canViewAppSettings ||
    canViewUserDetails
  );

  return {
    canViewDashboard,
    canViewPurchaseOrders,
    canViewRFQs,
    canViewStock,
    canViewVendors,
    hasNoViewPermissions,
    canViewReports,
    canViewAppSettings,
    canViewBudget,
    canViewUserDetails,
  };
}
