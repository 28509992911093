import DemoLogo from "assets/logo/dental_assist_logo_white_BG_horizontal.png";
import useFetchTenantDetails from "modules/User/hooks/useFetchTenantDetails";

const BrandLogo = () => {
  const { currentTenant, loading } = useFetchTenantDetails();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "8px",
      padding: "24px 16px", // ⬆️ Increased top padding for better spacing
      boxSizing: "border-box" as "border-box",
    },
    logo: {
      maxWidth: "80%", // ✅ Ensures the logo doesn't exceed its container width
      maxHeight: "64px", // ✅ Slightly larger but controlled height
      height: "auto",
      objectFit: "contain" as "contain",
    },
  };

  return (
    <div style={styles.container}>
      {currentTenant.logoUrl && (
        <img
          src={currentTenant.logoUrl}
          alt="Company logo"
          style={styles.logo}
        />
      )}
      {!currentTenant.logoUrl && !loading && (
        <img src={DemoLogo} alt="Company logo" style={styles.logo} />
      )}
    </div>
  );
};

export default BrandLogo;
