import {
  FilterState,
  StockPageViewType,
  useStockStore,
} from "modules/Stock/store/useStockStore";

import { Button } from "@mui/material";
import useScreenSize from "hooks/useScreenSize/useScreenSize";
import { useSearchParams } from "react-router-dom";

interface ToggleFilterProps {
  label: string;
  paramName: keyof FilterState;
  activeLabel: string;
}

const ToggleFilter = ({ label, paramName, activeLabel }: ToggleFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, setFilters, setView } = useStockStore();
  const { isMobile } = useScreenSize();

  const isActive = searchParams.get(paramName) === "true";

  const toggleFilter = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (isActive) {
        newParams.delete(paramName); // ✅ Remove filter when toggled off
      } else {
        newParams.set(paramName, "true"); // ✅ Set filter to active
      }
      return newParams;
    });

    setView(StockPageViewType.ITEM_DETAILS);
    // ✅ Sync with Zustand store
    setFilters({
      ...filters,
      [paramName]: !isActive, // ✅ Toggle the boolean value in Zustand
    });
  };

  return (
    <Button
      variant={isActive ? "contained" : "outlined"}
      color="primary"
      onClick={toggleFilter}
      fullWidth={isMobile}
      sx={{
        borderRadius: "24px",
        padding: "6px 16px",
        fontWeight: isActive ? "bold" : "normal",
        minWidth: "120px",
        height: "32px",
        textTransform: "none",
        width: isMobile ? "100%" : "auto",
      }}
    >
      {isActive ? activeLabel : label}
    </Button>
  );
};

export default ToggleFilter;
