import { Box, Button, Popover, TextField } from "@mui/material";
import {
  StockPageViewType,
  useStockStore,
} from "modules/Stock/store/useStockStore";
import { useEffect, useState } from "react";

import NumberFormat from "react-number-format";
import useScreenSize from "hooks/useScreenSize/useScreenSize";
import { useSearchParams } from "react-router-dom";

const QuantityFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, setFilters, setView } = useStockStore();
  const currentValue = searchParams.get("quantityLessOrEqualTo") || "";
  const [inputValue, setInputValue] = useState(currentValue);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isMobile } = useScreenSize();

  // 🟢 Sync state when URL changes
  useEffect(() => {
    setInputValue(currentValue);
  }, [currentValue]);

  // ✅ Apply changes after user types
  const applyFilter = () => {
    setView(StockPageViewType.ITEM_DETAILS);
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (inputValue) {
        newParams.set("quantityLessOrEqualTo", inputValue);
        setFilters({
          ...filters,
          quantityLessOrEqualTo: parseInt(inputValue),
        });
      } else {
        newParams.delete("quantityLessOrEqualTo");
        setFilters({
          ...filters,
          quantityLessOrEqualTo: undefined,
        });
      }
      return newParams;
    });
    setAnchorEl(null); // Close popover
  };

  // ✅ Handle click on button to open number input
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ✅ Handle clearing the filter
  const handleClear = () => {
    setInputValue("");
    setFilters({
      ...filters,
      quantityLessOrEqualTo: undefined,
    });
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete("quantityLessOrEqualTo");
      return newParams;
    });
    setAnchorEl(null);
  };

  const onQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Box sx={{ width: isMobile ? "100%" : "auto" }}>
      <Button
        variant={currentValue ? "contained" : "outlined"}
        color="primary"
        onClick={handleClick}
        fullWidth={isMobile}
        sx={{
          borderRadius: "24px",
          padding: "6px 16px",
          fontWeight: currentValue ? "bold" : "normal",
          minWidth: "120px",
          height: "32px",
          textTransform: "none",
          width: isMobile ? "100%" : "auto",
        }}
      >
        {currentValue ? `Max: ${currentValue}` : "Max Quantity"}
      </Button>

      {/* 🔹 Popover for Number Input */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={applyFilter}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
          <NumberFormat
            customInput={TextField}
            size="small"
            label="Quantity"
            variant="standard"
            value={inputValue}
            decimalScale={2}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;
              return formattedValue === "" || floatValue! <= 10000;
            }}
            allowNegative={false}
            fullWidth
            onChange={onQuantityChange}
            InputLabelProps={{ shrink: true }}
          />
          <Button onClick={applyFilter} variant="contained" size="small">
            Apply
          </Button>
          {currentValue && (
            <Button onClick={handleClear} color="error" size="small">
              Clear
            </Button>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default QuantityFilter;
