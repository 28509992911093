import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  StockPageViewType,
  useStockStore,
} from "modules/Stock/store/useStockStore";
import { useEffect, useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "utils/useDebounce";
import { useSearchParams } from "react-router-dom";
import { useSearchStockLazyQuery } from "interfaces/graphql";

interface SearchBoxProps {
  autoFocus?: boolean;
  hideBackButton?: boolean;
}

const SearchBox = ({
  autoFocus = false,
  hideBackButton = false,
}: SearchBoxProps) => {
  const searchRef = useRef<HTMLDivElement>(null);
  const {
    searchBoxInput,
    setSearchResults,
    setView,
    setIsSearchStockLoading,
    setIsSearchStockError,
    setSearchBoxInput,
  } = useStockStore();

  const [_, setSearchParams] = useSearchParams();

  const debouncedInput = useDebounce(searchBoxInput, 500);

  const [fetchStock, { data, loading, error }] = useSearchStockLazyQuery();

  useEffect(() => {
    setIsSearchStockLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIsSearchStockError(!!error);
  }, [error]);

  // 🔍 Perform search when input changes
  useEffect(() => {
    if (debouncedInput.trim()) {
      setSearchParams({ search: debouncedInput }); // ✅ Update URL
      setView(StockPageViewType.SEARCH); // ✅ Switch to search view explicitly
      fetchStock({ variables: { nameTerm: debouncedInput } });
    } else {
      setSearchParams({});
      setView(StockPageViewType.FOLDERS); // ✅ Explicitly reset when input is cleared
      setSearchResults([]); // ✅ Clear search results
    }
  }, [debouncedInput]);

  // Update search results in Zustand
  useEffect(() => {
    if (data?.searchStock) {
      setSearchResults(data.searchStock);
    }
  }, [data]);

  // 🗑️ Clear search input & reset search state
  const handleClearInput = () => {
    setSearchBoxInput("");
    setSearchParams({});
    setSearchResults([]);
    setView(StockPageViewType.FOLDERS);
  };

  // 🔽 Search Suggestions
  return (
    <Box ref={searchRef} sx={{ position: "relative", width: "100%" }}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Search stock..."
        value={searchBoxInput}
        onChange={(e) => setSearchBoxInput(e.target.value)}
        autoFocus={autoFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#666" }} />
            </InputAdornment>
          ),
          endAdornment: searchBoxInput && !hideBackButton && (
            <InputAdornment position="end">
              <IconButton onClick={handleClearInput} size="small">
                <ClearIcon sx={{ color: "#999" }} />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            borderRadius: 2,
            bgcolor: "background.paper",
          },
        }}
      />
    </Box>
  );
};

export default SearchBox;
