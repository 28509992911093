/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type IAddMemberToRolesForTenantInput = {
  roleIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type IAddMembersToTenantInput = {
  userIds: Array<Scalars['String']>;
};

export type IBudget = {
  __typename?: 'Budget';
  amount: Scalars['Float'];
  used: Scalars['Float'];
};

export type ICreateFolderInput = {
  imagePayload?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type ICreateItemBulkInput = {
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  minLevel?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  primaryFolder?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  subFolder?: Maybe<Scalars['String']>;
};

export type ICreateItemInput = {
  description?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['DateTime']>;
  grade?: Maybe<ItemGrade>;
  imagePayload?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  minLevel?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type ICreateOrderInput = {
  rfqId: Scalars['ID'];
  rfqItemIds: Array<Scalars['ID']>;
  sendEmail: Scalars['Boolean'];
  status?: Maybe<OrderStatus>;
  vendorId: Scalars['ID'];
};

export type ICreateOrdersFromRfqInput = {
  rfqId: Scalars['ID'];
  vendorItems: Array<IVendorRfqItemInput>;
};

export type ICreateRfqItemInput = {
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
  imagePayload?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type ICreateTenantInput = {
  enabled: Scalars['Boolean'];
  externalId: Scalars['String'];
  name: Scalars['String'];
  ordersEmail: Scalars['String'];
  ordersEmailName: Scalars['String'];
};

export type ICreateVendorInput = {
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type IDashboardReport = {
  __typename?: 'DashboardReport';
  lowStockWithQuantity: Array<ILowStockItemWithQuantity>;
  orderSpend: Array<IOrderSpendPerMonth>;
  ordersByStatus: Array<IOrderCountWithStatus>;
  rfqCount: Scalars['Int'];
  stockCategories: IStockCategoryReport;
};


export type IFolder = {
  __typename?: 'Folder';
  children: Array<IStock>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type IFolderFilter = {
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
};

export type IFolderTreeNode = {
  __typename?: 'FolderTreeNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type IItem = {
  __typename?: 'Item';
  activities: Array<IItemActivity>;
  addedToOrders: Array<Scalars['ID']>;
  addedToRfqs: Array<Scalars['ID']>;
  barcode?: Maybe<Scalars['String']>;
  becameLowStockAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  grade?: Maybe<ItemGrade>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isLowStock?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  minLevel?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  needsAttention?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantities: Array<IItemQuantity>;
  totalQuantity?: Maybe<Scalars['Float']>;
};

export type IItemActivity = IItemArchiveActivity | IItemGradedActivity | IItemMovedToLocationActivity | IItemNeedsAttentionActivity | IItemNeedsNoAttentionActivity | IItemQuantityUpdatedActivity | IItemTakenActivity;

export type IItemArchiveActivity = {
  __typename?: 'ItemArchiveActivity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  itemId: Scalars['ID'];
  reason: Scalars['String'];
  user: Scalars['String'];
};

export type IItemFilter = {
  barcode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isLowStock?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  needsAttention?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  quantityLessOrEqualTo?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
};

export enum ItemGrade {
  Bad = 'BAD',
  Good = 'GOOD',
  Ungraded = 'UNGRADED'
}

export type IItemGradedActivity = {
  __typename?: 'ItemGradedActivity';
  createdAt: Scalars['DateTime'];
  grade: Scalars['String'];
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  reason: Scalars['String'];
  user: Scalars['String'];
};

export type IItemLocation = {
  __typename?: 'ItemLocation';
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
};

export type IItemMovedToLocationActivity = {
  __typename?: 'ItemMovedToLocationActivity';
  createdAt: Scalars['DateTime'];
  fromLocation: Scalars['String'];
  movedItemExpiry?: Maybe<Scalars['DateTime']>;
  quantityMoved: Scalars['Float'];
  toLocation: Scalars['String'];
  user: Scalars['String'];
};

export type IItemNeedsAttentionActivity = {
  __typename?: 'ItemNeedsAttentionActivity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  reason: Scalars['String'];
  user: Scalars['String'];
};

export type IItemNeedsNoAttentionActivity = {
  __typename?: 'ItemNeedsNoAttentionActivity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  reason: Scalars['String'];
  user: Scalars['String'];
};

export type IItemQuantity = {
  __typename?: 'ItemQuantity';
  expiresAt?: Maybe<Scalars['DateTime']>;
  location: IItemLocation;
  value: Scalars['Float'];
};

export type IItemQuantityUpdatedActivity = {
  __typename?: 'ItemQuantityUpdatedActivity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  newQuantity: Scalars['Float'];
  oldQuantity?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['ID']>;
  reason: Scalars['String'];
  user: Scalars['String'];
  vendor?: Maybe<IVendor>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type IItemSummary = {
  __typename?: 'ItemSummary';
  grade?: Maybe<ItemGrade>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isLowStock?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  minLevel?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type IItemTakenActivity = {
  __typename?: 'ItemTakenActivity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  quantity: Scalars['Float'];
  toWhere: Scalars['String'];
  user: Scalars['String'];
};

export type IItemUsage = {
  __typename?: 'ItemUsage';
  currentValueOfStock: Scalars['Float'];
  name: Scalars['String'];
  perMonth: Array<IMonthlyUsage>;
};

export type ILowStockItem = {
  __typename?: 'LowStockItem';
  becameLowStockAt?: Maybe<Scalars['DateTime']>;
  imageUrl?: Maybe<Scalars['String']>;
  orderIds: Array<Scalars['ID']>;
  rfqIds: Array<Scalars['ID']>;
  stockId: Scalars['ID'];
  stockName: Scalars['String'];
};

export type ILowStockItemWithQuantity = {
  __typename?: 'LowStockItemWithQuantity';
  itemName: Scalars['String'];
  quantity: Scalars['Float'];
};

export type IMergeStockItemsInput = {
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  mergeFromId: Scalars['ID'];
  mergeToId: Scalars['ID'];
  minLevel: Scalars['Float'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  pricePerUnit: Scalars['Float'];
};

export type IMonthlyUsage = {
  __typename?: 'MonthlyUsage';
  amount: Scalars['Float'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type IMoveItemToLocationInput = {
  expiry?: Maybe<Scalars['DateTime']>;
  fromLocationId: Scalars['ID'];
  quantity: Scalars['Float'];
  toLocationId: Scalars['ID'];
};

export type IMutation = {
  __typename?: 'Mutation';
  /** Add user to a list of roles within tenant */
  addMemberToRolesForTenant: Scalars['ID'];
  /** Assigns an existing user to a tenant. This seems redundant because you won't have a userId to do that unless a user is assigned to tenant. And we don't want to let you assign ANY user to our tenant */
  addMembersToTenant: Array<Scalars['ID']>;
  addStockItemToRfqDraft: IRfq;
  addVendorsToRfq: IRfq;
  cancelOrder: IOrder;
  /** Cancels the audit and sets the status to CANCELLED */
  cancelStockAudit: Scalars['ID'];
  cloneOrder: IOrder;
  cloneRfq: IRfq;
  /** This will mark given items for follow up, and will complete the order if there is nothing to follow up on. To mark item as delivered, set the followUpQuantity for item to 0 */
  completeOrder: IOrder;
  /** Completes the audit and updates the stock item quantities to the ones in the audit */
  completeStockAudit: IStockAudit;
  createFolder: IFolder;
  createItem: IItem;
  createItemLocation: Scalars['ID'];
  createItemsBulk: Array<IItem>;
  createOrder: IOrder;
  createOrdersFromRfq: Array<IOrder>;
  createRfq: IRfq;
  createRfqItem: IRfq;
  createStockAudit: IStockAudit;
  /** Used to create a new stock audit item for a specific stock item at a specific location with a specific expiry */
  createStockAuditItem: IStockAuditItem;
  createTenant: ITenant;
  createVendor: IVendor;
  deleteFolder: IFolder;
  /** Deletes an existing item location. This only works on item locations, which are associated with items that has 0 quantity. If the location has any associated items with non-zero quantity, mutation will fail */
  deleteItemLocation: Scalars['ID'];
  deleteRfq: IRfq;
  deleteRfqResponseItems: IRfq;
  deleteVendor: IVendor;
  gradeItem: IItem;
  markItemArchived: IItem;
  markItemNeedsAttention: IItem;
  markItemNeedsNoAttention: IItem;
  mergeItems: IItem;
  /** Moves item from one location to another based on given locationId and expiry date. Both FROM and TO locations MUST be created before this is invoked */
  moveItemToLocation: IItem;
  moveOrderItemsToRfq: IOrder;
  moveRfqItemsToDraft: IRfq;
  removeItemsFromRfq: IRfq;
  /** Removes user from roles within tenant */
  removeMemberFromRolesForTenant: Scalars['ID'];
  /** Removes an existing user from tenant */
  removeMembersFromTenant: Array<Scalars['ID']>;
  /** Removes a stock audit item from audit */
  removeStockAuditItem: IStockAudit;
  removeVendorsFromRfq: IRfq;
  sendOrderEmail: IOrder;
  sendRfq: IRfq;
  /** Sends invitation for a user to join a current tenant */
  sendTenantInvitation: Scalars['ID'];
  setBarcodeOnStock: IItem;
  setBudget: IBudget;
  setRfqLabel: IRfq;
  setupTenantDefaults: Scalars['String'];
  /** After audit created, items are added (createStockAuditItem), then audit has to be submitted. After submission manager can adjust stock levels (adjustStockLevels), and then complete the audit */
  submitStockAudit: IStockAudit;
  takeItem: IItem;
  updateFolder: IFolder;
  updateItem: IItem;
  /** Updates expiry date of the given itemId in a given location for a given expiry */
  updateItemExpiry: Scalars['ID'];
  updateItemLocation: Scalars['ID'];
  updateItemQuantity: IItem;
  updateRfqItemNote: IRfqItem;
  updateRfqItemPrice: IRfqItem;
  updateRfqItemQuantity: IRfqItem;
  updateRfqNote: IRfq;
  updateRfqResponseQuantity: IRfqResponseItem;
  /** Used to update a stock audit item - and should be used only to adjust the quantity counted, in case the quantity entered during creation was wrong */
  updateStockAuditItem: IStockAuditItem;
  updateTenant: ITenant;
  updateVendor: IVendor;
  uploadFolderImage: IFolder;
  uploadItemImage: IItem;
};


export type IMutationAddMemberToRolesForTenantArgs = {
  input: IAddMemberToRolesForTenantInput;
};


export type IMutationAddMembersToTenantArgs = {
  input: IAddMembersToTenantInput;
};


export type IMutationAddStockItemToRfqDraftArgs = {
  quantity: Scalars['Float'];
  rfqId: Scalars['ID'];
  stockItemId: Scalars['ID'];
};


export type IMutationAddVendorsToRfqArgs = {
  id: Scalars['ID'];
  vendorIds: Array<Scalars['ID']>;
};


export type IMutationCancelOrderArgs = {
  id: Scalars['ID'];
};


export type IMutationCancelStockAuditArgs = {
  stockAuditId: Scalars['ID'];
};


export type IMutationCloneOrderArgs = {
  id: Scalars['ID'];
  sendEmail: Scalars['Boolean'];
  vendorId: Scalars['ID'];
};


export type IMutationCloneRfqArgs = {
  id: Scalars['ID'];
};


export type IMutationCompleteOrderArgs = {
  id: Scalars['ID'];
  input?: Maybe<Array<IOrderItemFollowUpInput>>;
};


export type IMutationCompleteStockAuditArgs = {
  stockAuditId: Scalars['ID'];
};


export type IMutationCreateFolderArgs = {
  input: ICreateFolderInput;
};


export type IMutationCreateItemArgs = {
  input: ICreateItemInput;
};


export type IMutationCreateItemLocationArgs = {
  name: Scalars['String'];
};


export type IMutationCreateItemsBulkArgs = {
  input: Array<ICreateItemBulkInput>;
};


export type IMutationCreateOrderArgs = {
  input: ICreateOrderInput;
};


export type IMutationCreateOrdersFromRfqArgs = {
  input: ICreateOrdersFromRfqInput;
};


export type IMutationCreateRfqArgs = {
  label?: Maybe<Scalars['String']>;
};


export type IMutationCreateRfqItemArgs = {
  id: Scalars['ID'];
  input: ICreateRfqItemInput;
};


export type IMutationCreateStockAuditItemArgs = {
  expiresAt?: Maybe<Scalars['DateTime']>;
  locationId: Scalars['ID'];
  quantityCounted: Scalars['Float'];
  stockAuditId: Scalars['ID'];
  stockItemId: Scalars['ID'];
};


export type IMutationCreateTenantArgs = {
  input: ICreateTenantInput;
};


export type IMutationCreateVendorArgs = {
  input?: Maybe<ICreateVendorInput>;
};


export type IMutationDeleteFolderArgs = {
  id: Scalars['ID'];
};


export type IMutationDeleteItemLocationArgs = {
  id: Scalars['ID'];
};


export type IMutationDeleteRfqArgs = {
  id: Scalars['ID'];
};


export type IMutationDeleteRfqResponseItemsArgs = {
  id: Scalars['ID'];
  responseItemIds: Array<Scalars['ID']>;
};


export type IMutationDeleteVendorArgs = {
  id: Scalars['ID'];
};


export type IMutationGradeItemArgs = {
  grade: ItemGrade;
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type IMutationMarkItemArchivedArgs = {
  archived: Scalars['Boolean'];
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type IMutationMarkItemNeedsAttentionArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type IMutationMarkItemNeedsNoAttentionArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type IMutationMergeItemsArgs = {
  input: IMergeStockItemsInput;
};


export type IMutationMoveItemToLocationArgs = {
  id: Scalars['ID'];
  input?: Maybe<IMoveItemToLocationInput>;
};


export type IMutationMoveOrderItemsToRfqArgs = {
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']>;
  moveToDraftId?: Maybe<Scalars['ID']>;
};


export type IMutationMoveRfqItemsToDraftArgs = {
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']>;
  moveToDraftId?: Maybe<Scalars['ID']>;
};


export type IMutationRemoveItemsFromRfqArgs = {
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']>;
};


export type IMutationRemoveMemberFromRolesForTenantArgs = {
  input: IRemoveMemberFromRolesInput;
};


export type IMutationRemoveMembersFromTenantArgs = {
  input: IRemoveMembersFromTenantInput;
};


export type IMutationRemoveStockAuditItemArgs = {
  stockAuditId: Scalars['ID'];
  stockAuditItemId: Scalars['ID'];
};


export type IMutationRemoveVendorsFromRfqArgs = {
  id: Scalars['ID'];
  vendorIds: Array<Scalars['ID']>;
};


export type IMutationSendOrderEmailArgs = {
  id: Scalars['ID'];
};


export type IMutationSendRfqArgs = {
  id: Scalars['ID'];
  vendorIds: Array<Scalars['ID']>;
};


export type IMutationSendTenantInvitationArgs = {
  input: ISendTenantInvitationInput;
};


export type IMutationSetBarcodeOnStockArgs = {
  barcode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type IMutationSetBudgetArgs = {
  amount: Scalars['Float'];
};


export type IMutationSetRfqLabelArgs = {
  id: Scalars['ID'];
  label: Scalars['String'];
};


export type IMutationSubmitStockAuditArgs = {
  stockAuditId: Scalars['ID'];
};


export type IMutationTakeItemArgs = {
  input: ITakeItemInput;
};


export type IMutationUpdateFolderArgs = {
  id: Scalars['ID'];
  input: IUpdateFolderInput;
};


export type IMutationUpdateItemArgs = {
  id: Scalars['ID'];
  input: IUpdateItemInput;
};


export type IMutationUpdateItemExpiryArgs = {
  id: Scalars['ID'];
  input: IUpdateItemExpiryInput;
};


export type IMutationUpdateItemLocationArgs = {
  id: Scalars['ID'];
  input: IUpdateItemLocationInput;
};


export type IMutationUpdateItemQuantityArgs = {
  input?: Maybe<IUpdateItemQuantityInput>;
};


export type IMutationUpdateRfqItemNoteArgs = {
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  note: Scalars['String'];
};


export type IMutationUpdateRfqItemPriceArgs = {
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  pricePerUnit: Scalars['Float'];
};


export type IMutationUpdateRfqItemQuantityArgs = {
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  quantity: Scalars['Float'];
};


export type IMutationUpdateRfqNoteArgs = {
  id: Scalars['ID'];
  input?: Maybe<Scalars['String']>;
};


export type IMutationUpdateRfqResponseQuantityArgs = {
  quantity: Scalars['Float'];
  responseItemId: Scalars['ID'];
  rfqId: Scalars['ID'];
};


export type IMutationUpdateStockAuditItemArgs = {
  quantityCounted: Scalars['Float'];
  stockAuditId: Scalars['ID'];
  stockAuditItemId: Scalars['ID'];
};


export type IMutationUpdateTenantArgs = {
  id: Scalars['ID'];
  input: IUpdateTenantInput;
};


export type IMutationUpdateVendorArgs = {
  id: Scalars['ID'];
  input?: Maybe<IUpdateVendorInput>;
};


export type IMutationUploadFolderImageArgs = {
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
};


export type IMutationUploadItemImageArgs = {
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
};

export type IOrder = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Array<IOrderItem>;
  status: OrderStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  vendor: IVendor;
};

export type IOrderCountWithStatus = {
  __typename?: 'OrderCountWithStatus';
  count?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};

export type IOrderFilter = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<OrderStatus>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type IOrderItem = {
  __typename?: 'OrderItem';
  followUpQuantity?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockItem: IItem;
};

export type IOrderItemFollowUpInput = {
  expiry?: Maybe<Scalars['DateTime']>;
  followUpQuantity: Scalars['Float'];
  /** LocationId is mandatory, unless full quantity is set on follow up (followUpQuantity==quantity) - then it is not needed */
  locationId?: Maybe<Scalars['ID']>;
  orderItemId: Scalars['ID'];
};

export type IOrderReport = {
  __typename?: 'OrderReport';
  perVendor: Array<IVendorUsage>;
};

export type IOrderSpendPerMonth = {
  __typename?: 'OrderSpendPerMonth';
  date: Scalars['DateTime'];
  value: Scalars['Float'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Delivered = 'DELIVERED',
  FollowUp = 'FOLLOW_UP',
  Pending = 'PENDING'
}

export type IProduct = {
  __typename?: 'Product';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  imageUrlLarge?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
};

export type IQuery = {
  __typename?: 'Query';
  /** Returns globally available roles for our application */
  allRoles: Array<IRole>;
  budget?: Maybe<IBudget>;
  currentTenant: ITenant;
  dashboardReport: IDashboardReport;
  /** Returns a full list of folder nodes which allows to build a tree */
  folderTree: Array<IFolderTreeNode>;
  folders: Array<IFolder>;
  item?: Maybe<IItem>;
  itemLocations: Array<IItemLocation>;
  itemsSummary: Array<IItemSummary>;
  lowStock: Array<ILowStockItem>;
  navigateStock: Array<IStock>;
  orderReport: IOrderReport;
  orders: Array<IOrder>;
  rfq?: Maybe<IRfq>;
  rfqsSummary: Array<IRfqSummary>;
  searchItemsForAudit: Array<ISearchItemsForAuditResult>;
  searchProduct: Array<IProduct>;
  /** Search stock by name term */
  searchStock: Array<ISearchableItem>;
  stockAudit?: Maybe<IStockAudit>;
  stockAuditSummaries: Array<IStockAuditSummary>;
  stockReport: IStockReport;
  /** Returns all known invitations sent for users to join current tenant */
  tenantInvitations: Array<ITenantInvitation>;
  /** Gets all available users within the current tenant */
  tenantMembers: Array<ITenantMember>;
  tenants: Array<ITenant>;
  vendors: Array<IVendor>;
};


export type IQueryFoldersArgs = {
  filter?: Maybe<IFolderFilter>;
};


export type IQueryItemArgs = {
  id: Scalars['ID'];
};


export type IQueryItemsSummaryArgs = {
  filter?: Maybe<IItemFilter>;
};


export type IQueryNavigateStockArgs = {
  parentId?: Maybe<Scalars['ID']>;
};


export type IQueryOrdersArgs = {
  filter?: Maybe<IOrderFilter>;
};


export type IQueryRfqArgs = {
  id: Scalars['ID'];
};


export type IQueryRfqsSummaryArgs = {
  filter?: Maybe<IRfqFilter>;
};


export type IQuerySearchItemsForAuditArgs = {
  filter: ISearchItemsForAuditFilter;
};


export type IQuerySearchProductArgs = {
  text: Scalars['String'];
};


export type IQuerySearchStockArgs = {
  nameTerm: Scalars['String'];
};


export type IQueryStockAuditArgs = {
  id: Scalars['ID'];
};


export type IQueryVendorsArgs = {
  filter?: Maybe<IVendorFilter>;
};

export type IRemoveMemberFromRolesInput = {
  roleIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type IRemoveMembersFromTenantInput = {
  userIds: Array<Scalars['String']>;
};

export type IRfq = {
  __typename?: 'Rfq';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  items: Array<IRfqItem>;
  label?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  responses: Array<IRfqResponse>;
  status: RfqStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  vendors: Array<IVendor>;
};

export type IRfqFilter = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<RfqStatus>>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type IRfqItem = {
  __typename?: 'RfqItem';
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stockItem: IItem;
};

export type IRfqResponse = {
  __typename?: 'RfqResponse';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Array<IRfqResponseItem>;
  note?: Maybe<Scalars['String']>;
  rfqId: Scalars['ID'];
  vendor: IVendor;
};

export type IRfqResponseItem = {
  __typename?: 'RfqResponseItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  rfqItemId?: Maybe<Scalars['ID']>;
};

export enum RfqStatus {
  Draft = 'DRAFT',
  Received = 'RECEIVED',
  Sent = 'SENT'
}

export type IRfqSummary = {
  __typename?: 'RfqSummary';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  itemsCount: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  status: RfqStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  vendors: Array<IVendor>;
};

export type IRole = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ISearchItemsForAuditFilter = {
  barcode?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ISearchItemsForAuditResult = {
  __typename?: 'SearchItemsForAuditResult';
  expiresAt?: Maybe<Scalars['DateTime']>;
  imageUrl?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Float'];
  stockItemId: Scalars['ID'];
};

export type ISearchableItem = {
  __typename?: 'SearchableItem';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ISendTenantInvitationInput = {
  inviteeEmail: Scalars['String'];
  inviterName: Scalars['String'];
  roleIds: Array<Scalars['String']>;
};

export type IStock = IFolder | IItemSummary;

export type IStockAudit = {
  __typename?: 'StockAudit';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  items: Array<IStockAuditItem>;
  status: StockAuditStatus;
};

export type IStockAuditItem = {
  __typename?: 'StockAuditItem';
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
  quantityCounted: Scalars['Float'];
  quantityExpected: Scalars['Float'];
  status: StockAuditItemStatus;
  stockItemId: Scalars['ID'];
  stockItemName: Scalars['String'];
};

export enum StockAuditItemStatus {
  Match = 'MATCH',
  Missing = 'MISSING',
  Surplus = 'SURPLUS'
}

export enum StockAuditStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export type IStockAuditSummary = {
  __typename?: 'StockAuditSummary';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  status: StockAuditStatus;
};

export type IStockCategoryReport = {
  __typename?: 'StockCategoryReport';
  stockWithCategories: Array<IStockWithCategory>;
  totalValue: Scalars['Float'];
};

export type IStockReport = {
  __typename?: 'StockReport';
  currentValueOfAllStock: Scalars['Float'];
  perItem: Array<IItemUsage>;
};

export type IStockWithCategory = {
  __typename?: 'StockWithCategory';
  categoryName: Scalars['String'];
  value: Scalars['Float'];
};

export type ISubscription = {
  __typename?: 'Subscription';
  itemAlert: IItem;
  vendorReplied: IRfqResponse;
};

export type ITakeItemInput = {
  expiry?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  quantity: Scalars['Float'];
  toWhere: Scalars['String'];
};

export type ITenant = {
  __typename?: 'Tenant';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  /** A logo for the tenant, in case they supplied one */
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An email address which vendors will see in their 'From' field */
  ordersEmail: Scalars['String'];
  /** A name that will be seen in the 'From' field in email vendors receive */
  ordersEmailName: Scalars['String'];
  /** Describes what statuses were applied for this tenant */
  statuses: Array<TenantStatus>;
};

export type ITenantInvitation = {
  __typename?: 'TenantInvitation';
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  invitedBy: Scalars['String'];
  userEmail: Scalars['String'];
};

export type ITenantMember = {
  __typename?: 'TenantMember';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  roles: Array<IRole>;
};

export enum TenantStatus {
  Disabled = 'DISABLED',
  StockAuditInProgress = 'STOCK_AUDIT_IN_PROGRESS'
}

export type IUpdateFolderInput = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type IUpdateItemExpiryInput = {
  /** Used to identify which expiry of item we are looking to update. There could be multiple different ones in a location. This is nullable. */
  currentExpiry?: Maybe<Scalars['DateTime']>;
  /** Used to identify which location to look for the item */
  locationId: Scalars['ID'];
  /** Used to set the expiry to the item. This is nullable, because we might want to remove the expiry from item. */
  newExpiry?: Maybe<Scalars['DateTime']>;
};

export type IUpdateItemInput = {
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  minLevel?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

export type IUpdateItemLocationInput = {
  name: Scalars['String'];
  /** Only a single item location can be marked as default. Which means, if you set this flag to true, it will override any existing default location */
  setDefault: Scalars['Boolean'];
};

export type IUpdateItemQuantityInput = {
  expiry?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  quantity: Scalars['Float'];
  reason: Scalars['String'];
};

export type IUpdateTenantInput = {
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  ordersEmail: Scalars['String'];
  ordersEmailName: Scalars['String'];
};

export type IUpdateVendorInput = {
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  hidden: Scalars['Boolean'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type IVendor = {
  __typename?: 'Vendor';
  contactEmail?: Maybe<Scalars['String']>;
  contactName: Scalars['String'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type IVendorFilter = {
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type IVendorRfqItemInput = {
  rfqResponseItemId: Scalars['ID'];
  vendorId: Scalars['ID'];
};

export type IVendorUsage = {
  __typename?: 'VendorUsage';
  total: Scalars['Float'];
  usage: Array<IMonthlyUsage>;
  vendorName: Scalars['String'];
};

export type ISetBudgetMutationVariables = Exact<{
  amount: Scalars['Float'];
}>;


export type ISetBudgetMutation = (
  { __typename?: 'Mutation' }
  & { setBudget: (
    { __typename?: 'Budget' }
    & Pick<IBudget, 'amount' | 'used'>
  ) }
);

export type IBudgetQueryVariables = Exact<{ [key: string]: never; }>;


export type IBudgetQuery = (
  { __typename?: 'Query' }
  & { budget?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<IBudget, 'amount' | 'used'>
  )> }
);

export type IDashboardReportQueryVariables = Exact<{ [key: string]: never; }>;


export type IDashboardReportQuery = (
  { __typename?: 'Query' }
  & { dashboardReport: (
    { __typename?: 'DashboardReport' }
    & Pick<IDashboardReport, 'rfqCount'>
    & { lowStockWithQuantity: Array<(
      { __typename?: 'LowStockItemWithQuantity' }
      & Pick<ILowStockItemWithQuantity, 'itemName' | 'quantity'>
    )>, ordersByStatus: Array<(
      { __typename?: 'OrderCountWithStatus' }
      & Pick<IOrderCountWithStatus, 'count' | 'status'>
    )>, stockCategories: (
      { __typename?: 'StockCategoryReport' }
      & Pick<IStockCategoryReport, 'totalValue'>
      & { stockWithCategories: Array<(
        { __typename?: 'StockWithCategory' }
        & Pick<IStockWithCategory, 'categoryName' | 'value'>
      )> }
    ), orderSpend: Array<(
      { __typename?: 'OrderSpendPerMonth' }
      & Pick<IOrderSpendPerMonth, 'date' | 'value'>
    )> }
  ) }
);

export type ICreateOrderMutationVariables = Exact<{
  input: ICreateOrderInput;
}>;


export type ICreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'Order' }
    & Pick<IOrder, 'id'>
  ) }
);

export type ICreateOrdersFromRfqMutationVariables = Exact<{
  input: ICreateOrdersFromRfqInput;
}>;


export type ICreateOrdersFromRfqMutation = (
  { __typename?: 'Mutation' }
  & { createOrdersFromRfq: Array<(
    { __typename?: 'Order' }
    & Pick<IOrder, 'id'>
  )> }
);

export type ICompleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<Array<IOrderItemFollowUpInput> | IOrderItemFollowUpInput>;
}>;


export type ICompleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { completeOrder: (
    { __typename?: 'Order' }
    & Pick<IOrder, 'id'>
  ) }
);

export type IMoveOrderItemsToRfqMutationVariables = Exact<{
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']> | Scalars['ID'];
  moveToDraftId?: Maybe<Scalars['ID']>;
}>;


export type IMoveOrderItemsToRfqMutation = (
  { __typename?: 'Mutation' }
  & { moveOrderItemsToRfq: (
    { __typename?: 'Order' }
    & Pick<IOrder, 'id'>
  ) }
);

export type ICancelOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ICancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelOrder: (
    { __typename?: 'Order' }
    & Pick<IOrder, 'id'>
  ) }
);

export type IOrdersQueryVariables = Exact<{
  filter?: Maybe<IOrderFilter>;
}>;


export type IOrdersQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<IOrder, 'createdAt' | 'id' | 'status' | 'updatedAt'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<IOrderItem, 'followUpQuantity' | 'id' | 'note' | 'pricePerUnit' | 'quantity'>
      & { stockItem: (
        { __typename?: 'Item' }
        & Pick<IItem, 'addedToOrders' | 'addedToRfqs' | 'grade' | 'id' | 'imageUrl' | 'isArchived' | 'isLowStock' | 'manufacturer' | 'manufacturerId' | 'minLevel' | 'name' | 'parentId' | 'pricePerUnit' | 'description' | 'note'>
        & { activities: Array<(
          { __typename?: 'ItemArchiveActivity' }
          & Pick<IItemArchiveActivity, 'createdAt' | 'id' | 'isArchived' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemGradedActivity' }
          & Pick<IItemGradedActivity, 'createdAt' | 'grade' | 'id' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemMovedToLocationActivity' }
          & Pick<IItemMovedToLocationActivity, 'createdAt' | 'fromLocation' | 'user' | 'toLocation' | 'quantityMoved' | 'movedItemExpiry'>
        ) | (
          { __typename?: 'ItemNeedsAttentionActivity' }
          & Pick<IItemNeedsAttentionActivity, 'createdAt' | 'id' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemNeedsNoAttentionActivity' }
          & Pick<IItemNeedsNoAttentionActivity, 'createdAt' | 'id' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemQuantityUpdatedActivity' }
          & Pick<IItemQuantityUpdatedActivity, 'createdAt' | 'id' | 'itemId' | 'newQuantity' | 'oldQuantity' | 'orderId' | 'reason' | 'user' | 'vendorId'>
          & { vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
          )> }
        ) | (
          { __typename?: 'ItemTakenActivity' }
          & Pick<IItemTakenActivity, 'createdAt' | 'id' | 'itemId' | 'quantity' | 'toWhere' | 'user'>
        )>, quantities: Array<(
          { __typename?: 'ItemQuantity' }
          & Pick<IItemQuantity, 'value' | 'expiresAt'>
          & { location: (
            { __typename?: 'ItemLocation' }
            & Pick<IItemLocation, 'id' | 'name' | 'isDefault'>
          ) }
        )> }
      ) }
    )>, vendor: (
      { __typename?: 'Vendor' }
      & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
    ) }
  )> }
);

export type IOrderQueryVariables = Exact<{
  filter?: Maybe<IOrderFilter>;
}>;


export type IOrderQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<IOrder, 'createdAt' | 'createdBy' | 'updatedBy' | 'id' | 'status' | 'updatedAt'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<IOrderItem, 'followUpQuantity' | 'id' | 'note' | 'pricePerUnit' | 'quantity'>
      & { stockItem: (
        { __typename?: 'Item' }
        & Pick<IItem, 'addedToOrders' | 'addedToRfqs' | 'grade' | 'id' | 'imageUrl' | 'isArchived' | 'isLowStock' | 'manufacturer' | 'manufacturerId' | 'minLevel' | 'name' | 'parentId' | 'pricePerUnit' | 'description' | 'note'>
        & { activities: Array<(
          { __typename?: 'ItemArchiveActivity' }
          & Pick<IItemArchiveActivity, 'createdAt' | 'id' | 'isArchived' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemGradedActivity' }
          & Pick<IItemGradedActivity, 'createdAt' | 'grade' | 'id' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemMovedToLocationActivity' }
          & Pick<IItemMovedToLocationActivity, 'createdAt' | 'fromLocation' | 'user' | 'toLocation' | 'quantityMoved' | 'movedItemExpiry'>
        ) | (
          { __typename?: 'ItemNeedsAttentionActivity' }
          & Pick<IItemNeedsAttentionActivity, 'createdAt' | 'id' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemNeedsNoAttentionActivity' }
          & Pick<IItemNeedsNoAttentionActivity, 'createdAt' | 'id' | 'itemId' | 'reason' | 'user'>
        ) | (
          { __typename?: 'ItemQuantityUpdatedActivity' }
          & Pick<IItemQuantityUpdatedActivity, 'createdAt' | 'id' | 'itemId' | 'newQuantity' | 'oldQuantity' | 'orderId' | 'reason' | 'user' | 'vendorId'>
          & { vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
          )> }
        ) | (
          { __typename?: 'ItemTakenActivity' }
          & Pick<IItemTakenActivity, 'createdAt' | 'id' | 'itemId' | 'quantity' | 'toWhere' | 'user'>
        )>, quantities: Array<(
          { __typename?: 'ItemQuantity' }
          & Pick<IItemQuantity, 'value' | 'expiresAt'>
          & { location: (
            { __typename?: 'ItemLocation' }
            & Pick<IItemLocation, 'id' | 'name' | 'isDefault'>
          ) }
        )> }
      ) }
    )>, vendor: (
      { __typename?: 'Vendor' }
      & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
    ) }
  )> }
);

export type ICreateRfqMutationVariables = Exact<{
  label?: Maybe<Scalars['String']>;
}>;


export type ICreateRfqMutation = (
  { __typename?: 'Mutation' }
  & { createRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type ISetRfqLabelMutationVariables = Exact<{
  id: Scalars['ID'];
  label: Scalars['String'];
}>;


export type ISetRfqLabelMutation = (
  { __typename?: 'Mutation' }
  & { setRfqLabel: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IAddVendorsToRfqMutationVariables = Exact<{
  id: Scalars['ID'];
  vendorIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type IAddVendorsToRfqMutation = (
  { __typename?: 'Mutation' }
  & { addVendorsToRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IRemoveVendorsFromRfqMutationVariables = Exact<{
  id: Scalars['ID'];
  vendorIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type IRemoveVendorsFromRfqMutation = (
  { __typename?: 'Mutation' }
  & { removeVendorsFromRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type ICreateRfqItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ICreateRfqItemInput;
}>;


export type ICreateRfqItemMutation = (
  { __typename?: 'Mutation' }
  & { createRfqItem: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IAddStockItemToRfqDraftMutationVariables = Exact<{
  stockItemId: Scalars['ID'];
  rfqId: Scalars['ID'];
  quantity: Scalars['Float'];
}>;


export type IAddStockItemToRfqDraftMutation = (
  { __typename?: 'Mutation' }
  & { addStockItemToRfqDraft: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IRemoveItemsFromRfqMutationVariables = Exact<{
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type IRemoveItemsFromRfqMutation = (
  { __typename?: 'Mutation' }
  & { removeItemsFromRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IMoveRfqItemsToDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  itemIds: Array<Scalars['ID']> | Scalars['ID'];
  moveToDraftId?: Maybe<Scalars['ID']>;
}>;


export type IMoveRfqItemsToDraftMutation = (
  { __typename?: 'Mutation' }
  & { moveRfqItemsToDraft: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type ICloneRfqMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ICloneRfqMutation = (
  { __typename?: 'Mutation' }
  & { cloneRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type ISendRfqMutationVariables = Exact<{
  id: Scalars['ID'];
  vendorIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ISendRfqMutation = (
  { __typename?: 'Mutation' }
  & { sendRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IDeleteRfqMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IDeleteRfqMutation = (
  { __typename?: 'Mutation' }
  & { deleteRfq: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IUpdateRfqNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<Scalars['String']>;
}>;


export type IUpdateRfqNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateRfqNote: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IDeleteRfqResponseItemsMutationVariables = Exact<{
  id: Scalars['ID'];
  responseItemIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type IDeleteRfqResponseItemsMutation = (
  { __typename?: 'Mutation' }
  & { deleteRfqResponseItems: (
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id'>
  ) }
);

export type IUpdateRfqItemQuantityMutationVariables = Exact<{
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  quantity: Scalars['Float'];
}>;


export type IUpdateRfqItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { updateRfqItemQuantity: (
    { __typename?: 'RfqItem' }
    & Pick<IRfqItem, 'id' | 'quantity'>
  ) }
);

export type IUpdateRfqItemNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  note: Scalars['String'];
}>;


export type IUpdateRfqItemNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateRfqItemNote: (
    { __typename?: 'RfqItem' }
    & Pick<IRfqItem, 'id' | 'note'>
  ) }
);

export type IUpdateRfqItemPriceMutationVariables = Exact<{
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  pricePerUnit: Scalars['Float'];
}>;


export type IUpdateRfqItemPriceMutation = (
  { __typename?: 'Mutation' }
  & { updateRfqItemPrice: (
    { __typename?: 'RfqItem' }
    & Pick<IRfqItem, 'id' | 'pricePerUnit'>
  ) }
);

export type IUpdateRfqResponseQuantityMutationVariables = Exact<{
  rfqId: Scalars['ID'];
  responseItemId: Scalars['ID'];
  quantity: Scalars['Float'];
}>;


export type IUpdateRfqResponseQuantityMutation = (
  { __typename?: 'Mutation' }
  & { updateRfqResponseQuantity: (
    { __typename?: 'RfqResponseItem' }
    & Pick<IRfqResponseItem, 'id' | 'quantity'>
  ) }
);

export type IRfqsQueryVariables = Exact<{
  filter?: Maybe<IRfqFilter>;
}>;


export type IRfqsQuery = (
  { __typename?: 'Query' }
  & { rfqsSummary: Array<(
    { __typename?: 'RfqSummary' }
    & Pick<IRfqSummary, 'id' | 'label' | 'itemsCount' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>
    & { vendors: Array<(
      { __typename?: 'Vendor' }
      & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name'>
    )> }
  )> }
);

export type IRfqsByStatusQueryVariables = Exact<{
  filter?: Maybe<IRfqFilter>;
}>;


export type IRfqsByStatusQuery = (
  { __typename?: 'Query' }
  & { rfqsSummary: Array<(
    { __typename?: 'RfqSummary' }
    & Pick<IRfqSummary, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'label' | 'status'>
    & { vendors: Array<(
      { __typename?: 'Vendor' }
      & Pick<IVendor, 'id' | 'hidden'>
    )> }
  )> }
);

export type IRfqsVendorsQueryVariables = Exact<{
  filter?: Maybe<IRfqFilter>;
}>;


export type IRfqsVendorsQuery = (
  { __typename?: 'Query' }
  & { rfqsSummary: Array<(
    { __typename?: 'RfqSummary' }
    & Pick<IRfqSummary, 'id'>
    & { vendors: Array<(
      { __typename?: 'Vendor' }
      & Pick<IVendor, 'id' | 'hidden'>
    )> }
  )> }
);

export type IRfqQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IRfqQuery = (
  { __typename?: 'Query' }
  & { rfq?: Maybe<(
    { __typename?: 'Rfq' }
    & Pick<IRfq, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'label' | 'status' | 'note'>
    & { vendors: Array<(
      { __typename?: 'Vendor' }
      & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name'>
    )>, items: Array<(
      { __typename?: 'RfqItem' }
      & Pick<IRfqItem, 'id' | 'quantity' | 'note' | 'pricePerUnit'>
      & { stockItem: (
        { __typename?: 'Item' }
        & Pick<IItem, 'addedToOrders' | 'addedToRfqs' | 'description' | 'grade' | 'id' | 'imageUrl' | 'isArchived' | 'isLowStock' | 'minLevel' | 'name' | 'manufacturer' | 'manufacturerId' | 'note' | 'parentId' | 'pricePerUnit'>
        & { quantities: Array<(
          { __typename?: 'ItemQuantity' }
          & Pick<IItemQuantity, 'value' | 'expiresAt'>
          & { location: (
            { __typename?: 'ItemLocation' }
            & Pick<IItemLocation, 'id' | 'name' | 'isDefault'>
          ) }
        )> }
      ) }
    )>, responses: Array<(
      { __typename?: 'RfqResponse' }
      & Pick<IRfqResponse, 'createdAt' | 'id' | 'note' | 'rfqId'>
      & { items: Array<(
        { __typename?: 'RfqResponseItem' }
        & Pick<IRfqResponseItem, 'id' | 'description' | 'imageUrl' | 'manufacturer' | 'manufacturerId' | 'name' | 'note' | 'pricePerUnit' | 'quantity' | 'rfqItemId'>
      )>, vendor: (
        { __typename?: 'Vendor' }
        & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
      ) }
    )> }
  )> }
);

export type ISearchProductQueryVariables = Exact<{
  text: Scalars['String'];
}>;


export type ISearchProductQuery = (
  { __typename?: 'Query' }
  & { searchProduct: Array<(
    { __typename?: 'Product' }
    & Pick<IProduct, 'category' | 'description' | 'externalId' | 'imageUrlLarge' | 'manufacturer' | 'name' | 'subCategory'>
  )> }
);

export type IStockReportQueryVariables = Exact<{ [key: string]: never; }>;


export type IStockReportQuery = (
  { __typename?: 'Query' }
  & { stockReport: (
    { __typename?: 'StockReport' }
    & Pick<IStockReport, 'currentValueOfAllStock'>
    & { perItem: Array<(
      { __typename?: 'ItemUsage' }
      & Pick<IItemUsage, 'name' | 'currentValueOfStock'>
      & { perMonth: Array<(
        { __typename?: 'MonthlyUsage' }
        & Pick<IMonthlyUsage, 'amount' | 'month' | 'year'>
      )> }
    )> }
  ) }
);

export type IOrderReportQueryVariables = Exact<{ [key: string]: never; }>;


export type IOrderReportQuery = (
  { __typename?: 'Query' }
  & { orderReport: (
    { __typename?: 'OrderReport' }
    & { perVendor: Array<(
      { __typename?: 'VendorUsage' }
      & Pick<IVendorUsage, 'total' | 'vendorName'>
      & { usage: Array<(
        { __typename?: 'MonthlyUsage' }
        & Pick<IMonthlyUsage, 'amount' | 'month' | 'year'>
      )> }
    )> }
  ) }
);

export type ICreateFolderMutationVariables = Exact<{
  input: ICreateFolderInput;
}>;


export type ICreateFolderMutation = (
  { __typename?: 'Mutation' }
  & { createFolder: (
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id'>
  ) }
);

export type ICreateItemMutationVariables = Exact<{
  input: ICreateItemInput;
}>;


export type ICreateItemMutation = (
  { __typename?: 'Mutation' }
  & { createItem: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  ) }
);

export type ICreateItemsBulkMutationVariables = Exact<{
  input: Array<ICreateItemBulkInput> | ICreateItemBulkInput;
}>;


export type ICreateItemsBulkMutation = (
  { __typename?: 'Mutation' }
  & { createItemsBulk: Array<(
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  )> }
);

export type IUpdateFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IUpdateFolderInput;
}>;


export type IUpdateFolderMutation = (
  { __typename?: 'Mutation' }
  & { updateFolder: (
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id'>
  ) }
);

export type IUpdateItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IUpdateItemInput;
}>;


export type IUpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id' | 'name' | 'description' | 'manufacturer' | 'manufacturerId' | 'minLevel' | 'note'>
  ) }
);

export type IUpdateItemQuantityMutationVariables = Exact<{
  input?: Maybe<IUpdateItemQuantityInput>;
}>;


export type IUpdateItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { updateItemQuantity: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  ) }
);

export type IGradeItemMutationVariables = Exact<{
  id: Scalars['ID'];
  grade: ItemGrade;
  reason: Scalars['String'];
}>;


export type IGradeItemMutation = (
  { __typename?: 'Mutation' }
  & { gradeItem: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id' | 'grade'>
  ) }
);

export type ITakeItemMutationVariables = Exact<{
  input: ITakeItemInput;
}>;


export type ITakeItemMutation = (
  { __typename?: 'Mutation' }
  & { takeItem: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id' | 'totalQuantity'>
    & { activities: Array<{ __typename: 'ItemArchiveActivity' } | { __typename: 'ItemGradedActivity' } | { __typename: 'ItemMovedToLocationActivity' } | { __typename: 'ItemNeedsAttentionActivity' } | { __typename: 'ItemNeedsNoAttentionActivity' } | { __typename: 'ItemQuantityUpdatedActivity' } | { __typename: 'ItemTakenActivity' }> }
  ) }
);

export type IUploadItemImageMutationVariables = Exact<{
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
}>;


export type IUploadItemImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadItemImage: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id' | 'imageUrl'>
  ) }
);

export type IUploadFolderImageMutationVariables = Exact<{
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
}>;


export type IUploadFolderImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadFolderImage: (
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id'>
  ) }
);

export type ISetBarcodeOnStockMutationVariables = Exact<{
  id: Scalars['ID'];
  barcode?: Maybe<Scalars['String']>;
}>;


export type ISetBarcodeOnStockMutation = (
  { __typename?: 'Mutation' }
  & { setBarcodeOnStock: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  ) }
);

export type IMergeItemsMutationVariables = Exact<{
  input: IMergeStockItemsInput;
}>;


export type IMergeItemsMutation = (
  { __typename?: 'Mutation' }
  & { mergeItems: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  ) }
);

export type IMarkItemArchivedMutationVariables = Exact<{
  id: Scalars['ID'];
  archived: Scalars['Boolean'];
  reason: Scalars['String'];
}>;


export type IMarkItemArchivedMutation = (
  { __typename?: 'Mutation' }
  & { markItemArchived: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  ) }
);

export type IDeleteFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IDeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFolder: (
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id'>
  ) }
);

export type ICreateItemLocationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type ICreateItemLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'createItemLocation'>
);

export type IUpdateItemLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IUpdateItemLocationInput;
}>;


export type IUpdateItemLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'updateItemLocation'>
);

export type IDeleteItemLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IDeleteItemLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'deleteItemLocation'>
);

export type IMoveItemToLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IMoveItemToLocationInput;
}>;


export type IMoveItemToLocationMutation = (
  { __typename?: 'Mutation' }
  & { moveItemToLocation: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id'>
  ) }
);

export type IUpdateItemExpiryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IUpdateItemExpiryInput;
}>;


export type IUpdateItemExpiryMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'updateItemExpiry'>
);

export type ICompleteStockAuditMutationVariables = Exact<{
  stockAuditId: Scalars['ID'];
}>;


export type ICompleteStockAuditMutation = (
  { __typename?: 'Mutation' }
  & { completeStockAudit: (
    { __typename?: 'StockAudit' }
    & Pick<IStockAudit, 'id'>
  ) }
);

export type ICreateStockAuditMutationVariables = Exact<{ [key: string]: never; }>;


export type ICreateStockAuditMutation = (
  { __typename?: 'Mutation' }
  & { createStockAudit: (
    { __typename?: 'StockAudit' }
    & Pick<IStockAudit, 'id'>
  ) }
);

export type ICreateStockAuditItemMutationVariables = Exact<{
  stockAuditId: Scalars['ID'];
  stockItemId: Scalars['ID'];
  locationId: Scalars['ID'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  quantityCounted: Scalars['Float'];
}>;


export type ICreateStockAuditItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockAuditItem: (
    { __typename?: 'StockAuditItem' }
    & Pick<IStockAuditItem, 'id'>
  ) }
);

export type ISubmitStockAuditMutationVariables = Exact<{
  stockAuditId: Scalars['ID'];
}>;


export type ISubmitStockAuditMutation = (
  { __typename?: 'Mutation' }
  & { submitStockAudit: (
    { __typename?: 'StockAudit' }
    & Pick<IStockAudit, 'id'>
  ) }
);

export type IUpdateStockAuditItemMutationVariables = Exact<{
  stockAuditId: Scalars['ID'];
  stockAuditItemId: Scalars['ID'];
  quantityCounted: Scalars['Float'];
}>;


export type IUpdateStockAuditItemMutation = (
  { __typename?: 'Mutation' }
  & { updateStockAuditItem: (
    { __typename?: 'StockAuditItem' }
    & Pick<IStockAuditItem, 'id'>
  ) }
);

export type ICancelStockAuditMutationVariables = Exact<{
  stockAuditId: Scalars['ID'];
}>;


export type ICancelStockAuditMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'cancelStockAudit'>
);

export type IRemoveStockAuditItemMutationVariables = Exact<{
  stockAuditId: Scalars['ID'];
  stockAuditItemId: Scalars['ID'];
}>;


export type IRemoveStockAuditItemMutation = (
  { __typename?: 'Mutation' }
  & { removeStockAuditItem: (
    { __typename?: 'StockAudit' }
    & Pick<IStockAudit, 'id'>
  ) }
);

export type IFoldersQueryVariables = Exact<{
  filter?: Maybe<IFolderFilter>;
}>;


export type IFoldersQuery = (
  { __typename?: 'Query' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id' | 'name' | 'imageUrl' | 'parentId'>
    & { children: Array<(
      { __typename?: 'Folder' }
      & Pick<IFolder, 'id' | 'name' | 'imageUrl' | 'parentId'>
    ) | { __typename?: 'ItemSummary' }> }
  )> }
);

export type IFoldersDropdownQueryVariables = Exact<{
  filter?: Maybe<IFolderFilter>;
}>;


export type IFoldersDropdownQuery = (
  { __typename?: 'Query' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id' | 'name' | 'imageUrl'>
  )> }
);

export type IItemsSummaryQueryVariables = Exact<{
  filter?: Maybe<IItemFilter>;
}>;


export type IItemsSummaryQuery = (
  { __typename?: 'Query' }
  & { itemsSummary: Array<(
    { __typename?: 'ItemSummary' }
    & Pick<IItemSummary, 'grade' | 'id' | 'imageUrl' | 'minLevel' | 'name' | 'parentId' | 'pricePerUnit' | 'isArchived' | 'quantity'>
  )> }
);

export type IItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IItemQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & Pick<IItem, 'addedToOrders' | 'addedToRfqs' | 'barcode' | 'becameLowStockAt' | 'description' | 'grade' | 'id' | 'imageUrl' | 'isArchived' | 'isLowStock' | 'manufacturer' | 'manufacturerId' | 'minLevel' | 'name' | 'needsAttention' | 'note' | 'parentId' | 'pricePerUnit' | 'totalQuantity'>
    & { activities: Array<(
      { __typename?: 'ItemArchiveActivity' }
      & Pick<IItemArchiveActivity, 'createdAt' | 'id' | 'isArchived' | 'itemId' | 'reason' | 'user'>
    ) | (
      { __typename?: 'ItemGradedActivity' }
      & Pick<IItemGradedActivity, 'createdAt' | 'grade' | 'id' | 'itemId' | 'reason' | 'user'>
    ) | (
      { __typename?: 'ItemMovedToLocationActivity' }
      & Pick<IItemMovedToLocationActivity, 'createdAt' | 'fromLocation' | 'user' | 'toLocation' | 'quantityMoved' | 'movedItemExpiry'>
    ) | (
      { __typename?: 'ItemNeedsAttentionActivity' }
      & Pick<IItemNeedsAttentionActivity, 'createdAt' | 'id' | 'itemId' | 'reason' | 'user'>
    ) | (
      { __typename?: 'ItemNeedsNoAttentionActivity' }
      & Pick<IItemNeedsNoAttentionActivity, 'createdAt' | 'id' | 'itemId' | 'reason' | 'user'>
    ) | (
      { __typename?: 'ItemQuantityUpdatedActivity' }
      & Pick<IItemQuantityUpdatedActivity, 'createdAt' | 'id' | 'itemId' | 'newQuantity' | 'oldQuantity' | 'orderId' | 'reason' | 'user' | 'vendorId'>
      & { vendor?: Maybe<(
        { __typename?: 'Vendor' }
        & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
      )> }
    ) | (
      { __typename?: 'ItemTakenActivity' }
      & Pick<IItemTakenActivity, 'createdAt' | 'id' | 'itemId' | 'quantity' | 'toWhere' | 'user'>
    )>, quantities: Array<(
      { __typename?: 'ItemQuantity' }
      & Pick<IItemQuantity, 'value' | 'expiresAt'>
      & { location: (
        { __typename?: 'ItemLocation' }
        & Pick<IItemLocation, 'id' | 'name' | 'isDefault'>
      ) }
    )> }
  )> }
);

export type ISearchStockItemsQueryVariables = Exact<{
  filter?: Maybe<IItemFilter>;
}>;


export type ISearchStockItemsQuery = (
  { __typename?: 'Query' }
  & { itemsSummary: Array<(
    { __typename?: 'ItemSummary' }
    & Pick<IItemSummary, 'grade' | 'id' | 'imageUrl' | 'isArchived' | 'isLowStock' | 'minLevel' | 'name' | 'parentId' | 'pricePerUnit' | 'quantity'>
  )> }
);

export type IGetItemsAndFoldersForSearchQueryVariables = Exact<{
  parentId?: Maybe<Scalars['ID']>;
}>;


export type IGetItemsAndFoldersForSearchQuery = (
  { __typename?: 'Query' }
  & { navigateStock: Array<(
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id' | 'parentId' | 'imageUrl' | 'name'>
  ) | (
    { __typename?: 'ItemSummary' }
    & Pick<IItemSummary, 'grade' | 'id' | 'imageUrl' | 'isArchived' | 'minLevel' | 'name' | 'parentId' | 'pricePerUnit' | 'quantity'>
  )> }
);

export type INavigateStockQueryVariables = Exact<{
  parentId?: Maybe<Scalars['ID']>;
}>;


export type INavigateStockQuery = (
  { __typename?: 'Query' }
  & { navigateStock: Array<(
    { __typename?: 'Folder' }
    & Pick<IFolder, 'id' | 'parentId' | 'imageUrl' | 'name'>
  ) | (
    { __typename?: 'ItemSummary' }
    & Pick<IItemSummary, 'grade' | 'id' | 'imageUrl' | 'minLevel' | 'name' | 'parentId' | 'pricePerUnit' | 'quantity' | 'isArchived'>
  )> }
);

export type IItemAlertSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type IItemAlertSubscription = (
  { __typename?: 'Subscription' }
  & { itemAlert: (
    { __typename?: 'Item' }
    & Pick<IItem, 'id' | 'name' | 'minLevel' | 'manufacturerId' | 'manufacturer'>
    & { activities: Array<{ __typename: 'ItemArchiveActivity' } | (
      { __typename: 'ItemGradedActivity' }
      & Pick<IItemGradedActivity, 'id' | 'createdAt' | 'user' | 'grade' | 'reason'>
    ) | { __typename: 'ItemMovedToLocationActivity' } | { __typename: 'ItemNeedsAttentionActivity' } | { __typename: 'ItemNeedsNoAttentionActivity' } | { __typename: 'ItemQuantityUpdatedActivity' } | (
      { __typename: 'ItemTakenActivity' }
      & Pick<IItemTakenActivity, 'id' | 'createdAt' | 'quantity' | 'user' | 'toWhere'>
    )>, quantities: Array<(
      { __typename?: 'ItemQuantity' }
      & Pick<IItemQuantity, 'value' | 'expiresAt'>
      & { location: (
        { __typename?: 'ItemLocation' }
        & Pick<IItemLocation, 'id' | 'name' | 'isDefault'>
      ) }
    )> }
  ) }
);

export type ILowStockQueryVariables = Exact<{ [key: string]: never; }>;


export type ILowStockQuery = (
  { __typename?: 'Query' }
  & { lowStock: Array<(
    { __typename?: 'LowStockItem' }
    & Pick<ILowStockItem, 'becameLowStockAt' | 'orderIds' | 'rfqIds' | 'stockId' | 'stockName' | 'imageUrl'>
  )> }
);

export type IItemLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IItemLocationsQuery = (
  { __typename?: 'Query' }
  & { itemLocations: Array<(
    { __typename?: 'ItemLocation' }
    & Pick<IItemLocation, 'id' | 'isDefault' | 'name'>
  )> }
);

export type IStockAuditSummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type IStockAuditSummariesQuery = (
  { __typename?: 'Query' }
  & { stockAuditSummaries: Array<(
    { __typename?: 'StockAuditSummary' }
    & Pick<IStockAuditSummary, 'id' | 'status' | 'createdAt' | 'createdBy'>
  )> }
);

export type IStockAuditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IStockAuditQuery = (
  { __typename?: 'Query' }
  & { stockAudit?: Maybe<(
    { __typename?: 'StockAudit' }
    & Pick<IStockAudit, 'createdAt' | 'createdBy' | 'id' | 'status'>
    & { items: Array<(
      { __typename?: 'StockAuditItem' }
      & Pick<IStockAuditItem, 'expiresAt' | 'id' | 'imageUrl' | 'locationId' | 'quantityCounted' | 'quantityExpected' | 'status' | 'stockItemId' | 'stockItemName'>
    )> }
  )> }
);

export type ISearchItemsForAuditQueryVariables = Exact<{
  filter: ISearchItemsForAuditFilter;
}>;


export type ISearchItemsForAuditQuery = (
  { __typename?: 'Query' }
  & { searchItemsForAudit: Array<(
    { __typename?: 'SearchItemsForAuditResult' }
    & Pick<ISearchItemsForAuditResult, 'stockItemId' | 'name' | 'imageUrl' | 'locationId' | 'quantity' | 'expiresAt'>
  )> }
);

export type ISearchStockQueryVariables = Exact<{
  nameTerm: Scalars['String'];
}>;


export type ISearchStockQuery = (
  { __typename?: 'Query' }
  & { searchStock: Array<(
    { __typename?: 'SearchableItem' }
    & Pick<ISearchableItem, 'id' | 'name' | 'imageUrl'>
  )> }
);

export type IFetchItemImageQueryVariables = Exact<{
  filter?: Maybe<IItemFilter>;
}>;


export type IFetchItemImageQuery = (
  { __typename?: 'Query' }
  & { itemsSummary: Array<(
    { __typename?: 'ItemSummary' }
    & Pick<IItemSummary, 'id' | 'imageUrl' | 'grade' | 'quantity'>
  )> }
);

export type ISendTenantInvitationMutationVariables = Exact<{
  input: ISendTenantInvitationInput;
}>;


export type ISendTenantInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'sendTenantInvitation'>
);

export type IAddMembersToTenantMutationVariables = Exact<{
  input: IAddMembersToTenantInput;
}>;


export type IAddMembersToTenantMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'addMembersToTenant'>
);

export type IRemoveMembersFromTenantMutationVariables = Exact<{
  input: IRemoveMembersFromTenantInput;
}>;


export type IRemoveMembersFromTenantMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'removeMembersFromTenant'>
);

export type IAddMemberToRolesForTenantMutationVariables = Exact<{
  input: IAddMemberToRolesForTenantInput;
}>;


export type IAddMemberToRolesForTenantMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'addMemberToRolesForTenant'>
);

export type IRemoveMemberFromRolesForTenantMutationVariables = Exact<{
  input: IRemoveMemberFromRolesInput;
}>;


export type IRemoveMemberFromRolesForTenantMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'removeMemberFromRolesForTenant'>
);

export type ICurrentTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type ICurrentTenantQuery = (
  { __typename?: 'Query' }
  & { currentTenant: (
    { __typename?: 'Tenant' }
    & Pick<ITenant, 'externalId' | 'statuses' | 'id' | 'logoUrl' | 'name' | 'ordersEmail' | 'ordersEmailName'>
  ) }
);

export type IAllRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type IAllRolesQuery = (
  { __typename?: 'Query' }
  & { allRoles: Array<(
    { __typename?: 'Role' }
    & Pick<IRole, 'id' | 'name'>
  )> }
);

export type ITenantMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type ITenantMembersQuery = (
  { __typename?: 'Query' }
  & { tenantMembers: Array<(
    { __typename?: 'TenantMember' }
    & Pick<ITenantMember, 'id' | 'name' | 'email'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<IRole, 'id' | 'name'>
    )> }
  )> }
);

export type ITenantInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ITenantInvitationsQuery = (
  { __typename?: 'Query' }
  & { tenantInvitations: Array<(
    { __typename?: 'TenantInvitation' }
    & Pick<ITenantInvitation, 'userEmail' | 'invitedBy' | 'createdAt' | 'expiresAt'>
  )> }
);

export type ICreateVendorMutationVariables = Exact<{
  input: ICreateVendorInput;
}>;


export type ICreateVendorMutation = (
  { __typename?: 'Mutation' }
  & { createVendor: (
    { __typename?: 'Vendor' }
    & Pick<IVendor, 'id' | 'name' | 'contactEmail' | 'contactName'>
  ) }
);

export type IUpdateVendorMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<IUpdateVendorInput>;
}>;


export type IUpdateVendorMutation = (
  { __typename?: 'Mutation' }
  & { updateVendor: (
    { __typename?: 'Vendor' }
    & Pick<IVendor, 'id' | 'name' | 'contactEmail' | 'contactName' | 'website' | 'note'>
  ) }
);

export type IDeleteVendorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IDeleteVendorMutation = (
  { __typename?: 'Mutation' }
  & { deleteVendor: (
    { __typename?: 'Vendor' }
    & Pick<IVendor, 'id'>
  ) }
);

export type IVendorsQueryVariables = Exact<{
  filter?: Maybe<IVendorFilter>;
}>;


export type IVendorsQuery = (
  { __typename?: 'Query' }
  & { vendors: Array<(
    { __typename?: 'Vendor' }
    & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
  )> }
);

export type IVendorsForRfqQueryVariables = Exact<{
  filter?: Maybe<IVendorFilter>;
}>;


export type IVendorsForRfqQuery = (
  { __typename?: 'Query' }
  & { vendors: Array<(
    { __typename?: 'Vendor' }
    & Pick<IVendor, 'contactEmail' | 'contactName' | 'hidden' | 'id' | 'name' | 'note' | 'website'>
  )> }
);

export type IVendorRepliedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type IVendorRepliedSubscription = (
  { __typename?: 'Subscription' }
  & { vendorReplied: (
    { __typename?: 'RfqResponse' }
    & Pick<IRfqResponse, 'id' | 'rfqId'>
  ) }
);


export const SetBudgetDocument = gql`
    mutation setBudget($amount: Float!) {
  setBudget(amount: $amount) {
    amount
    used
  }
}
    `;
export type ISetBudgetMutationFn = Apollo.MutationFunction<ISetBudgetMutation, ISetBudgetMutationVariables>;

/**
 * __useSetBudgetMutation__
 *
 * To run a mutation, you first call `useSetBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBudgetMutation, { data, loading, error }] = useSetBudgetMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useSetBudgetMutation(baseOptions?: Apollo.MutationHookOptions<ISetBudgetMutation, ISetBudgetMutationVariables>) {
        return Apollo.useMutation<ISetBudgetMutation, ISetBudgetMutationVariables>(SetBudgetDocument, baseOptions);
      }
export type SetBudgetMutationHookResult = ReturnType<typeof useSetBudgetMutation>;
export type SetBudgetMutationResult = Apollo.MutationResult<ISetBudgetMutation>;
export type SetBudgetMutationOptions = Apollo.BaseMutationOptions<ISetBudgetMutation, ISetBudgetMutationVariables>;
export const BudgetDocument = gql`
    query budget {
  budget {
    amount
    used
  }
}
    `;

/**
 * __useBudgetQuery__
 *
 * To run a query within a React component, call `useBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useBudgetQuery(baseOptions?: Apollo.QueryHookOptions<IBudgetQuery, IBudgetQueryVariables>) {
        return Apollo.useQuery<IBudgetQuery, IBudgetQueryVariables>(BudgetDocument, baseOptions);
      }
export function useBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IBudgetQuery, IBudgetQueryVariables>) {
          return Apollo.useLazyQuery<IBudgetQuery, IBudgetQueryVariables>(BudgetDocument, baseOptions);
        }
export type BudgetQueryHookResult = ReturnType<typeof useBudgetQuery>;
export type BudgetLazyQueryHookResult = ReturnType<typeof useBudgetLazyQuery>;
export type BudgetQueryResult = Apollo.QueryResult<IBudgetQuery, IBudgetQueryVariables>;
export const DashboardReportDocument = gql`
    query dashboardReport {
  dashboardReport {
    lowStockWithQuantity {
      itemName
      quantity
    }
    ordersByStatus {
      count
      status
    }
    rfqCount
    stockCategories {
      totalValue
      stockWithCategories {
        categoryName
        value
      }
    }
    orderSpend {
      date
      value
    }
  }
}
    `;

/**
 * __useDashboardReportQuery__
 *
 * To run a query within a React component, call `useDashboardReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardReportQuery(baseOptions?: Apollo.QueryHookOptions<IDashboardReportQuery, IDashboardReportQueryVariables>) {
        return Apollo.useQuery<IDashboardReportQuery, IDashboardReportQueryVariables>(DashboardReportDocument, baseOptions);
      }
export function useDashboardReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IDashboardReportQuery, IDashboardReportQueryVariables>) {
          return Apollo.useLazyQuery<IDashboardReportQuery, IDashboardReportQueryVariables>(DashboardReportDocument, baseOptions);
        }
export type DashboardReportQueryHookResult = ReturnType<typeof useDashboardReportQuery>;
export type DashboardReportLazyQueryHookResult = ReturnType<typeof useDashboardReportLazyQuery>;
export type DashboardReportQueryResult = Apollo.QueryResult<IDashboardReportQuery, IDashboardReportQueryVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    id
  }
}
    `;
export type ICreateOrderMutationFn = Apollo.MutationFunction<ICreateOrderMutation, ICreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<ICreateOrderMutation, ICreateOrderMutationVariables>) {
        return Apollo.useMutation<ICreateOrderMutation, ICreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<ICreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<ICreateOrderMutation, ICreateOrderMutationVariables>;
export const CreateOrdersFromRfqDocument = gql`
    mutation createOrdersFromRfq($input: CreateOrdersFromRfqInput!) {
  createOrdersFromRfq(input: $input) {
    id
  }
}
    `;
export type ICreateOrdersFromRfqMutationFn = Apollo.MutationFunction<ICreateOrdersFromRfqMutation, ICreateOrdersFromRfqMutationVariables>;

/**
 * __useCreateOrdersFromRfqMutation__
 *
 * To run a mutation, you first call `useCreateOrdersFromRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrdersFromRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrdersFromRfqMutation, { data, loading, error }] = useCreateOrdersFromRfqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrdersFromRfqMutation(baseOptions?: Apollo.MutationHookOptions<ICreateOrdersFromRfqMutation, ICreateOrdersFromRfqMutationVariables>) {
        return Apollo.useMutation<ICreateOrdersFromRfqMutation, ICreateOrdersFromRfqMutationVariables>(CreateOrdersFromRfqDocument, baseOptions);
      }
export type CreateOrdersFromRfqMutationHookResult = ReturnType<typeof useCreateOrdersFromRfqMutation>;
export type CreateOrdersFromRfqMutationResult = Apollo.MutationResult<ICreateOrdersFromRfqMutation>;
export type CreateOrdersFromRfqMutationOptions = Apollo.BaseMutationOptions<ICreateOrdersFromRfqMutation, ICreateOrdersFromRfqMutationVariables>;
export const CompleteOrderDocument = gql`
    mutation completeOrder($id: ID!, $input: [OrderItemFollowUpInput!]) {
  completeOrder(id: $id, input: $input) {
    id
  }
}
    `;
export type ICompleteOrderMutationFn = Apollo.MutationFunction<ICompleteOrderMutation, ICompleteOrderMutationVariables>;

/**
 * __useCompleteOrderMutation__
 *
 * To run a mutation, you first call `useCompleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOrderMutation, { data, loading, error }] = useCompleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<ICompleteOrderMutation, ICompleteOrderMutationVariables>) {
        return Apollo.useMutation<ICompleteOrderMutation, ICompleteOrderMutationVariables>(CompleteOrderDocument, baseOptions);
      }
export type CompleteOrderMutationHookResult = ReturnType<typeof useCompleteOrderMutation>;
export type CompleteOrderMutationResult = Apollo.MutationResult<ICompleteOrderMutation>;
export type CompleteOrderMutationOptions = Apollo.BaseMutationOptions<ICompleteOrderMutation, ICompleteOrderMutationVariables>;
export const MoveOrderItemsToRfqDocument = gql`
    mutation moveOrderItemsToRfq($id: ID!, $itemIds: [ID!]!, $moveToDraftId: ID) {
  moveOrderItemsToRfq(id: $id, itemIds: $itemIds, moveToDraftId: $moveToDraftId) {
    id
  }
}
    `;
export type IMoveOrderItemsToRfqMutationFn = Apollo.MutationFunction<IMoveOrderItemsToRfqMutation, IMoveOrderItemsToRfqMutationVariables>;

/**
 * __useMoveOrderItemsToRfqMutation__
 *
 * To run a mutation, you first call `useMoveOrderItemsToRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveOrderItemsToRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveOrderItemsToRfqMutation, { data, loading, error }] = useMoveOrderItemsToRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemIds: // value for 'itemIds'
 *      moveToDraftId: // value for 'moveToDraftId'
 *   },
 * });
 */
export function useMoveOrderItemsToRfqMutation(baseOptions?: Apollo.MutationHookOptions<IMoveOrderItemsToRfqMutation, IMoveOrderItemsToRfqMutationVariables>) {
        return Apollo.useMutation<IMoveOrderItemsToRfqMutation, IMoveOrderItemsToRfqMutationVariables>(MoveOrderItemsToRfqDocument, baseOptions);
      }
export type MoveOrderItemsToRfqMutationHookResult = ReturnType<typeof useMoveOrderItemsToRfqMutation>;
export type MoveOrderItemsToRfqMutationResult = Apollo.MutationResult<IMoveOrderItemsToRfqMutation>;
export type MoveOrderItemsToRfqMutationOptions = Apollo.BaseMutationOptions<IMoveOrderItemsToRfqMutation, IMoveOrderItemsToRfqMutationVariables>;
export const CancelOrderDocument = gql`
    mutation cancelOrder($id: ID!) {
  cancelOrder(id: $id) {
    id
  }
}
    `;
export type ICancelOrderMutationFn = Apollo.MutationFunction<ICancelOrderMutation, ICancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<ICancelOrderMutation, ICancelOrderMutationVariables>) {
        return Apollo.useMutation<ICancelOrderMutation, ICancelOrderMutationVariables>(CancelOrderDocument, baseOptions);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<ICancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<ICancelOrderMutation, ICancelOrderMutationVariables>;
export const OrdersDocument = gql`
    query orders($filter: OrderFilter) {
  orders(filter: $filter) {
    createdAt
    id
    items {
      followUpQuantity
      id
      note
      pricePerUnit
      quantity
      stockItem {
        activities {
          ... on ItemArchiveActivity {
            createdAt
            id
            isArchived
            itemId
            reason
            user
          }
          ... on ItemGradedActivity {
            createdAt
            grade
            id
            itemId
            reason
            user
          }
          ... on ItemNeedsAttentionActivity {
            createdAt
            id
            itemId
            reason
            user
          }
          ... on ItemNeedsNoAttentionActivity {
            createdAt
            id
            itemId
            reason
            user
          }
          ... on ItemQuantityUpdatedActivity {
            createdAt
            id
            itemId
            newQuantity
            oldQuantity
            orderId
            reason
            user
            vendor {
              contactEmail
              contactName
              hidden
              id
              name
              note
              website
            }
            vendorId
          }
          ... on ItemTakenActivity {
            createdAt
            id
            itemId
            quantity
            toWhere
            user
          }
          ... on ItemMovedToLocationActivity {
            createdAt
            fromLocation
            user
            toLocation
            quantityMoved
            movedItemExpiry
          }
        }
        addedToOrders
        addedToRfqs
        grade
        id
        imageUrl
        isArchived
        isLowStock
        manufacturer
        manufacturerId
        minLevel
        name
        parentId
        pricePerUnit
        quantities {
          value
          expiresAt
          location {
            id
            name
            isDefault
          }
        }
        description
        note
      }
    }
    status
    updatedAt
    vendor {
      contactEmail
      contactName
      hidden
      id
      name
      note
      website
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<IOrdersQuery, IOrdersQueryVariables>) {
        return Apollo.useQuery<IOrdersQuery, IOrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOrdersQuery, IOrdersQueryVariables>) {
          return Apollo.useLazyQuery<IOrdersQuery, IOrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<IOrdersQuery, IOrdersQueryVariables>;
export const OrderDocument = gql`
    query order($filter: OrderFilter) {
  orders(filter: $filter) {
    createdAt
    createdBy
    updatedBy
    id
    items {
      followUpQuantity
      id
      note
      pricePerUnit
      quantity
      stockItem {
        activities {
          ... on ItemArchiveActivity {
            createdAt
            id
            isArchived
            itemId
            reason
            user
          }
          ... on ItemGradedActivity {
            createdAt
            grade
            id
            itemId
            reason
            user
          }
          ... on ItemNeedsAttentionActivity {
            createdAt
            id
            itemId
            reason
            user
          }
          ... on ItemNeedsNoAttentionActivity {
            createdAt
            id
            itemId
            reason
            user
          }
          ... on ItemQuantityUpdatedActivity {
            createdAt
            id
            itemId
            newQuantity
            oldQuantity
            orderId
            reason
            user
            vendor {
              contactEmail
              contactName
              hidden
              id
              name
              note
              website
            }
            vendorId
          }
          ... on ItemTakenActivity {
            createdAt
            id
            itemId
            quantity
            toWhere
            user
          }
          ... on ItemMovedToLocationActivity {
            createdAt
            fromLocation
            user
            toLocation
            quantityMoved
            movedItemExpiry
          }
        }
        addedToOrders
        addedToRfqs
        grade
        id
        imageUrl
        isArchived
        isLowStock
        manufacturer
        manufacturerId
        minLevel
        name
        parentId
        pricePerUnit
        quantities {
          value
          expiresAt
          location {
            id
            name
            isDefault
          }
        }
        description
        note
      }
    }
    status
    updatedAt
    vendor {
      contactEmail
      contactName
      hidden
      id
      name
      note
      website
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrderQuery(baseOptions?: Apollo.QueryHookOptions<IOrderQuery, IOrderQueryVariables>) {
        return Apollo.useQuery<IOrderQuery, IOrderQueryVariables>(OrderDocument, baseOptions);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOrderQuery, IOrderQueryVariables>) {
          return Apollo.useLazyQuery<IOrderQuery, IOrderQueryVariables>(OrderDocument, baseOptions);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<IOrderQuery, IOrderQueryVariables>;
export const CreateRfqDocument = gql`
    mutation createRfq($label: String) {
  createRfq(label: $label) {
    id
  }
}
    `;
export type ICreateRfqMutationFn = Apollo.MutationFunction<ICreateRfqMutation, ICreateRfqMutationVariables>;

/**
 * __useCreateRfqMutation__
 *
 * To run a mutation, you first call `useCreateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfqMutation, { data, loading, error }] = useCreateRfqMutation({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useCreateRfqMutation(baseOptions?: Apollo.MutationHookOptions<ICreateRfqMutation, ICreateRfqMutationVariables>) {
        return Apollo.useMutation<ICreateRfqMutation, ICreateRfqMutationVariables>(CreateRfqDocument, baseOptions);
      }
export type CreateRfqMutationHookResult = ReturnType<typeof useCreateRfqMutation>;
export type CreateRfqMutationResult = Apollo.MutationResult<ICreateRfqMutation>;
export type CreateRfqMutationOptions = Apollo.BaseMutationOptions<ICreateRfqMutation, ICreateRfqMutationVariables>;
export const SetRfqLabelDocument = gql`
    mutation setRfqLabel($id: ID!, $label: String!) {
  setRfqLabel(id: $id, label: $label) {
    id
  }
}
    `;
export type ISetRfqLabelMutationFn = Apollo.MutationFunction<ISetRfqLabelMutation, ISetRfqLabelMutationVariables>;

/**
 * __useSetRfqLabelMutation__
 *
 * To run a mutation, you first call `useSetRfqLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRfqLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRfqLabelMutation, { data, loading, error }] = useSetRfqLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useSetRfqLabelMutation(baseOptions?: Apollo.MutationHookOptions<ISetRfqLabelMutation, ISetRfqLabelMutationVariables>) {
        return Apollo.useMutation<ISetRfqLabelMutation, ISetRfqLabelMutationVariables>(SetRfqLabelDocument, baseOptions);
      }
export type SetRfqLabelMutationHookResult = ReturnType<typeof useSetRfqLabelMutation>;
export type SetRfqLabelMutationResult = Apollo.MutationResult<ISetRfqLabelMutation>;
export type SetRfqLabelMutationOptions = Apollo.BaseMutationOptions<ISetRfqLabelMutation, ISetRfqLabelMutationVariables>;
export const AddVendorsToRfqDocument = gql`
    mutation addVendorsToRfq($id: ID!, $vendorIds: [ID!]!) {
  addVendorsToRfq(id: $id, vendorIds: $vendorIds) {
    id
  }
}
    `;
export type IAddVendorsToRfqMutationFn = Apollo.MutationFunction<IAddVendorsToRfqMutation, IAddVendorsToRfqMutationVariables>;

/**
 * __useAddVendorsToRfqMutation__
 *
 * To run a mutation, you first call `useAddVendorsToRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorsToRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorsToRfqMutation, { data, loading, error }] = useAddVendorsToRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vendorIds: // value for 'vendorIds'
 *   },
 * });
 */
export function useAddVendorsToRfqMutation(baseOptions?: Apollo.MutationHookOptions<IAddVendorsToRfqMutation, IAddVendorsToRfqMutationVariables>) {
        return Apollo.useMutation<IAddVendorsToRfqMutation, IAddVendorsToRfqMutationVariables>(AddVendorsToRfqDocument, baseOptions);
      }
export type AddVendorsToRfqMutationHookResult = ReturnType<typeof useAddVendorsToRfqMutation>;
export type AddVendorsToRfqMutationResult = Apollo.MutationResult<IAddVendorsToRfqMutation>;
export type AddVendorsToRfqMutationOptions = Apollo.BaseMutationOptions<IAddVendorsToRfqMutation, IAddVendorsToRfqMutationVariables>;
export const RemoveVendorsFromRfqDocument = gql`
    mutation removeVendorsFromRfq($id: ID!, $vendorIds: [ID!]!) {
  removeVendorsFromRfq(id: $id, vendorIds: $vendorIds) {
    id
  }
}
    `;
export type IRemoveVendorsFromRfqMutationFn = Apollo.MutationFunction<IRemoveVendorsFromRfqMutation, IRemoveVendorsFromRfqMutationVariables>;

/**
 * __useRemoveVendorsFromRfqMutation__
 *
 * To run a mutation, you first call `useRemoveVendorsFromRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVendorsFromRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVendorsFromRfqMutation, { data, loading, error }] = useRemoveVendorsFromRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vendorIds: // value for 'vendorIds'
 *   },
 * });
 */
export function useRemoveVendorsFromRfqMutation(baseOptions?: Apollo.MutationHookOptions<IRemoveVendorsFromRfqMutation, IRemoveVendorsFromRfqMutationVariables>) {
        return Apollo.useMutation<IRemoveVendorsFromRfqMutation, IRemoveVendorsFromRfqMutationVariables>(RemoveVendorsFromRfqDocument, baseOptions);
      }
export type RemoveVendorsFromRfqMutationHookResult = ReturnType<typeof useRemoveVendorsFromRfqMutation>;
export type RemoveVendorsFromRfqMutationResult = Apollo.MutationResult<IRemoveVendorsFromRfqMutation>;
export type RemoveVendorsFromRfqMutationOptions = Apollo.BaseMutationOptions<IRemoveVendorsFromRfqMutation, IRemoveVendorsFromRfqMutationVariables>;
export const CreateRfqItemDocument = gql`
    mutation createRfqItem($id: ID!, $input: CreateRfqItemInput!) {
  createRfqItem(id: $id, input: $input) {
    id
  }
}
    `;
export type ICreateRfqItemMutationFn = Apollo.MutationFunction<ICreateRfqItemMutation, ICreateRfqItemMutationVariables>;

/**
 * __useCreateRfqItemMutation__
 *
 * To run a mutation, you first call `useCreateRfqItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfqItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfqItemMutation, { data, loading, error }] = useCreateRfqItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRfqItemMutation(baseOptions?: Apollo.MutationHookOptions<ICreateRfqItemMutation, ICreateRfqItemMutationVariables>) {
        return Apollo.useMutation<ICreateRfqItemMutation, ICreateRfqItemMutationVariables>(CreateRfqItemDocument, baseOptions);
      }
export type CreateRfqItemMutationHookResult = ReturnType<typeof useCreateRfqItemMutation>;
export type CreateRfqItemMutationResult = Apollo.MutationResult<ICreateRfqItemMutation>;
export type CreateRfqItemMutationOptions = Apollo.BaseMutationOptions<ICreateRfqItemMutation, ICreateRfqItemMutationVariables>;
export const AddStockItemToRfqDraftDocument = gql`
    mutation addStockItemToRfqDraft($stockItemId: ID!, $rfqId: ID!, $quantity: Float!) {
  addStockItemToRfqDraft(
    stockItemId: $stockItemId
    rfqId: $rfqId
    quantity: $quantity
  ) {
    id
  }
}
    `;
export type IAddStockItemToRfqDraftMutationFn = Apollo.MutationFunction<IAddStockItemToRfqDraftMutation, IAddStockItemToRfqDraftMutationVariables>;

/**
 * __useAddStockItemToRfqDraftMutation__
 *
 * To run a mutation, you first call `useAddStockItemToRfqDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStockItemToRfqDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStockItemToRfqDraftMutation, { data, loading, error }] = useAddStockItemToRfqDraftMutation({
 *   variables: {
 *      stockItemId: // value for 'stockItemId'
 *      rfqId: // value for 'rfqId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useAddStockItemToRfqDraftMutation(baseOptions?: Apollo.MutationHookOptions<IAddStockItemToRfqDraftMutation, IAddStockItemToRfqDraftMutationVariables>) {
        return Apollo.useMutation<IAddStockItemToRfqDraftMutation, IAddStockItemToRfqDraftMutationVariables>(AddStockItemToRfqDraftDocument, baseOptions);
      }
export type AddStockItemToRfqDraftMutationHookResult = ReturnType<typeof useAddStockItemToRfqDraftMutation>;
export type AddStockItemToRfqDraftMutationResult = Apollo.MutationResult<IAddStockItemToRfqDraftMutation>;
export type AddStockItemToRfqDraftMutationOptions = Apollo.BaseMutationOptions<IAddStockItemToRfqDraftMutation, IAddStockItemToRfqDraftMutationVariables>;
export const RemoveItemsFromRfqDocument = gql`
    mutation removeItemsFromRfq($id: ID!, $itemIds: [ID!]!) {
  removeItemsFromRfq(id: $id, itemIds: $itemIds) {
    id
  }
}
    `;
export type IRemoveItemsFromRfqMutationFn = Apollo.MutationFunction<IRemoveItemsFromRfqMutation, IRemoveItemsFromRfqMutationVariables>;

/**
 * __useRemoveItemsFromRfqMutation__
 *
 * To run a mutation, you first call `useRemoveItemsFromRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemsFromRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemsFromRfqMutation, { data, loading, error }] = useRemoveItemsFromRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useRemoveItemsFromRfqMutation(baseOptions?: Apollo.MutationHookOptions<IRemoveItemsFromRfqMutation, IRemoveItemsFromRfqMutationVariables>) {
        return Apollo.useMutation<IRemoveItemsFromRfqMutation, IRemoveItemsFromRfqMutationVariables>(RemoveItemsFromRfqDocument, baseOptions);
      }
export type RemoveItemsFromRfqMutationHookResult = ReturnType<typeof useRemoveItemsFromRfqMutation>;
export type RemoveItemsFromRfqMutationResult = Apollo.MutationResult<IRemoveItemsFromRfqMutation>;
export type RemoveItemsFromRfqMutationOptions = Apollo.BaseMutationOptions<IRemoveItemsFromRfqMutation, IRemoveItemsFromRfqMutationVariables>;
export const MoveRfqItemsToDraftDocument = gql`
    mutation moveRfqItemsToDraft($id: ID!, $itemIds: [ID!]!, $moveToDraftId: ID) {
  moveRfqItemsToDraft(id: $id, itemIds: $itemIds, moveToDraftId: $moveToDraftId) {
    id
  }
}
    `;
export type IMoveRfqItemsToDraftMutationFn = Apollo.MutationFunction<IMoveRfqItemsToDraftMutation, IMoveRfqItemsToDraftMutationVariables>;

/**
 * __useMoveRfqItemsToDraftMutation__
 *
 * To run a mutation, you first call `useMoveRfqItemsToDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveRfqItemsToDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveRfqItemsToDraftMutation, { data, loading, error }] = useMoveRfqItemsToDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemIds: // value for 'itemIds'
 *      moveToDraftId: // value for 'moveToDraftId'
 *   },
 * });
 */
export function useMoveRfqItemsToDraftMutation(baseOptions?: Apollo.MutationHookOptions<IMoveRfqItemsToDraftMutation, IMoveRfqItemsToDraftMutationVariables>) {
        return Apollo.useMutation<IMoveRfqItemsToDraftMutation, IMoveRfqItemsToDraftMutationVariables>(MoveRfqItemsToDraftDocument, baseOptions);
      }
export type MoveRfqItemsToDraftMutationHookResult = ReturnType<typeof useMoveRfqItemsToDraftMutation>;
export type MoveRfqItemsToDraftMutationResult = Apollo.MutationResult<IMoveRfqItemsToDraftMutation>;
export type MoveRfqItemsToDraftMutationOptions = Apollo.BaseMutationOptions<IMoveRfqItemsToDraftMutation, IMoveRfqItemsToDraftMutationVariables>;
export const CloneRfqDocument = gql`
    mutation cloneRfq($id: ID!) {
  cloneRfq(id: $id) {
    id
  }
}
    `;
export type ICloneRfqMutationFn = Apollo.MutationFunction<ICloneRfqMutation, ICloneRfqMutationVariables>;

/**
 * __useCloneRfqMutation__
 *
 * To run a mutation, you first call `useCloneRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneRfqMutation, { data, loading, error }] = useCloneRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloneRfqMutation(baseOptions?: Apollo.MutationHookOptions<ICloneRfqMutation, ICloneRfqMutationVariables>) {
        return Apollo.useMutation<ICloneRfqMutation, ICloneRfqMutationVariables>(CloneRfqDocument, baseOptions);
      }
export type CloneRfqMutationHookResult = ReturnType<typeof useCloneRfqMutation>;
export type CloneRfqMutationResult = Apollo.MutationResult<ICloneRfqMutation>;
export type CloneRfqMutationOptions = Apollo.BaseMutationOptions<ICloneRfqMutation, ICloneRfqMutationVariables>;
export const SendRfqDocument = gql`
    mutation sendRfq($id: ID!, $vendorIds: [ID!]!) {
  sendRfq(id: $id, vendorIds: $vendorIds) {
    id
  }
}
    `;
export type ISendRfqMutationFn = Apollo.MutationFunction<ISendRfqMutation, ISendRfqMutationVariables>;

/**
 * __useSendRfqMutation__
 *
 * To run a mutation, you first call `useSendRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRfqMutation, { data, loading, error }] = useSendRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vendorIds: // value for 'vendorIds'
 *   },
 * });
 */
export function useSendRfqMutation(baseOptions?: Apollo.MutationHookOptions<ISendRfqMutation, ISendRfqMutationVariables>) {
        return Apollo.useMutation<ISendRfqMutation, ISendRfqMutationVariables>(SendRfqDocument, baseOptions);
      }
export type SendRfqMutationHookResult = ReturnType<typeof useSendRfqMutation>;
export type SendRfqMutationResult = Apollo.MutationResult<ISendRfqMutation>;
export type SendRfqMutationOptions = Apollo.BaseMutationOptions<ISendRfqMutation, ISendRfqMutationVariables>;
export const DeleteRfqDocument = gql`
    mutation deleteRfq($id: ID!) {
  deleteRfq(id: $id) {
    id
  }
}
    `;
export type IDeleteRfqMutationFn = Apollo.MutationFunction<IDeleteRfqMutation, IDeleteRfqMutationVariables>;

/**
 * __useDeleteRfqMutation__
 *
 * To run a mutation, you first call `useDeleteRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqMutation, { data, loading, error }] = useDeleteRfqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRfqMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteRfqMutation, IDeleteRfqMutationVariables>) {
        return Apollo.useMutation<IDeleteRfqMutation, IDeleteRfqMutationVariables>(DeleteRfqDocument, baseOptions);
      }
export type DeleteRfqMutationHookResult = ReturnType<typeof useDeleteRfqMutation>;
export type DeleteRfqMutationResult = Apollo.MutationResult<IDeleteRfqMutation>;
export type DeleteRfqMutationOptions = Apollo.BaseMutationOptions<IDeleteRfqMutation, IDeleteRfqMutationVariables>;
export const UpdateRfqNoteDocument = gql`
    mutation updateRfqNote($id: ID!, $input: String) {
  updateRfqNote(id: $id, input: $input) {
    id
  }
}
    `;
export type IUpdateRfqNoteMutationFn = Apollo.MutationFunction<IUpdateRfqNoteMutation, IUpdateRfqNoteMutationVariables>;

/**
 * __useUpdateRfqNoteMutation__
 *
 * To run a mutation, you first call `useUpdateRfqNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqNoteMutation, { data, loading, error }] = useUpdateRfqNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRfqNoteMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateRfqNoteMutation, IUpdateRfqNoteMutationVariables>) {
        return Apollo.useMutation<IUpdateRfqNoteMutation, IUpdateRfqNoteMutationVariables>(UpdateRfqNoteDocument, baseOptions);
      }
export type UpdateRfqNoteMutationHookResult = ReturnType<typeof useUpdateRfqNoteMutation>;
export type UpdateRfqNoteMutationResult = Apollo.MutationResult<IUpdateRfqNoteMutation>;
export type UpdateRfqNoteMutationOptions = Apollo.BaseMutationOptions<IUpdateRfqNoteMutation, IUpdateRfqNoteMutationVariables>;
export const DeleteRfqResponseItemsDocument = gql`
    mutation deleteRfqResponseItems($id: ID!, $responseItemIds: [ID!]!) {
  deleteRfqResponseItems(id: $id, responseItemIds: $responseItemIds) {
    id
  }
}
    `;
export type IDeleteRfqResponseItemsMutationFn = Apollo.MutationFunction<IDeleteRfqResponseItemsMutation, IDeleteRfqResponseItemsMutationVariables>;

/**
 * __useDeleteRfqResponseItemsMutation__
 *
 * To run a mutation, you first call `useDeleteRfqResponseItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqResponseItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqResponseItemsMutation, { data, loading, error }] = useDeleteRfqResponseItemsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      responseItemIds: // value for 'responseItemIds'
 *   },
 * });
 */
export function useDeleteRfqResponseItemsMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteRfqResponseItemsMutation, IDeleteRfqResponseItemsMutationVariables>) {
        return Apollo.useMutation<IDeleteRfqResponseItemsMutation, IDeleteRfqResponseItemsMutationVariables>(DeleteRfqResponseItemsDocument, baseOptions);
      }
export type DeleteRfqResponseItemsMutationHookResult = ReturnType<typeof useDeleteRfqResponseItemsMutation>;
export type DeleteRfqResponseItemsMutationResult = Apollo.MutationResult<IDeleteRfqResponseItemsMutation>;
export type DeleteRfqResponseItemsMutationOptions = Apollo.BaseMutationOptions<IDeleteRfqResponseItemsMutation, IDeleteRfqResponseItemsMutationVariables>;
export const UpdateRfqItemQuantityDocument = gql`
    mutation updateRfqItemQuantity($id: ID!, $itemId: ID!, $quantity: Float!) {
  updateRfqItemQuantity(id: $id, itemId: $itemId, quantity: $quantity) {
    id
    quantity
  }
}
    `;
export type IUpdateRfqItemQuantityMutationFn = Apollo.MutationFunction<IUpdateRfqItemQuantityMutation, IUpdateRfqItemQuantityMutationVariables>;

/**
 * __useUpdateRfqItemQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateRfqItemQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqItemQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqItemQuantityMutation, { data, loading, error }] = useUpdateRfqItemQuantityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateRfqItemQuantityMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateRfqItemQuantityMutation, IUpdateRfqItemQuantityMutationVariables>) {
        return Apollo.useMutation<IUpdateRfqItemQuantityMutation, IUpdateRfqItemQuantityMutationVariables>(UpdateRfqItemQuantityDocument, baseOptions);
      }
export type UpdateRfqItemQuantityMutationHookResult = ReturnType<typeof useUpdateRfqItemQuantityMutation>;
export type UpdateRfqItemQuantityMutationResult = Apollo.MutationResult<IUpdateRfqItemQuantityMutation>;
export type UpdateRfqItemQuantityMutationOptions = Apollo.BaseMutationOptions<IUpdateRfqItemQuantityMutation, IUpdateRfqItemQuantityMutationVariables>;
export const UpdateRfqItemNoteDocument = gql`
    mutation updateRfqItemNote($id: ID!, $itemId: ID!, $note: String!) {
  updateRfqItemNote(id: $id, itemId: $itemId, note: $note) {
    id
    note
  }
}
    `;
export type IUpdateRfqItemNoteMutationFn = Apollo.MutationFunction<IUpdateRfqItemNoteMutation, IUpdateRfqItemNoteMutationVariables>;

/**
 * __useUpdateRfqItemNoteMutation__
 *
 * To run a mutation, you first call `useUpdateRfqItemNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqItemNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqItemNoteMutation, { data, loading, error }] = useUpdateRfqItemNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateRfqItemNoteMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateRfqItemNoteMutation, IUpdateRfqItemNoteMutationVariables>) {
        return Apollo.useMutation<IUpdateRfqItemNoteMutation, IUpdateRfqItemNoteMutationVariables>(UpdateRfqItemNoteDocument, baseOptions);
      }
export type UpdateRfqItemNoteMutationHookResult = ReturnType<typeof useUpdateRfqItemNoteMutation>;
export type UpdateRfqItemNoteMutationResult = Apollo.MutationResult<IUpdateRfqItemNoteMutation>;
export type UpdateRfqItemNoteMutationOptions = Apollo.BaseMutationOptions<IUpdateRfqItemNoteMutation, IUpdateRfqItemNoteMutationVariables>;
export const UpdateRfqItemPriceDocument = gql`
    mutation updateRfqItemPrice($id: ID!, $itemId: ID!, $pricePerUnit: Float!) {
  updateRfqItemPrice(id: $id, itemId: $itemId, pricePerUnit: $pricePerUnit) {
    id
    pricePerUnit
  }
}
    `;
export type IUpdateRfqItemPriceMutationFn = Apollo.MutationFunction<IUpdateRfqItemPriceMutation, IUpdateRfqItemPriceMutationVariables>;

/**
 * __useUpdateRfqItemPriceMutation__
 *
 * To run a mutation, you first call `useUpdateRfqItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqItemPriceMutation, { data, loading, error }] = useUpdateRfqItemPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *      pricePerUnit: // value for 'pricePerUnit'
 *   },
 * });
 */
export function useUpdateRfqItemPriceMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateRfqItemPriceMutation, IUpdateRfqItemPriceMutationVariables>) {
        return Apollo.useMutation<IUpdateRfqItemPriceMutation, IUpdateRfqItemPriceMutationVariables>(UpdateRfqItemPriceDocument, baseOptions);
      }
export type UpdateRfqItemPriceMutationHookResult = ReturnType<typeof useUpdateRfqItemPriceMutation>;
export type UpdateRfqItemPriceMutationResult = Apollo.MutationResult<IUpdateRfqItemPriceMutation>;
export type UpdateRfqItemPriceMutationOptions = Apollo.BaseMutationOptions<IUpdateRfqItemPriceMutation, IUpdateRfqItemPriceMutationVariables>;
export const UpdateRfqResponseQuantityDocument = gql`
    mutation updateRfqResponseQuantity($rfqId: ID!, $responseItemId: ID!, $quantity: Float!) {
  updateRfqResponseQuantity(
    rfqId: $rfqId
    responseItemId: $responseItemId
    quantity: $quantity
  ) {
    id
    quantity
  }
}
    `;
export type IUpdateRfqResponseQuantityMutationFn = Apollo.MutationFunction<IUpdateRfqResponseQuantityMutation, IUpdateRfqResponseQuantityMutationVariables>;

/**
 * __useUpdateRfqResponseQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateRfqResponseQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqResponseQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqResponseQuantityMutation, { data, loading, error }] = useUpdateRfqResponseQuantityMutation({
 *   variables: {
 *      rfqId: // value for 'rfqId'
 *      responseItemId: // value for 'responseItemId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateRfqResponseQuantityMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateRfqResponseQuantityMutation, IUpdateRfqResponseQuantityMutationVariables>) {
        return Apollo.useMutation<IUpdateRfqResponseQuantityMutation, IUpdateRfqResponseQuantityMutationVariables>(UpdateRfqResponseQuantityDocument, baseOptions);
      }
export type UpdateRfqResponseQuantityMutationHookResult = ReturnType<typeof useUpdateRfqResponseQuantityMutation>;
export type UpdateRfqResponseQuantityMutationResult = Apollo.MutationResult<IUpdateRfqResponseQuantityMutation>;
export type UpdateRfqResponseQuantityMutationOptions = Apollo.BaseMutationOptions<IUpdateRfqResponseQuantityMutation, IUpdateRfqResponseQuantityMutationVariables>;
export const RfqsDocument = gql`
    query rfqs($filter: RfqFilter) {
  rfqsSummary(filter: $filter) {
    id
    label
    itemsCount
    vendors {
      contactEmail
      contactName
      hidden
      id
      name
    }
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useRfqsQuery__
 *
 * To run a query within a React component, call `useRfqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRfqsQuery(baseOptions?: Apollo.QueryHookOptions<IRfqsQuery, IRfqsQueryVariables>) {
        return Apollo.useQuery<IRfqsQuery, IRfqsQueryVariables>(RfqsDocument, baseOptions);
      }
export function useRfqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IRfqsQuery, IRfqsQueryVariables>) {
          return Apollo.useLazyQuery<IRfqsQuery, IRfqsQueryVariables>(RfqsDocument, baseOptions);
        }
export type RfqsQueryHookResult = ReturnType<typeof useRfqsQuery>;
export type RfqsLazyQueryHookResult = ReturnType<typeof useRfqsLazyQuery>;
export type RfqsQueryResult = Apollo.QueryResult<IRfqsQuery, IRfqsQueryVariables>;
export const RfqsByStatusDocument = gql`
    query rfqsByStatus($filter: RfqFilter) {
  rfqsSummary(filter: $filter) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    label
    status
    vendors {
      id
      hidden
    }
  }
}
    `;

/**
 * __useRfqsByStatusQuery__
 *
 * To run a query within a React component, call `useRfqsByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqsByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqsByStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRfqsByStatusQuery(baseOptions?: Apollo.QueryHookOptions<IRfqsByStatusQuery, IRfqsByStatusQueryVariables>) {
        return Apollo.useQuery<IRfqsByStatusQuery, IRfqsByStatusQueryVariables>(RfqsByStatusDocument, baseOptions);
      }
export function useRfqsByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IRfqsByStatusQuery, IRfqsByStatusQueryVariables>) {
          return Apollo.useLazyQuery<IRfqsByStatusQuery, IRfqsByStatusQueryVariables>(RfqsByStatusDocument, baseOptions);
        }
export type RfqsByStatusQueryHookResult = ReturnType<typeof useRfqsByStatusQuery>;
export type RfqsByStatusLazyQueryHookResult = ReturnType<typeof useRfqsByStatusLazyQuery>;
export type RfqsByStatusQueryResult = Apollo.QueryResult<IRfqsByStatusQuery, IRfqsByStatusQueryVariables>;
export const RfqsVendorsDocument = gql`
    query rfqsVendors($filter: RfqFilter) {
  rfqsSummary(filter: $filter) {
    id
    vendors {
      id
      hidden
    }
  }
}
    `;

/**
 * __useRfqsVendorsQuery__
 *
 * To run a query within a React component, call `useRfqsVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqsVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqsVendorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRfqsVendorsQuery(baseOptions?: Apollo.QueryHookOptions<IRfqsVendorsQuery, IRfqsVendorsQueryVariables>) {
        return Apollo.useQuery<IRfqsVendorsQuery, IRfqsVendorsQueryVariables>(RfqsVendorsDocument, baseOptions);
      }
export function useRfqsVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IRfqsVendorsQuery, IRfqsVendorsQueryVariables>) {
          return Apollo.useLazyQuery<IRfqsVendorsQuery, IRfqsVendorsQueryVariables>(RfqsVendorsDocument, baseOptions);
        }
export type RfqsVendorsQueryHookResult = ReturnType<typeof useRfqsVendorsQuery>;
export type RfqsVendorsLazyQueryHookResult = ReturnType<typeof useRfqsVendorsLazyQuery>;
export type RfqsVendorsQueryResult = Apollo.QueryResult<IRfqsVendorsQuery, IRfqsVendorsQueryVariables>;
export const RfqDocument = gql`
    query rfq($id: ID!) {
  rfq(id: $id) {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy
    label
    status
    note
    vendors {
      contactEmail
      contactName
      hidden
      id
      name
    }
    items {
      id
      quantity
      note
      pricePerUnit
      stockItem {
        addedToOrders
        addedToRfqs
        description
        grade
        id
        imageUrl
        isArchived
        isLowStock
        minLevel
        name
        manufacturer
        manufacturerId
        note
        parentId
        pricePerUnit
        quantities {
          value
          expiresAt
          location {
            id
            name
            isDefault
          }
        }
      }
    }
    responses {
      createdAt
      id
      items {
        id
        description
        imageUrl
        manufacturer
        manufacturerId
        name
        note
        pricePerUnit
        quantity
        rfqItemId
      }
      note
      rfqId
      vendor {
        contactEmail
        contactName
        hidden
        id
        name
        note
        website
      }
    }
  }
}
    `;

/**
 * __useRfqQuery__
 *
 * To run a query within a React component, call `useRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfqQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRfqQuery(baseOptions: Apollo.QueryHookOptions<IRfqQuery, IRfqQueryVariables>) {
        return Apollo.useQuery<IRfqQuery, IRfqQueryVariables>(RfqDocument, baseOptions);
      }
export function useRfqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IRfqQuery, IRfqQueryVariables>) {
          return Apollo.useLazyQuery<IRfqQuery, IRfqQueryVariables>(RfqDocument, baseOptions);
        }
export type RfqQueryHookResult = ReturnType<typeof useRfqQuery>;
export type RfqLazyQueryHookResult = ReturnType<typeof useRfqLazyQuery>;
export type RfqQueryResult = Apollo.QueryResult<IRfqQuery, IRfqQueryVariables>;
export const SearchProductDocument = gql`
    query searchProduct($text: String!) {
  searchProduct(text: $text) {
    category
    description
    externalId
    imageUrlLarge
    manufacturer
    name
    subCategory
  }
}
    `;

/**
 * __useSearchProductQuery__
 *
 * To run a query within a React component, call `useSearchProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchProductQuery(baseOptions: Apollo.QueryHookOptions<ISearchProductQuery, ISearchProductQueryVariables>) {
        return Apollo.useQuery<ISearchProductQuery, ISearchProductQueryVariables>(SearchProductDocument, baseOptions);
      }
export function useSearchProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchProductQuery, ISearchProductQueryVariables>) {
          return Apollo.useLazyQuery<ISearchProductQuery, ISearchProductQueryVariables>(SearchProductDocument, baseOptions);
        }
export type SearchProductQueryHookResult = ReturnType<typeof useSearchProductQuery>;
export type SearchProductLazyQueryHookResult = ReturnType<typeof useSearchProductLazyQuery>;
export type SearchProductQueryResult = Apollo.QueryResult<ISearchProductQuery, ISearchProductQueryVariables>;
export const StockReportDocument = gql`
    query stockReport {
  stockReport {
    perItem {
      name
      currentValueOfStock
      perMonth {
        amount
        month
        year
      }
    }
    currentValueOfAllStock
  }
}
    `;

/**
 * __useStockReportQuery__
 *
 * To run a query within a React component, call `useStockReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useStockReportQuery(baseOptions?: Apollo.QueryHookOptions<IStockReportQuery, IStockReportQueryVariables>) {
        return Apollo.useQuery<IStockReportQuery, IStockReportQueryVariables>(StockReportDocument, baseOptions);
      }
export function useStockReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IStockReportQuery, IStockReportQueryVariables>) {
          return Apollo.useLazyQuery<IStockReportQuery, IStockReportQueryVariables>(StockReportDocument, baseOptions);
        }
export type StockReportQueryHookResult = ReturnType<typeof useStockReportQuery>;
export type StockReportLazyQueryHookResult = ReturnType<typeof useStockReportLazyQuery>;
export type StockReportQueryResult = Apollo.QueryResult<IStockReportQuery, IStockReportQueryVariables>;
export const OrderReportDocument = gql`
    query orderReport {
  orderReport {
    perVendor {
      total
      vendorName
      usage {
        amount
        month
        year
      }
    }
  }
}
    `;

/**
 * __useOrderReportQuery__
 *
 * To run a query within a React component, call `useOrderReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderReportQuery(baseOptions?: Apollo.QueryHookOptions<IOrderReportQuery, IOrderReportQueryVariables>) {
        return Apollo.useQuery<IOrderReportQuery, IOrderReportQueryVariables>(OrderReportDocument, baseOptions);
      }
export function useOrderReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IOrderReportQuery, IOrderReportQueryVariables>) {
          return Apollo.useLazyQuery<IOrderReportQuery, IOrderReportQueryVariables>(OrderReportDocument, baseOptions);
        }
export type OrderReportQueryHookResult = ReturnType<typeof useOrderReportQuery>;
export type OrderReportLazyQueryHookResult = ReturnType<typeof useOrderReportLazyQuery>;
export type OrderReportQueryResult = Apollo.QueryResult<IOrderReportQuery, IOrderReportQueryVariables>;
export const CreateFolderDocument = gql`
    mutation createFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    id
  }
}
    `;
export type ICreateFolderMutationFn = Apollo.MutationFunction<ICreateFolderMutation, ICreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<ICreateFolderMutation, ICreateFolderMutationVariables>) {
        return Apollo.useMutation<ICreateFolderMutation, ICreateFolderMutationVariables>(CreateFolderDocument, baseOptions);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<ICreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<ICreateFolderMutation, ICreateFolderMutationVariables>;
export const CreateItemDocument = gql`
    mutation createItem($input: CreateItemInput!) {
  createItem(input: $input) {
    id
  }
}
    `;
export type ICreateItemMutationFn = Apollo.MutationFunction<ICreateItemMutation, ICreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: Apollo.MutationHookOptions<ICreateItemMutation, ICreateItemMutationVariables>) {
        return Apollo.useMutation<ICreateItemMutation, ICreateItemMutationVariables>(CreateItemDocument, baseOptions);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<ICreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<ICreateItemMutation, ICreateItemMutationVariables>;
export const CreateItemsBulkDocument = gql`
    mutation createItemsBulk($input: [CreateItemBulkInput!]!) {
  createItemsBulk(input: $input) {
    id
  }
}
    `;
export type ICreateItemsBulkMutationFn = Apollo.MutationFunction<ICreateItemsBulkMutation, ICreateItemsBulkMutationVariables>;

/**
 * __useCreateItemsBulkMutation__
 *
 * To run a mutation, you first call `useCreateItemsBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemsBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemsBulkMutation, { data, loading, error }] = useCreateItemsBulkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemsBulkMutation(baseOptions?: Apollo.MutationHookOptions<ICreateItemsBulkMutation, ICreateItemsBulkMutationVariables>) {
        return Apollo.useMutation<ICreateItemsBulkMutation, ICreateItemsBulkMutationVariables>(CreateItemsBulkDocument, baseOptions);
      }
export type CreateItemsBulkMutationHookResult = ReturnType<typeof useCreateItemsBulkMutation>;
export type CreateItemsBulkMutationResult = Apollo.MutationResult<ICreateItemsBulkMutation>;
export type CreateItemsBulkMutationOptions = Apollo.BaseMutationOptions<ICreateItemsBulkMutation, ICreateItemsBulkMutationVariables>;
export const UpdateFolderDocument = gql`
    mutation updateFolder($id: ID!, $input: UpdateFolderInput!) {
  updateFolder(id: $id, input: $input) {
    id
  }
}
    `;
export type IUpdateFolderMutationFn = Apollo.MutationFunction<IUpdateFolderMutation, IUpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateFolderMutation, IUpdateFolderMutationVariables>) {
        return Apollo.useMutation<IUpdateFolderMutation, IUpdateFolderMutationVariables>(UpdateFolderDocument, baseOptions);
      }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<IUpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<IUpdateFolderMutation, IUpdateFolderMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($id: ID!, $input: UpdateItemInput!) {
  updateItem(id: $id, input: $input) {
    id
    name
    description
    manufacturer
    manufacturerId
    minLevel
    note
  }
}
    `;
export type IUpdateItemMutationFn = Apollo.MutationFunction<IUpdateItemMutation, IUpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateItemMutation, IUpdateItemMutationVariables>) {
        return Apollo.useMutation<IUpdateItemMutation, IUpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<IUpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<IUpdateItemMutation, IUpdateItemMutationVariables>;
export const UpdateItemQuantityDocument = gql`
    mutation updateItemQuantity($input: UpdateItemQuantityInput) {
  updateItemQuantity(input: $input) {
    id
  }
}
    `;
export type IUpdateItemQuantityMutationFn = Apollo.MutationFunction<IUpdateItemQuantityMutation, IUpdateItemQuantityMutationVariables>;

/**
 * __useUpdateItemQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateItemQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemQuantityMutation, { data, loading, error }] = useUpdateItemQuantityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemQuantityMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateItemQuantityMutation, IUpdateItemQuantityMutationVariables>) {
        return Apollo.useMutation<IUpdateItemQuantityMutation, IUpdateItemQuantityMutationVariables>(UpdateItemQuantityDocument, baseOptions);
      }
export type UpdateItemQuantityMutationHookResult = ReturnType<typeof useUpdateItemQuantityMutation>;
export type UpdateItemQuantityMutationResult = Apollo.MutationResult<IUpdateItemQuantityMutation>;
export type UpdateItemQuantityMutationOptions = Apollo.BaseMutationOptions<IUpdateItemQuantityMutation, IUpdateItemQuantityMutationVariables>;
export const GradeItemDocument = gql`
    mutation gradeItem($id: ID!, $grade: ItemGrade!, $reason: String!) {
  gradeItem(id: $id, grade: $grade, reason: $reason) {
    id
    grade
  }
}
    `;
export type IGradeItemMutationFn = Apollo.MutationFunction<IGradeItemMutation, IGradeItemMutationVariables>;

/**
 * __useGradeItemMutation__
 *
 * To run a mutation, you first call `useGradeItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeItemMutation, { data, loading, error }] = useGradeItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      grade: // value for 'grade'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useGradeItemMutation(baseOptions?: Apollo.MutationHookOptions<IGradeItemMutation, IGradeItemMutationVariables>) {
        return Apollo.useMutation<IGradeItemMutation, IGradeItemMutationVariables>(GradeItemDocument, baseOptions);
      }
export type GradeItemMutationHookResult = ReturnType<typeof useGradeItemMutation>;
export type GradeItemMutationResult = Apollo.MutationResult<IGradeItemMutation>;
export type GradeItemMutationOptions = Apollo.BaseMutationOptions<IGradeItemMutation, IGradeItemMutationVariables>;
export const TakeItemDocument = gql`
    mutation takeItem($input: TakeItemInput!) {
  takeItem(input: $input) {
    id
    totalQuantity
    activities {
      __typename
    }
  }
}
    `;
export type ITakeItemMutationFn = Apollo.MutationFunction<ITakeItemMutation, ITakeItemMutationVariables>;

/**
 * __useTakeItemMutation__
 *
 * To run a mutation, you first call `useTakeItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeItemMutation, { data, loading, error }] = useTakeItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTakeItemMutation(baseOptions?: Apollo.MutationHookOptions<ITakeItemMutation, ITakeItemMutationVariables>) {
        return Apollo.useMutation<ITakeItemMutation, ITakeItemMutationVariables>(TakeItemDocument, baseOptions);
      }
export type TakeItemMutationHookResult = ReturnType<typeof useTakeItemMutation>;
export type TakeItemMutationResult = Apollo.MutationResult<ITakeItemMutation>;
export type TakeItemMutationOptions = Apollo.BaseMutationOptions<ITakeItemMutation, ITakeItemMutationVariables>;
export const UploadItemImageDocument = gql`
    mutation uploadItemImage($id: ID!, $payload: String) {
  uploadItemImage(id: $id, payload: $payload) {
    id
    imageUrl
  }
}
    `;
export type IUploadItemImageMutationFn = Apollo.MutationFunction<IUploadItemImageMutation, IUploadItemImageMutationVariables>;

/**
 * __useUploadItemImageMutation__
 *
 * To run a mutation, you first call `useUploadItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadItemImageMutation, { data, loading, error }] = useUploadItemImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUploadItemImageMutation(baseOptions?: Apollo.MutationHookOptions<IUploadItemImageMutation, IUploadItemImageMutationVariables>) {
        return Apollo.useMutation<IUploadItemImageMutation, IUploadItemImageMutationVariables>(UploadItemImageDocument, baseOptions);
      }
export type UploadItemImageMutationHookResult = ReturnType<typeof useUploadItemImageMutation>;
export type UploadItemImageMutationResult = Apollo.MutationResult<IUploadItemImageMutation>;
export type UploadItemImageMutationOptions = Apollo.BaseMutationOptions<IUploadItemImageMutation, IUploadItemImageMutationVariables>;
export const UploadFolderImageDocument = gql`
    mutation uploadFolderImage($id: ID!, $payload: String) {
  uploadFolderImage(id: $id, payload: $payload) {
    id
  }
}
    `;
export type IUploadFolderImageMutationFn = Apollo.MutationFunction<IUploadFolderImageMutation, IUploadFolderImageMutationVariables>;

/**
 * __useUploadFolderImageMutation__
 *
 * To run a mutation, you first call `useUploadFolderImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFolderImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFolderImageMutation, { data, loading, error }] = useUploadFolderImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUploadFolderImageMutation(baseOptions?: Apollo.MutationHookOptions<IUploadFolderImageMutation, IUploadFolderImageMutationVariables>) {
        return Apollo.useMutation<IUploadFolderImageMutation, IUploadFolderImageMutationVariables>(UploadFolderImageDocument, baseOptions);
      }
export type UploadFolderImageMutationHookResult = ReturnType<typeof useUploadFolderImageMutation>;
export type UploadFolderImageMutationResult = Apollo.MutationResult<IUploadFolderImageMutation>;
export type UploadFolderImageMutationOptions = Apollo.BaseMutationOptions<IUploadFolderImageMutation, IUploadFolderImageMutationVariables>;
export const SetBarcodeOnStockDocument = gql`
    mutation setBarcodeOnStock($id: ID!, $barcode: String) {
  setBarcodeOnStock(id: $id, barcode: $barcode) {
    id
  }
}
    `;
export type ISetBarcodeOnStockMutationFn = Apollo.MutationFunction<ISetBarcodeOnStockMutation, ISetBarcodeOnStockMutationVariables>;

/**
 * __useSetBarcodeOnStockMutation__
 *
 * To run a mutation, you first call `useSetBarcodeOnStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBarcodeOnStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBarcodeOnStockMutation, { data, loading, error }] = useSetBarcodeOnStockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useSetBarcodeOnStockMutation(baseOptions?: Apollo.MutationHookOptions<ISetBarcodeOnStockMutation, ISetBarcodeOnStockMutationVariables>) {
        return Apollo.useMutation<ISetBarcodeOnStockMutation, ISetBarcodeOnStockMutationVariables>(SetBarcodeOnStockDocument, baseOptions);
      }
export type SetBarcodeOnStockMutationHookResult = ReturnType<typeof useSetBarcodeOnStockMutation>;
export type SetBarcodeOnStockMutationResult = Apollo.MutationResult<ISetBarcodeOnStockMutation>;
export type SetBarcodeOnStockMutationOptions = Apollo.BaseMutationOptions<ISetBarcodeOnStockMutation, ISetBarcodeOnStockMutationVariables>;
export const MergeItemsDocument = gql`
    mutation mergeItems($input: MergeStockItemsInput!) {
  mergeItems(input: $input) {
    id
  }
}
    `;
export type IMergeItemsMutationFn = Apollo.MutationFunction<IMergeItemsMutation, IMergeItemsMutationVariables>;

/**
 * __useMergeItemsMutation__
 *
 * To run a mutation, you first call `useMergeItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeItemsMutation, { data, loading, error }] = useMergeItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeItemsMutation(baseOptions?: Apollo.MutationHookOptions<IMergeItemsMutation, IMergeItemsMutationVariables>) {
        return Apollo.useMutation<IMergeItemsMutation, IMergeItemsMutationVariables>(MergeItemsDocument, baseOptions);
      }
export type MergeItemsMutationHookResult = ReturnType<typeof useMergeItemsMutation>;
export type MergeItemsMutationResult = Apollo.MutationResult<IMergeItemsMutation>;
export type MergeItemsMutationOptions = Apollo.BaseMutationOptions<IMergeItemsMutation, IMergeItemsMutationVariables>;
export const MarkItemArchivedDocument = gql`
    mutation markItemArchived($id: ID!, $archived: Boolean!, $reason: String!) {
  markItemArchived(id: $id, archived: $archived, reason: $reason) {
    id
  }
}
    `;
export type IMarkItemArchivedMutationFn = Apollo.MutationFunction<IMarkItemArchivedMutation, IMarkItemArchivedMutationVariables>;

/**
 * __useMarkItemArchivedMutation__
 *
 * To run a mutation, you first call `useMarkItemArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkItemArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markItemArchivedMutation, { data, loading, error }] = useMarkItemArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archived: // value for 'archived'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useMarkItemArchivedMutation(baseOptions?: Apollo.MutationHookOptions<IMarkItemArchivedMutation, IMarkItemArchivedMutationVariables>) {
        return Apollo.useMutation<IMarkItemArchivedMutation, IMarkItemArchivedMutationVariables>(MarkItemArchivedDocument, baseOptions);
      }
export type MarkItemArchivedMutationHookResult = ReturnType<typeof useMarkItemArchivedMutation>;
export type MarkItemArchivedMutationResult = Apollo.MutationResult<IMarkItemArchivedMutation>;
export type MarkItemArchivedMutationOptions = Apollo.BaseMutationOptions<IMarkItemArchivedMutation, IMarkItemArchivedMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation deleteFolder($id: ID!) {
  deleteFolder(id: $id) {
    id
  }
}
    `;
export type IDeleteFolderMutationFn = Apollo.MutationFunction<IDeleteFolderMutation, IDeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteFolderMutation, IDeleteFolderMutationVariables>) {
        return Apollo.useMutation<IDeleteFolderMutation, IDeleteFolderMutationVariables>(DeleteFolderDocument, baseOptions);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<IDeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<IDeleteFolderMutation, IDeleteFolderMutationVariables>;
export const CreateItemLocationDocument = gql`
    mutation createItemLocation($name: String!) {
  createItemLocation(name: $name)
}
    `;
export type ICreateItemLocationMutationFn = Apollo.MutationFunction<ICreateItemLocationMutation, ICreateItemLocationMutationVariables>;

/**
 * __useCreateItemLocationMutation__
 *
 * To run a mutation, you first call `useCreateItemLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemLocationMutation, { data, loading, error }] = useCreateItemLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateItemLocationMutation(baseOptions?: Apollo.MutationHookOptions<ICreateItemLocationMutation, ICreateItemLocationMutationVariables>) {
        return Apollo.useMutation<ICreateItemLocationMutation, ICreateItemLocationMutationVariables>(CreateItemLocationDocument, baseOptions);
      }
export type CreateItemLocationMutationHookResult = ReturnType<typeof useCreateItemLocationMutation>;
export type CreateItemLocationMutationResult = Apollo.MutationResult<ICreateItemLocationMutation>;
export type CreateItemLocationMutationOptions = Apollo.BaseMutationOptions<ICreateItemLocationMutation, ICreateItemLocationMutationVariables>;
export const UpdateItemLocationDocument = gql`
    mutation updateItemLocation($id: ID!, $input: UpdateItemLocationInput!) {
  updateItemLocation(id: $id, input: $input)
}
    `;
export type IUpdateItemLocationMutationFn = Apollo.MutationFunction<IUpdateItemLocationMutation, IUpdateItemLocationMutationVariables>;

/**
 * __useUpdateItemLocationMutation__
 *
 * To run a mutation, you first call `useUpdateItemLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemLocationMutation, { data, loading, error }] = useUpdateItemLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemLocationMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateItemLocationMutation, IUpdateItemLocationMutationVariables>) {
        return Apollo.useMutation<IUpdateItemLocationMutation, IUpdateItemLocationMutationVariables>(UpdateItemLocationDocument, baseOptions);
      }
export type UpdateItemLocationMutationHookResult = ReturnType<typeof useUpdateItemLocationMutation>;
export type UpdateItemLocationMutationResult = Apollo.MutationResult<IUpdateItemLocationMutation>;
export type UpdateItemLocationMutationOptions = Apollo.BaseMutationOptions<IUpdateItemLocationMutation, IUpdateItemLocationMutationVariables>;
export const DeleteItemLocationDocument = gql`
    mutation deleteItemLocation($id: ID!) {
  deleteItemLocation(id: $id)
}
    `;
export type IDeleteItemLocationMutationFn = Apollo.MutationFunction<IDeleteItemLocationMutation, IDeleteItemLocationMutationVariables>;

/**
 * __useDeleteItemLocationMutation__
 *
 * To run a mutation, you first call `useDeleteItemLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemLocationMutation, { data, loading, error }] = useDeleteItemLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemLocationMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteItemLocationMutation, IDeleteItemLocationMutationVariables>) {
        return Apollo.useMutation<IDeleteItemLocationMutation, IDeleteItemLocationMutationVariables>(DeleteItemLocationDocument, baseOptions);
      }
export type DeleteItemLocationMutationHookResult = ReturnType<typeof useDeleteItemLocationMutation>;
export type DeleteItemLocationMutationResult = Apollo.MutationResult<IDeleteItemLocationMutation>;
export type DeleteItemLocationMutationOptions = Apollo.BaseMutationOptions<IDeleteItemLocationMutation, IDeleteItemLocationMutationVariables>;
export const MoveItemToLocationDocument = gql`
    mutation moveItemToLocation($id: ID!, $input: MoveItemToLocationInput!) {
  moveItemToLocation(id: $id, input: $input) {
    id
  }
}
    `;
export type IMoveItemToLocationMutationFn = Apollo.MutationFunction<IMoveItemToLocationMutation, IMoveItemToLocationMutationVariables>;

/**
 * __useMoveItemToLocationMutation__
 *
 * To run a mutation, you first call `useMoveItemToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveItemToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveItemToLocationMutation, { data, loading, error }] = useMoveItemToLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveItemToLocationMutation(baseOptions?: Apollo.MutationHookOptions<IMoveItemToLocationMutation, IMoveItemToLocationMutationVariables>) {
        return Apollo.useMutation<IMoveItemToLocationMutation, IMoveItemToLocationMutationVariables>(MoveItemToLocationDocument, baseOptions);
      }
export type MoveItemToLocationMutationHookResult = ReturnType<typeof useMoveItemToLocationMutation>;
export type MoveItemToLocationMutationResult = Apollo.MutationResult<IMoveItemToLocationMutation>;
export type MoveItemToLocationMutationOptions = Apollo.BaseMutationOptions<IMoveItemToLocationMutation, IMoveItemToLocationMutationVariables>;
export const UpdateItemExpiryDocument = gql`
    mutation updateItemExpiry($id: ID!, $input: UpdateItemExpiryInput!) {
  updateItemExpiry(id: $id, input: $input)
}
    `;
export type IUpdateItemExpiryMutationFn = Apollo.MutationFunction<IUpdateItemExpiryMutation, IUpdateItemExpiryMutationVariables>;

/**
 * __useUpdateItemExpiryMutation__
 *
 * To run a mutation, you first call `useUpdateItemExpiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemExpiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemExpiryMutation, { data, loading, error }] = useUpdateItemExpiryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemExpiryMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateItemExpiryMutation, IUpdateItemExpiryMutationVariables>) {
        return Apollo.useMutation<IUpdateItemExpiryMutation, IUpdateItemExpiryMutationVariables>(UpdateItemExpiryDocument, baseOptions);
      }
export type UpdateItemExpiryMutationHookResult = ReturnType<typeof useUpdateItemExpiryMutation>;
export type UpdateItemExpiryMutationResult = Apollo.MutationResult<IUpdateItemExpiryMutation>;
export type UpdateItemExpiryMutationOptions = Apollo.BaseMutationOptions<IUpdateItemExpiryMutation, IUpdateItemExpiryMutationVariables>;
export const CompleteStockAuditDocument = gql`
    mutation completeStockAudit($stockAuditId: ID!) {
  completeStockAudit(stockAuditId: $stockAuditId) {
    id
  }
}
    `;
export type ICompleteStockAuditMutationFn = Apollo.MutationFunction<ICompleteStockAuditMutation, ICompleteStockAuditMutationVariables>;

/**
 * __useCompleteStockAuditMutation__
 *
 * To run a mutation, you first call `useCompleteStockAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteStockAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeStockAuditMutation, { data, loading, error }] = useCompleteStockAuditMutation({
 *   variables: {
 *      stockAuditId: // value for 'stockAuditId'
 *   },
 * });
 */
export function useCompleteStockAuditMutation(baseOptions?: Apollo.MutationHookOptions<ICompleteStockAuditMutation, ICompleteStockAuditMutationVariables>) {
        return Apollo.useMutation<ICompleteStockAuditMutation, ICompleteStockAuditMutationVariables>(CompleteStockAuditDocument, baseOptions);
      }
export type CompleteStockAuditMutationHookResult = ReturnType<typeof useCompleteStockAuditMutation>;
export type CompleteStockAuditMutationResult = Apollo.MutationResult<ICompleteStockAuditMutation>;
export type CompleteStockAuditMutationOptions = Apollo.BaseMutationOptions<ICompleteStockAuditMutation, ICompleteStockAuditMutationVariables>;
export const CreateStockAuditDocument = gql`
    mutation createStockAudit {
  createStockAudit {
    id
  }
}
    `;
export type ICreateStockAuditMutationFn = Apollo.MutationFunction<ICreateStockAuditMutation, ICreateStockAuditMutationVariables>;

/**
 * __useCreateStockAuditMutation__
 *
 * To run a mutation, you first call `useCreateStockAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockAuditMutation, { data, loading, error }] = useCreateStockAuditMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStockAuditMutation(baseOptions?: Apollo.MutationHookOptions<ICreateStockAuditMutation, ICreateStockAuditMutationVariables>) {
        return Apollo.useMutation<ICreateStockAuditMutation, ICreateStockAuditMutationVariables>(CreateStockAuditDocument, baseOptions);
      }
export type CreateStockAuditMutationHookResult = ReturnType<typeof useCreateStockAuditMutation>;
export type CreateStockAuditMutationResult = Apollo.MutationResult<ICreateStockAuditMutation>;
export type CreateStockAuditMutationOptions = Apollo.BaseMutationOptions<ICreateStockAuditMutation, ICreateStockAuditMutationVariables>;
export const CreateStockAuditItemDocument = gql`
    mutation createStockAuditItem($stockAuditId: ID!, $stockItemId: ID!, $locationId: ID!, $expiresAt: DateTime, $quantityCounted: Float!) {
  createStockAuditItem(
    stockAuditId: $stockAuditId
    stockItemId: $stockItemId
    locationId: $locationId
    expiresAt: $expiresAt
    quantityCounted: $quantityCounted
  ) {
    id
  }
}
    `;
export type ICreateStockAuditItemMutationFn = Apollo.MutationFunction<ICreateStockAuditItemMutation, ICreateStockAuditItemMutationVariables>;

/**
 * __useCreateStockAuditItemMutation__
 *
 * To run a mutation, you first call `useCreateStockAuditItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockAuditItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockAuditItemMutation, { data, loading, error }] = useCreateStockAuditItemMutation({
 *   variables: {
 *      stockAuditId: // value for 'stockAuditId'
 *      stockItemId: // value for 'stockItemId'
 *      locationId: // value for 'locationId'
 *      expiresAt: // value for 'expiresAt'
 *      quantityCounted: // value for 'quantityCounted'
 *   },
 * });
 */
export function useCreateStockAuditItemMutation(baseOptions?: Apollo.MutationHookOptions<ICreateStockAuditItemMutation, ICreateStockAuditItemMutationVariables>) {
        return Apollo.useMutation<ICreateStockAuditItemMutation, ICreateStockAuditItemMutationVariables>(CreateStockAuditItemDocument, baseOptions);
      }
export type CreateStockAuditItemMutationHookResult = ReturnType<typeof useCreateStockAuditItemMutation>;
export type CreateStockAuditItemMutationResult = Apollo.MutationResult<ICreateStockAuditItemMutation>;
export type CreateStockAuditItemMutationOptions = Apollo.BaseMutationOptions<ICreateStockAuditItemMutation, ICreateStockAuditItemMutationVariables>;
export const SubmitStockAuditDocument = gql`
    mutation submitStockAudit($stockAuditId: ID!) {
  submitStockAudit(stockAuditId: $stockAuditId) {
    id
  }
}
    `;
export type ISubmitStockAuditMutationFn = Apollo.MutationFunction<ISubmitStockAuditMutation, ISubmitStockAuditMutationVariables>;

/**
 * __useSubmitStockAuditMutation__
 *
 * To run a mutation, you first call `useSubmitStockAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStockAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStockAuditMutation, { data, loading, error }] = useSubmitStockAuditMutation({
 *   variables: {
 *      stockAuditId: // value for 'stockAuditId'
 *   },
 * });
 */
export function useSubmitStockAuditMutation(baseOptions?: Apollo.MutationHookOptions<ISubmitStockAuditMutation, ISubmitStockAuditMutationVariables>) {
        return Apollo.useMutation<ISubmitStockAuditMutation, ISubmitStockAuditMutationVariables>(SubmitStockAuditDocument, baseOptions);
      }
export type SubmitStockAuditMutationHookResult = ReturnType<typeof useSubmitStockAuditMutation>;
export type SubmitStockAuditMutationResult = Apollo.MutationResult<ISubmitStockAuditMutation>;
export type SubmitStockAuditMutationOptions = Apollo.BaseMutationOptions<ISubmitStockAuditMutation, ISubmitStockAuditMutationVariables>;
export const UpdateStockAuditItemDocument = gql`
    mutation updateStockAuditItem($stockAuditId: ID!, $stockAuditItemId: ID!, $quantityCounted: Float!) {
  updateStockAuditItem(
    stockAuditId: $stockAuditId
    stockAuditItemId: $stockAuditItemId
    quantityCounted: $quantityCounted
  ) {
    id
  }
}
    `;
export type IUpdateStockAuditItemMutationFn = Apollo.MutationFunction<IUpdateStockAuditItemMutation, IUpdateStockAuditItemMutationVariables>;

/**
 * __useUpdateStockAuditItemMutation__
 *
 * To run a mutation, you first call `useUpdateStockAuditItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockAuditItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockAuditItemMutation, { data, loading, error }] = useUpdateStockAuditItemMutation({
 *   variables: {
 *      stockAuditId: // value for 'stockAuditId'
 *      stockAuditItemId: // value for 'stockAuditItemId'
 *      quantityCounted: // value for 'quantityCounted'
 *   },
 * });
 */
export function useUpdateStockAuditItemMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateStockAuditItemMutation, IUpdateStockAuditItemMutationVariables>) {
        return Apollo.useMutation<IUpdateStockAuditItemMutation, IUpdateStockAuditItemMutationVariables>(UpdateStockAuditItemDocument, baseOptions);
      }
export type UpdateStockAuditItemMutationHookResult = ReturnType<typeof useUpdateStockAuditItemMutation>;
export type UpdateStockAuditItemMutationResult = Apollo.MutationResult<IUpdateStockAuditItemMutation>;
export type UpdateStockAuditItemMutationOptions = Apollo.BaseMutationOptions<IUpdateStockAuditItemMutation, IUpdateStockAuditItemMutationVariables>;
export const CancelStockAuditDocument = gql`
    mutation cancelStockAudit($stockAuditId: ID!) {
  cancelStockAudit(stockAuditId: $stockAuditId)
}
    `;
export type ICancelStockAuditMutationFn = Apollo.MutationFunction<ICancelStockAuditMutation, ICancelStockAuditMutationVariables>;

/**
 * __useCancelStockAuditMutation__
 *
 * To run a mutation, you first call `useCancelStockAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStockAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStockAuditMutation, { data, loading, error }] = useCancelStockAuditMutation({
 *   variables: {
 *      stockAuditId: // value for 'stockAuditId'
 *   },
 * });
 */
export function useCancelStockAuditMutation(baseOptions?: Apollo.MutationHookOptions<ICancelStockAuditMutation, ICancelStockAuditMutationVariables>) {
        return Apollo.useMutation<ICancelStockAuditMutation, ICancelStockAuditMutationVariables>(CancelStockAuditDocument, baseOptions);
      }
export type CancelStockAuditMutationHookResult = ReturnType<typeof useCancelStockAuditMutation>;
export type CancelStockAuditMutationResult = Apollo.MutationResult<ICancelStockAuditMutation>;
export type CancelStockAuditMutationOptions = Apollo.BaseMutationOptions<ICancelStockAuditMutation, ICancelStockAuditMutationVariables>;
export const RemoveStockAuditItemDocument = gql`
    mutation removeStockAuditItem($stockAuditId: ID!, $stockAuditItemId: ID!) {
  removeStockAuditItem(
    stockAuditId: $stockAuditId
    stockAuditItemId: $stockAuditItemId
  ) {
    id
  }
}
    `;
export type IRemoveStockAuditItemMutationFn = Apollo.MutationFunction<IRemoveStockAuditItemMutation, IRemoveStockAuditItemMutationVariables>;

/**
 * __useRemoveStockAuditItemMutation__
 *
 * To run a mutation, you first call `useRemoveStockAuditItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStockAuditItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStockAuditItemMutation, { data, loading, error }] = useRemoveStockAuditItemMutation({
 *   variables: {
 *      stockAuditId: // value for 'stockAuditId'
 *      stockAuditItemId: // value for 'stockAuditItemId'
 *   },
 * });
 */
export function useRemoveStockAuditItemMutation(baseOptions?: Apollo.MutationHookOptions<IRemoveStockAuditItemMutation, IRemoveStockAuditItemMutationVariables>) {
        return Apollo.useMutation<IRemoveStockAuditItemMutation, IRemoveStockAuditItemMutationVariables>(RemoveStockAuditItemDocument, baseOptions);
      }
export type RemoveStockAuditItemMutationHookResult = ReturnType<typeof useRemoveStockAuditItemMutation>;
export type RemoveStockAuditItemMutationResult = Apollo.MutationResult<IRemoveStockAuditItemMutation>;
export type RemoveStockAuditItemMutationOptions = Apollo.BaseMutationOptions<IRemoveStockAuditItemMutation, IRemoveStockAuditItemMutationVariables>;
export const FoldersDocument = gql`
    query folders($filter: FolderFilter) {
  folders(filter: $filter) {
    id
    children {
      ... on Folder {
        id
        name
        imageUrl
        parentId
      }
    }
    name
    imageUrl
    parentId
  }
}
    `;

/**
 * __useFoldersQuery__
 *
 * To run a query within a React component, call `useFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFoldersQuery(baseOptions?: Apollo.QueryHookOptions<IFoldersQuery, IFoldersQueryVariables>) {
        return Apollo.useQuery<IFoldersQuery, IFoldersQueryVariables>(FoldersDocument, baseOptions);
      }
export function useFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IFoldersQuery, IFoldersQueryVariables>) {
          return Apollo.useLazyQuery<IFoldersQuery, IFoldersQueryVariables>(FoldersDocument, baseOptions);
        }
export type FoldersQueryHookResult = ReturnType<typeof useFoldersQuery>;
export type FoldersLazyQueryHookResult = ReturnType<typeof useFoldersLazyQuery>;
export type FoldersQueryResult = Apollo.QueryResult<IFoldersQuery, IFoldersQueryVariables>;
export const FoldersDropdownDocument = gql`
    query foldersDropdown($filter: FolderFilter) {
  folders(filter: $filter) {
    id
    name
    imageUrl
  }
}
    `;

/**
 * __useFoldersDropdownQuery__
 *
 * To run a query within a React component, call `useFoldersDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersDropdownQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFoldersDropdownQuery(baseOptions?: Apollo.QueryHookOptions<IFoldersDropdownQuery, IFoldersDropdownQueryVariables>) {
        return Apollo.useQuery<IFoldersDropdownQuery, IFoldersDropdownQueryVariables>(FoldersDropdownDocument, baseOptions);
      }
export function useFoldersDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IFoldersDropdownQuery, IFoldersDropdownQueryVariables>) {
          return Apollo.useLazyQuery<IFoldersDropdownQuery, IFoldersDropdownQueryVariables>(FoldersDropdownDocument, baseOptions);
        }
export type FoldersDropdownQueryHookResult = ReturnType<typeof useFoldersDropdownQuery>;
export type FoldersDropdownLazyQueryHookResult = ReturnType<typeof useFoldersDropdownLazyQuery>;
export type FoldersDropdownQueryResult = Apollo.QueryResult<IFoldersDropdownQuery, IFoldersDropdownQueryVariables>;
export const ItemsSummaryDocument = gql`
    query itemsSummary($filter: ItemFilter) {
  itemsSummary(filter: $filter) {
    grade
    id
    imageUrl
    minLevel
    name
    parentId
    pricePerUnit
    isArchived
    quantity
  }
}
    `;

/**
 * __useItemsSummaryQuery__
 *
 * To run a query within a React component, call `useItemsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsSummaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<IItemsSummaryQuery, IItemsSummaryQueryVariables>) {
        return Apollo.useQuery<IItemsSummaryQuery, IItemsSummaryQueryVariables>(ItemsSummaryDocument, baseOptions);
      }
export function useItemsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IItemsSummaryQuery, IItemsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<IItemsSummaryQuery, IItemsSummaryQueryVariables>(ItemsSummaryDocument, baseOptions);
        }
export type ItemsSummaryQueryHookResult = ReturnType<typeof useItemsSummaryQuery>;
export type ItemsSummaryLazyQueryHookResult = ReturnType<typeof useItemsSummaryLazyQuery>;
export type ItemsSummaryQueryResult = Apollo.QueryResult<IItemsSummaryQuery, IItemsSummaryQueryVariables>;
export const ItemDocument = gql`
    query item($id: ID!) {
  item(id: $id) {
    activities {
      ... on ItemArchiveActivity {
        createdAt
        id
        isArchived
        itemId
        reason
        user
      }
      ... on ItemGradedActivity {
        createdAt
        grade
        id
        itemId
        reason
        user
      }
      ... on ItemNeedsAttentionActivity {
        createdAt
        id
        itemId
        reason
        user
      }
      ... on ItemNeedsNoAttentionActivity {
        createdAt
        id
        itemId
        reason
        user
      }
      ... on ItemQuantityUpdatedActivity {
        createdAt
        id
        itemId
        newQuantity
        oldQuantity
        orderId
        reason
        user
        vendor {
          contactEmail
          contactName
          hidden
          id
          name
          note
          website
        }
        vendorId
      }
      ... on ItemTakenActivity {
        createdAt
        id
        itemId
        quantity
        toWhere
        user
      }
      ... on ItemMovedToLocationActivity {
        createdAt
        fromLocation
        user
        toLocation
        quantityMoved
        movedItemExpiry
      }
    }
    addedToOrders
    addedToRfqs
    barcode
    becameLowStockAt
    description
    grade
    id
    imageUrl
    isArchived
    isLowStock
    manufacturer
    manufacturerId
    minLevel
    name
    needsAttention
    note
    parentId
    pricePerUnit
    totalQuantity
    quantities {
      value
      expiresAt
      location {
        id
        name
        isDefault
      }
    }
  }
}
    `;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<IItemQuery, IItemQueryVariables>) {
        return Apollo.useQuery<IItemQuery, IItemQueryVariables>(ItemDocument, baseOptions);
      }
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IItemQuery, IItemQueryVariables>) {
          return Apollo.useLazyQuery<IItemQuery, IItemQueryVariables>(ItemDocument, baseOptions);
        }
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemQueryResult = Apollo.QueryResult<IItemQuery, IItemQueryVariables>;
export const SearchStockItemsDocument = gql`
    query searchStockItems($filter: ItemFilter) {
  itemsSummary(filter: $filter) {
    grade
    id
    imageUrl
    isArchived
    isLowStock
    minLevel
    name
    parentId
    pricePerUnit
    quantity
  }
}
    `;

/**
 * __useSearchStockItemsQuery__
 *
 * To run a query within a React component, call `useSearchStockItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchStockItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchStockItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchStockItemsQuery(baseOptions?: Apollo.QueryHookOptions<ISearchStockItemsQuery, ISearchStockItemsQueryVariables>) {
        return Apollo.useQuery<ISearchStockItemsQuery, ISearchStockItemsQueryVariables>(SearchStockItemsDocument, baseOptions);
      }
export function useSearchStockItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchStockItemsQuery, ISearchStockItemsQueryVariables>) {
          return Apollo.useLazyQuery<ISearchStockItemsQuery, ISearchStockItemsQueryVariables>(SearchStockItemsDocument, baseOptions);
        }
export type SearchStockItemsQueryHookResult = ReturnType<typeof useSearchStockItemsQuery>;
export type SearchStockItemsLazyQueryHookResult = ReturnType<typeof useSearchStockItemsLazyQuery>;
export type SearchStockItemsQueryResult = Apollo.QueryResult<ISearchStockItemsQuery, ISearchStockItemsQueryVariables>;
export const GetItemsAndFoldersForSearchDocument = gql`
    query getItemsAndFoldersForSearch($parentId: ID) {
  navigateStock(parentId: $parentId) {
    ... on Folder {
      id
      parentId
      imageUrl
      name
    }
    ... on ItemSummary {
      grade
      id
      imageUrl
      isArchived
      minLevel
      name
      parentId
      pricePerUnit
      quantity
    }
  }
}
    `;

/**
 * __useGetItemsAndFoldersForSearchQuery__
 *
 * To run a query within a React component, call `useGetItemsAndFoldersForSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsAndFoldersForSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsAndFoldersForSearchQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useGetItemsAndFoldersForSearchQuery(baseOptions?: Apollo.QueryHookOptions<IGetItemsAndFoldersForSearchQuery, IGetItemsAndFoldersForSearchQueryVariables>) {
        return Apollo.useQuery<IGetItemsAndFoldersForSearchQuery, IGetItemsAndFoldersForSearchQueryVariables>(GetItemsAndFoldersForSearchDocument, baseOptions);
      }
export function useGetItemsAndFoldersForSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetItemsAndFoldersForSearchQuery, IGetItemsAndFoldersForSearchQueryVariables>) {
          return Apollo.useLazyQuery<IGetItemsAndFoldersForSearchQuery, IGetItemsAndFoldersForSearchQueryVariables>(GetItemsAndFoldersForSearchDocument, baseOptions);
        }
export type GetItemsAndFoldersForSearchQueryHookResult = ReturnType<typeof useGetItemsAndFoldersForSearchQuery>;
export type GetItemsAndFoldersForSearchLazyQueryHookResult = ReturnType<typeof useGetItemsAndFoldersForSearchLazyQuery>;
export type GetItemsAndFoldersForSearchQueryResult = Apollo.QueryResult<IGetItemsAndFoldersForSearchQuery, IGetItemsAndFoldersForSearchQueryVariables>;
export const NavigateStockDocument = gql`
    query navigateStock($parentId: ID) {
  navigateStock(parentId: $parentId) {
    ... on Folder {
      id
      parentId
      imageUrl
      name
    }
    ... on ItemSummary {
      grade
      id
      imageUrl
      minLevel
      name
      parentId
      pricePerUnit
      quantity
      isArchived
    }
  }
}
    `;

/**
 * __useNavigateStockQuery__
 *
 * To run a query within a React component, call `useNavigateStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigateStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigateStockQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useNavigateStockQuery(baseOptions?: Apollo.QueryHookOptions<INavigateStockQuery, INavigateStockQueryVariables>) {
        return Apollo.useQuery<INavigateStockQuery, INavigateStockQueryVariables>(NavigateStockDocument, baseOptions);
      }
export function useNavigateStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<INavigateStockQuery, INavigateStockQueryVariables>) {
          return Apollo.useLazyQuery<INavigateStockQuery, INavigateStockQueryVariables>(NavigateStockDocument, baseOptions);
        }
export type NavigateStockQueryHookResult = ReturnType<typeof useNavigateStockQuery>;
export type NavigateStockLazyQueryHookResult = ReturnType<typeof useNavigateStockLazyQuery>;
export type NavigateStockQueryResult = Apollo.QueryResult<INavigateStockQuery, INavigateStockQueryVariables>;
export const ItemAlertDocument = gql`
    subscription itemAlert {
  itemAlert {
    id
    name
    activities {
      __typename
      ... on ItemGradedActivity {
        id
        createdAt
        user
        grade
        reason
      }
      ... on ItemTakenActivity {
        id
        createdAt
        quantity
        user
        toWhere
      }
    }
    quantities {
      value
      expiresAt
      location {
        id
        name
        isDefault
      }
    }
    minLevel
    manufacturerId
    manufacturer
  }
}
    `;

/**
 * __useItemAlertSubscription__
 *
 * To run a query within a React component, call `useItemAlertSubscription` and pass it any options that fit your needs.
 * When your component renders, `useItemAlertSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemAlertSubscription({
 *   variables: {
 *   },
 * });
 */
export function useItemAlertSubscription(baseOptions?: Apollo.SubscriptionHookOptions<IItemAlertSubscription, IItemAlertSubscriptionVariables>) {
        return Apollo.useSubscription<IItemAlertSubscription, IItemAlertSubscriptionVariables>(ItemAlertDocument, baseOptions);
      }
export type ItemAlertSubscriptionHookResult = ReturnType<typeof useItemAlertSubscription>;
export type ItemAlertSubscriptionResult = Apollo.SubscriptionResult<IItemAlertSubscription>;
export const LowStockDocument = gql`
    query lowStock {
  lowStock {
    becameLowStockAt
    orderIds
    rfqIds
    stockId
    stockName
    imageUrl
  }
}
    `;

/**
 * __useLowStockQuery__
 *
 * To run a query within a React component, call `useLowStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useLowStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLowStockQuery({
 *   variables: {
 *   },
 * });
 */
export function useLowStockQuery(baseOptions?: Apollo.QueryHookOptions<ILowStockQuery, ILowStockQueryVariables>) {
        return Apollo.useQuery<ILowStockQuery, ILowStockQueryVariables>(LowStockDocument, baseOptions);
      }
export function useLowStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILowStockQuery, ILowStockQueryVariables>) {
          return Apollo.useLazyQuery<ILowStockQuery, ILowStockQueryVariables>(LowStockDocument, baseOptions);
        }
export type LowStockQueryHookResult = ReturnType<typeof useLowStockQuery>;
export type LowStockLazyQueryHookResult = ReturnType<typeof useLowStockLazyQuery>;
export type LowStockQueryResult = Apollo.QueryResult<ILowStockQuery, ILowStockQueryVariables>;
export const ItemLocationsDocument = gql`
    query itemLocations {
  itemLocations {
    id
    isDefault
    name
  }
}
    `;

/**
 * __useItemLocationsQuery__
 *
 * To run a query within a React component, call `useItemLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemLocationsQuery(baseOptions?: Apollo.QueryHookOptions<IItemLocationsQuery, IItemLocationsQueryVariables>) {
        return Apollo.useQuery<IItemLocationsQuery, IItemLocationsQueryVariables>(ItemLocationsDocument, baseOptions);
      }
export function useItemLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IItemLocationsQuery, IItemLocationsQueryVariables>) {
          return Apollo.useLazyQuery<IItemLocationsQuery, IItemLocationsQueryVariables>(ItemLocationsDocument, baseOptions);
        }
export type ItemLocationsQueryHookResult = ReturnType<typeof useItemLocationsQuery>;
export type ItemLocationsLazyQueryHookResult = ReturnType<typeof useItemLocationsLazyQuery>;
export type ItemLocationsQueryResult = Apollo.QueryResult<IItemLocationsQuery, IItemLocationsQueryVariables>;
export const StockAuditSummariesDocument = gql`
    query stockAuditSummaries {
  stockAuditSummaries {
    id
    status
    createdAt
    createdBy
  }
}
    `;

/**
 * __useStockAuditSummariesQuery__
 *
 * To run a query within a React component, call `useStockAuditSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAuditSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockAuditSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStockAuditSummariesQuery(baseOptions?: Apollo.QueryHookOptions<IStockAuditSummariesQuery, IStockAuditSummariesQueryVariables>) {
        return Apollo.useQuery<IStockAuditSummariesQuery, IStockAuditSummariesQueryVariables>(StockAuditSummariesDocument, baseOptions);
      }
export function useStockAuditSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IStockAuditSummariesQuery, IStockAuditSummariesQueryVariables>) {
          return Apollo.useLazyQuery<IStockAuditSummariesQuery, IStockAuditSummariesQueryVariables>(StockAuditSummariesDocument, baseOptions);
        }
export type StockAuditSummariesQueryHookResult = ReturnType<typeof useStockAuditSummariesQuery>;
export type StockAuditSummariesLazyQueryHookResult = ReturnType<typeof useStockAuditSummariesLazyQuery>;
export type StockAuditSummariesQueryResult = Apollo.QueryResult<IStockAuditSummariesQuery, IStockAuditSummariesQueryVariables>;
export const StockAuditDocument = gql`
    query stockAudit($id: ID!) {
  stockAudit(id: $id) {
    createdAt
    createdBy
    id
    items {
      expiresAt
      id
      imageUrl
      locationId
      quantityCounted
      quantityExpected
      status
      stockItemId
      stockItemName
    }
    status
  }
}
    `;

/**
 * __useStockAuditQuery__
 *
 * To run a query within a React component, call `useStockAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockAuditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockAuditQuery(baseOptions: Apollo.QueryHookOptions<IStockAuditQuery, IStockAuditQueryVariables>) {
        return Apollo.useQuery<IStockAuditQuery, IStockAuditQueryVariables>(StockAuditDocument, baseOptions);
      }
export function useStockAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IStockAuditQuery, IStockAuditQueryVariables>) {
          return Apollo.useLazyQuery<IStockAuditQuery, IStockAuditQueryVariables>(StockAuditDocument, baseOptions);
        }
export type StockAuditQueryHookResult = ReturnType<typeof useStockAuditQuery>;
export type StockAuditLazyQueryHookResult = ReturnType<typeof useStockAuditLazyQuery>;
export type StockAuditQueryResult = Apollo.QueryResult<IStockAuditQuery, IStockAuditQueryVariables>;
export const SearchItemsForAuditDocument = gql`
    query searchItemsForAudit($filter: SearchItemsForAuditFilter!) {
  searchItemsForAudit(filter: $filter) {
    stockItemId
    name
    imageUrl
    locationId
    quantity
    expiresAt
  }
}
    `;

/**
 * __useSearchItemsForAuditQuery__
 *
 * To run a query within a React component, call `useSearchItemsForAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchItemsForAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchItemsForAuditQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchItemsForAuditQuery(baseOptions: Apollo.QueryHookOptions<ISearchItemsForAuditQuery, ISearchItemsForAuditQueryVariables>) {
        return Apollo.useQuery<ISearchItemsForAuditQuery, ISearchItemsForAuditQueryVariables>(SearchItemsForAuditDocument, baseOptions);
      }
export function useSearchItemsForAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchItemsForAuditQuery, ISearchItemsForAuditQueryVariables>) {
          return Apollo.useLazyQuery<ISearchItemsForAuditQuery, ISearchItemsForAuditQueryVariables>(SearchItemsForAuditDocument, baseOptions);
        }
export type SearchItemsForAuditQueryHookResult = ReturnType<typeof useSearchItemsForAuditQuery>;
export type SearchItemsForAuditLazyQueryHookResult = ReturnType<typeof useSearchItemsForAuditLazyQuery>;
export type SearchItemsForAuditQueryResult = Apollo.QueryResult<ISearchItemsForAuditQuery, ISearchItemsForAuditQueryVariables>;
export const SearchStockDocument = gql`
    query searchStock($nameTerm: String!) {
  searchStock(nameTerm: $nameTerm) {
    id
    name
    imageUrl
  }
}
    `;

/**
 * __useSearchStockQuery__
 *
 * To run a query within a React component, call `useSearchStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchStockQuery({
 *   variables: {
 *      nameTerm: // value for 'nameTerm'
 *   },
 * });
 */
export function useSearchStockQuery(baseOptions: Apollo.QueryHookOptions<ISearchStockQuery, ISearchStockQueryVariables>) {
        return Apollo.useQuery<ISearchStockQuery, ISearchStockQueryVariables>(SearchStockDocument, baseOptions);
      }
export function useSearchStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchStockQuery, ISearchStockQueryVariables>) {
          return Apollo.useLazyQuery<ISearchStockQuery, ISearchStockQueryVariables>(SearchStockDocument, baseOptions);
        }
export type SearchStockQueryHookResult = ReturnType<typeof useSearchStockQuery>;
export type SearchStockLazyQueryHookResult = ReturnType<typeof useSearchStockLazyQuery>;
export type SearchStockQueryResult = Apollo.QueryResult<ISearchStockQuery, ISearchStockQueryVariables>;
export const FetchItemImageDocument = gql`
    query fetchItemImage($filter: ItemFilter) {
  itemsSummary(filter: $filter) {
    id
    imageUrl
    grade
    quantity
  }
}
    `;

/**
 * __useFetchItemImageQuery__
 *
 * To run a query within a React component, call `useFetchItemImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchItemImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchItemImageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchItemImageQuery(baseOptions?: Apollo.QueryHookOptions<IFetchItemImageQuery, IFetchItemImageQueryVariables>) {
        return Apollo.useQuery<IFetchItemImageQuery, IFetchItemImageQueryVariables>(FetchItemImageDocument, baseOptions);
      }
export function useFetchItemImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IFetchItemImageQuery, IFetchItemImageQueryVariables>) {
          return Apollo.useLazyQuery<IFetchItemImageQuery, IFetchItemImageQueryVariables>(FetchItemImageDocument, baseOptions);
        }
export type FetchItemImageQueryHookResult = ReturnType<typeof useFetchItemImageQuery>;
export type FetchItemImageLazyQueryHookResult = ReturnType<typeof useFetchItemImageLazyQuery>;
export type FetchItemImageQueryResult = Apollo.QueryResult<IFetchItemImageQuery, IFetchItemImageQueryVariables>;
export const SendTenantInvitationDocument = gql`
    mutation SendTenantInvitation($input: SendTenantInvitationInput!) {
  sendTenantInvitation(input: $input)
}
    `;
export type ISendTenantInvitationMutationFn = Apollo.MutationFunction<ISendTenantInvitationMutation, ISendTenantInvitationMutationVariables>;

/**
 * __useSendTenantInvitationMutation__
 *
 * To run a mutation, you first call `useSendTenantInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTenantInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTenantInvitationMutation, { data, loading, error }] = useSendTenantInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTenantInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ISendTenantInvitationMutation, ISendTenantInvitationMutationVariables>) {
        return Apollo.useMutation<ISendTenantInvitationMutation, ISendTenantInvitationMutationVariables>(SendTenantInvitationDocument, baseOptions);
      }
export type SendTenantInvitationMutationHookResult = ReturnType<typeof useSendTenantInvitationMutation>;
export type SendTenantInvitationMutationResult = Apollo.MutationResult<ISendTenantInvitationMutation>;
export type SendTenantInvitationMutationOptions = Apollo.BaseMutationOptions<ISendTenantInvitationMutation, ISendTenantInvitationMutationVariables>;
export const AddMembersToTenantDocument = gql`
    mutation AddMembersToTenant($input: AddMembersToTenantInput!) {
  addMembersToTenant(input: $input)
}
    `;
export type IAddMembersToTenantMutationFn = Apollo.MutationFunction<IAddMembersToTenantMutation, IAddMembersToTenantMutationVariables>;

/**
 * __useAddMembersToTenantMutation__
 *
 * To run a mutation, you first call `useAddMembersToTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToTenantMutation, { data, loading, error }] = useAddMembersToTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMembersToTenantMutation(baseOptions?: Apollo.MutationHookOptions<IAddMembersToTenantMutation, IAddMembersToTenantMutationVariables>) {
        return Apollo.useMutation<IAddMembersToTenantMutation, IAddMembersToTenantMutationVariables>(AddMembersToTenantDocument, baseOptions);
      }
export type AddMembersToTenantMutationHookResult = ReturnType<typeof useAddMembersToTenantMutation>;
export type AddMembersToTenantMutationResult = Apollo.MutationResult<IAddMembersToTenantMutation>;
export type AddMembersToTenantMutationOptions = Apollo.BaseMutationOptions<IAddMembersToTenantMutation, IAddMembersToTenantMutationVariables>;
export const RemoveMembersFromTenantDocument = gql`
    mutation RemoveMembersFromTenant($input: RemoveMembersFromTenantInput!) {
  removeMembersFromTenant(input: $input)
}
    `;
export type IRemoveMembersFromTenantMutationFn = Apollo.MutationFunction<IRemoveMembersFromTenantMutation, IRemoveMembersFromTenantMutationVariables>;

/**
 * __useRemoveMembersFromTenantMutation__
 *
 * To run a mutation, you first call `useRemoveMembersFromTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMembersFromTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMembersFromTenantMutation, { data, loading, error }] = useRemoveMembersFromTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMembersFromTenantMutation(baseOptions?: Apollo.MutationHookOptions<IRemoveMembersFromTenantMutation, IRemoveMembersFromTenantMutationVariables>) {
        return Apollo.useMutation<IRemoveMembersFromTenantMutation, IRemoveMembersFromTenantMutationVariables>(RemoveMembersFromTenantDocument, baseOptions);
      }
export type RemoveMembersFromTenantMutationHookResult = ReturnType<typeof useRemoveMembersFromTenantMutation>;
export type RemoveMembersFromTenantMutationResult = Apollo.MutationResult<IRemoveMembersFromTenantMutation>;
export type RemoveMembersFromTenantMutationOptions = Apollo.BaseMutationOptions<IRemoveMembersFromTenantMutation, IRemoveMembersFromTenantMutationVariables>;
export const AddMemberToRolesForTenantDocument = gql`
    mutation AddMemberToRolesForTenant($input: AddMemberToRolesForTenantInput!) {
  addMemberToRolesForTenant(input: $input)
}
    `;
export type IAddMemberToRolesForTenantMutationFn = Apollo.MutationFunction<IAddMemberToRolesForTenantMutation, IAddMemberToRolesForTenantMutationVariables>;

/**
 * __useAddMemberToRolesForTenantMutation__
 *
 * To run a mutation, you first call `useAddMemberToRolesForTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToRolesForTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToRolesForTenantMutation, { data, loading, error }] = useAddMemberToRolesForTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberToRolesForTenantMutation(baseOptions?: Apollo.MutationHookOptions<IAddMemberToRolesForTenantMutation, IAddMemberToRolesForTenantMutationVariables>) {
        return Apollo.useMutation<IAddMemberToRolesForTenantMutation, IAddMemberToRolesForTenantMutationVariables>(AddMemberToRolesForTenantDocument, baseOptions);
      }
export type AddMemberToRolesForTenantMutationHookResult = ReturnType<typeof useAddMemberToRolesForTenantMutation>;
export type AddMemberToRolesForTenantMutationResult = Apollo.MutationResult<IAddMemberToRolesForTenantMutation>;
export type AddMemberToRolesForTenantMutationOptions = Apollo.BaseMutationOptions<IAddMemberToRolesForTenantMutation, IAddMemberToRolesForTenantMutationVariables>;
export const RemoveMemberFromRolesForTenantDocument = gql`
    mutation RemoveMemberFromRolesForTenant($input: RemoveMemberFromRolesInput!) {
  removeMemberFromRolesForTenant(input: $input)
}
    `;
export type IRemoveMemberFromRolesForTenantMutationFn = Apollo.MutationFunction<IRemoveMemberFromRolesForTenantMutation, IRemoveMemberFromRolesForTenantMutationVariables>;

/**
 * __useRemoveMemberFromRolesForTenantMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromRolesForTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromRolesForTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromRolesForTenantMutation, { data, loading, error }] = useRemoveMemberFromRolesForTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberFromRolesForTenantMutation(baseOptions?: Apollo.MutationHookOptions<IRemoveMemberFromRolesForTenantMutation, IRemoveMemberFromRolesForTenantMutationVariables>) {
        return Apollo.useMutation<IRemoveMemberFromRolesForTenantMutation, IRemoveMemberFromRolesForTenantMutationVariables>(RemoveMemberFromRolesForTenantDocument, baseOptions);
      }
export type RemoveMemberFromRolesForTenantMutationHookResult = ReturnType<typeof useRemoveMemberFromRolesForTenantMutation>;
export type RemoveMemberFromRolesForTenantMutationResult = Apollo.MutationResult<IRemoveMemberFromRolesForTenantMutation>;
export type RemoveMemberFromRolesForTenantMutationOptions = Apollo.BaseMutationOptions<IRemoveMemberFromRolesForTenantMutation, IRemoveMemberFromRolesForTenantMutationVariables>;
export const CurrentTenantDocument = gql`
    query currentTenant {
  currentTenant {
    externalId
    statuses
    id
    logoUrl
    name
    ordersEmail
    ordersEmailName
  }
}
    `;

/**
 * __useCurrentTenantQuery__
 *
 * To run a query within a React component, call `useCurrentTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTenantQuery(baseOptions?: Apollo.QueryHookOptions<ICurrentTenantQuery, ICurrentTenantQueryVariables>) {
        return Apollo.useQuery<ICurrentTenantQuery, ICurrentTenantQueryVariables>(CurrentTenantDocument, baseOptions);
      }
export function useCurrentTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICurrentTenantQuery, ICurrentTenantQueryVariables>) {
          return Apollo.useLazyQuery<ICurrentTenantQuery, ICurrentTenantQueryVariables>(CurrentTenantDocument, baseOptions);
        }
export type CurrentTenantQueryHookResult = ReturnType<typeof useCurrentTenantQuery>;
export type CurrentTenantLazyQueryHookResult = ReturnType<typeof useCurrentTenantLazyQuery>;
export type CurrentTenantQueryResult = Apollo.QueryResult<ICurrentTenantQuery, ICurrentTenantQueryVariables>;
export const AllRolesDocument = gql`
    query allRoles {
  allRoles {
    id
    name
  }
}
    `;

/**
 * __useAllRolesQuery__
 *
 * To run a query within a React component, call `useAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<IAllRolesQuery, IAllRolesQueryVariables>) {
        return Apollo.useQuery<IAllRolesQuery, IAllRolesQueryVariables>(AllRolesDocument, baseOptions);
      }
export function useAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAllRolesQuery, IAllRolesQueryVariables>) {
          return Apollo.useLazyQuery<IAllRolesQuery, IAllRolesQueryVariables>(AllRolesDocument, baseOptions);
        }
export type AllRolesQueryHookResult = ReturnType<typeof useAllRolesQuery>;
export type AllRolesLazyQueryHookResult = ReturnType<typeof useAllRolesLazyQuery>;
export type AllRolesQueryResult = Apollo.QueryResult<IAllRolesQuery, IAllRolesQueryVariables>;
export const TenantMembersDocument = gql`
    query tenantMembers {
  tenantMembers {
    id
    name
    email
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useTenantMembersQuery__
 *
 * To run a query within a React component, call `useTenantMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantMembersQuery(baseOptions?: Apollo.QueryHookOptions<ITenantMembersQuery, ITenantMembersQueryVariables>) {
        return Apollo.useQuery<ITenantMembersQuery, ITenantMembersQueryVariables>(TenantMembersDocument, baseOptions);
      }
export function useTenantMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITenantMembersQuery, ITenantMembersQueryVariables>) {
          return Apollo.useLazyQuery<ITenantMembersQuery, ITenantMembersQueryVariables>(TenantMembersDocument, baseOptions);
        }
export type TenantMembersQueryHookResult = ReturnType<typeof useTenantMembersQuery>;
export type TenantMembersLazyQueryHookResult = ReturnType<typeof useTenantMembersLazyQuery>;
export type TenantMembersQueryResult = Apollo.QueryResult<ITenantMembersQuery, ITenantMembersQueryVariables>;
export const TenantInvitationsDocument = gql`
    query tenantInvitations {
  tenantInvitations {
    userEmail
    invitedBy
    createdAt
    expiresAt
  }
}
    `;

/**
 * __useTenantInvitationsQuery__
 *
 * To run a query within a React component, call `useTenantInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<ITenantInvitationsQuery, ITenantInvitationsQueryVariables>) {
        return Apollo.useQuery<ITenantInvitationsQuery, ITenantInvitationsQueryVariables>(TenantInvitationsDocument, baseOptions);
      }
export function useTenantInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ITenantInvitationsQuery, ITenantInvitationsQueryVariables>) {
          return Apollo.useLazyQuery<ITenantInvitationsQuery, ITenantInvitationsQueryVariables>(TenantInvitationsDocument, baseOptions);
        }
export type TenantInvitationsQueryHookResult = ReturnType<typeof useTenantInvitationsQuery>;
export type TenantInvitationsLazyQueryHookResult = ReturnType<typeof useTenantInvitationsLazyQuery>;
export type TenantInvitationsQueryResult = Apollo.QueryResult<ITenantInvitationsQuery, ITenantInvitationsQueryVariables>;
export const CreateVendorDocument = gql`
    mutation createVendor($input: CreateVendorInput!) {
  createVendor(input: $input) {
    id
    name
    contactEmail
    contactName
  }
}
    `;
export type ICreateVendorMutationFn = Apollo.MutationFunction<ICreateVendorMutation, ICreateVendorMutationVariables>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorMutation(baseOptions?: Apollo.MutationHookOptions<ICreateVendorMutation, ICreateVendorMutationVariables>) {
        return Apollo.useMutation<ICreateVendorMutation, ICreateVendorMutationVariables>(CreateVendorDocument, baseOptions);
      }
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>;
export type CreateVendorMutationResult = Apollo.MutationResult<ICreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<ICreateVendorMutation, ICreateVendorMutationVariables>;
export const UpdateVendorDocument = gql`
    mutation updateVendor($id: ID!, $input: UpdateVendorInput) {
  updateVendor(id: $id, input: $input) {
    id
    name
    contactEmail
    contactName
    website
    note
  }
}
    `;
export type IUpdateVendorMutationFn = Apollo.MutationFunction<IUpdateVendorMutation, IUpdateVendorMutationVariables>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateVendorMutation, IUpdateVendorMutationVariables>) {
        return Apollo.useMutation<IUpdateVendorMutation, IUpdateVendorMutationVariables>(UpdateVendorDocument, baseOptions);
      }
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = Apollo.MutationResult<IUpdateVendorMutation>;
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<IUpdateVendorMutation, IUpdateVendorMutationVariables>;
export const DeleteVendorDocument = gql`
    mutation deleteVendor($id: ID!) {
  deleteVendor(id: $id) {
    id
  }
}
    `;
export type IDeleteVendorMutationFn = Apollo.MutationFunction<IDeleteVendorMutation, IDeleteVendorMutationVariables>;

/**
 * __useDeleteVendorMutation__
 *
 * To run a mutation, you first call `useDeleteVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorMutation, { data, loading, error }] = useDeleteVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteVendorMutation, IDeleteVendorMutationVariables>) {
        return Apollo.useMutation<IDeleteVendorMutation, IDeleteVendorMutationVariables>(DeleteVendorDocument, baseOptions);
      }
export type DeleteVendorMutationHookResult = ReturnType<typeof useDeleteVendorMutation>;
export type DeleteVendorMutationResult = Apollo.MutationResult<IDeleteVendorMutation>;
export type DeleteVendorMutationOptions = Apollo.BaseMutationOptions<IDeleteVendorMutation, IDeleteVendorMutationVariables>;
export const VendorsDocument = gql`
    query vendors($filter: VendorFilter) {
  vendors(filter: $filter) {
    contactEmail
    contactName
    hidden
    id
    name
    note
    website
  }
}
    `;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions?: Apollo.QueryHookOptions<IVendorsQuery, IVendorsQueryVariables>) {
        return Apollo.useQuery<IVendorsQuery, IVendorsQueryVariables>(VendorsDocument, baseOptions);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IVendorsQuery, IVendorsQueryVariables>) {
          return Apollo.useLazyQuery<IVendorsQuery, IVendorsQueryVariables>(VendorsDocument, baseOptions);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<IVendorsQuery, IVendorsQueryVariables>;
export const VendorsForRfqDocument = gql`
    query vendorsForRfq($filter: VendorFilter) {
  vendors(filter: $filter) {
    contactEmail
    contactName
    hidden
    id
    name
    note
    website
  }
}
    `;

/**
 * __useVendorsForRfqQuery__
 *
 * To run a query within a React component, call `useVendorsForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsForRfqQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVendorsForRfqQuery(baseOptions?: Apollo.QueryHookOptions<IVendorsForRfqQuery, IVendorsForRfqQueryVariables>) {
        return Apollo.useQuery<IVendorsForRfqQuery, IVendorsForRfqQueryVariables>(VendorsForRfqDocument, baseOptions);
      }
export function useVendorsForRfqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IVendorsForRfqQuery, IVendorsForRfqQueryVariables>) {
          return Apollo.useLazyQuery<IVendorsForRfqQuery, IVendorsForRfqQueryVariables>(VendorsForRfqDocument, baseOptions);
        }
export type VendorsForRfqQueryHookResult = ReturnType<typeof useVendorsForRfqQuery>;
export type VendorsForRfqLazyQueryHookResult = ReturnType<typeof useVendorsForRfqLazyQuery>;
export type VendorsForRfqQueryResult = Apollo.QueryResult<IVendorsForRfqQuery, IVendorsForRfqQueryVariables>;
export const VendorRepliedDocument = gql`
    subscription vendorReplied {
  vendorReplied {
    id
    rfqId
  }
}
    `;

/**
 * __useVendorRepliedSubscription__
 *
 * To run a query within a React component, call `useVendorRepliedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVendorRepliedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorRepliedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVendorRepliedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<IVendorRepliedSubscription, IVendorRepliedSubscriptionVariables>) {
        return Apollo.useSubscription<IVendorRepliedSubscription, IVendorRepliedSubscriptionVariables>(VendorRepliedDocument, baseOptions);
      }
export type VendorRepliedSubscriptionHookResult = ReturnType<typeof useVendorRepliedSubscription>;
export type VendorRepliedSubscriptionResult = Apollo.SubscriptionResult<IVendorRepliedSubscription>;