import {
  IFolder,
  IItem,
  IItemSummary,
  ISearchableItem,
  ItemGrade,
} from "interfaces/graphql";

import { create } from "zustand";
import { persist } from "zustand/middleware";

// Current view is used to determine if
// 1. User landed on the Stock page or inside a folder (FOLDERS)
// 2. User is using search box which uses fuzzy search and only returns item id, name and imageUrl (SEARCH)
// 3. User is using filters (ITEM_DETAILS)
export enum StockPageViewType {
  FOLDERS = "folders",
  SEARCH = "search",
  ITEM_DETAILS = "itemDetails",
}

export interface FilterState {
  // currently in use
  parentId?: string;
  isArchived?: boolean;
  isLowStock?: boolean;
  quantityLessOrEqualTo?: number;

  // available but not in use
  text?: string;
  needsAttention?: boolean;
  barcode?: string;

  // Add additional filters as needed
  // ...
}

export type ISelectedItem = Omit<IItemSummary, "__typename"> &
  Partial<Omit<IItem, "__typename">> & {
    __typename?: "ItemSummary" | "Item";
  };

// Define the complete StockState interface
interface StockState {
  currentView: StockPageViewType;
  currentFolderId: string | null;
  currentFolderName: string | null;
  folders: IFolder[];
  items: IItemSummary[];
  searchBoxInput: string;
  searchResults: (ISearchableItem | IItemSummary)[];
  filteredResults: IItemSummary[];
  filters: FilterState;
  isFilterVisible: boolean;
  selectedItem: ISelectedItem | null;
  isGridView: boolean;
  isSearchStockLoading: boolean;
  isSearchStockError: boolean;
  setSearchBoxInput: (input: string) => void;
  updateSelectedItemGrade: (grade: ItemGrade) => void;
  enrichSelectedItem: (fullItemData: IItem) => void;
  updateSelectedItemQuantity: (newQuantity: number) => void;
  addRfqToSelectedItem: (rfqId: string) => void;
  updateStockItemDetails: (
    itemId: string,
    updates: Partial<IItemSummary>
  ) => void;

  // Action functions
  setView: (view: StockPageViewType) => void;
  setIsSearchStockLoading: (isLoading: boolean) => void;
  setIsSearchStockError: (isError: boolean) => void;
  setCurrentFolder: (
    folderId: string | null,
    folderName: string | null
  ) => void;
  setFolders: (folders: IFolder[]) => void;
  setItems: (items: IItemSummary[]) => void;
  setSearchResults: (results: (ISearchableItem | IItemSummary)[]) => void;
  setFilters: (filters: FilterState) => void;
  setFilteredResults: (items: IItemSummary[]) => void;
  setIsFilterVisible: (isVisible: boolean) => void;
  setSelectedItem: (item: IItemSummary | ISelectedItem | null) => void;
  toggleViewMode: () => void;
}

export const useStockStore = create<StockState>()(
  persist(
    (set) => ({
      currentView: StockPageViewType.FOLDERS,
      currentFolderId: null,
      currentFolderName: null,
      folders: [],
      items: [],
      searchBoxInput: "",
      searchResults: [],
      filteredResults: [],
      filters: {},
      isFilterVisible: false,
      selectedItem: null,
      isDrawerOpen: false,
      isGridView: true,
      isSearchStockLoading: false,
      isSearchStockError: false,
      setSearchBoxInput: (input: string) => set({ searchBoxInput: input }),
      updateSelectedItemGrade: (grade: ItemGrade) =>
        set((state) => {
          if (!state.selectedItem) return state;

          return {
            selectedItem: {
              ...state.selectedItem,
              grade,
            },
          };
        }),
      enrichSelectedItem: (fullItemData) =>
        set((state) => {
          if (!state.selectedItem || state.selectedItem.id !== fullItemData.id)
            return state;

          // Create a new object without __typename
          const { __typename, ...itemDataWithoutTypename } = fullItemData;
          return {
            selectedItem: {
              ...state.selectedItem,
              ...itemDataWithoutTypename,
              totalQuantity: fullItemData.totalQuantity,
              __typename: state.selectedItem.__typename,
            },
          };
        }),
      updateSelectedItemQuantity: (newQuantity) =>
        set((state) => {
          if (!state.selectedItem) return state;

          // Update the item in the items array if it exists
          const updatedItems = state.items.map((item) =>
            item.id === state.selectedItem?.id
              ? { ...item, quantity: newQuantity }
              : item
          );

          // Update the item in filteredResults if it exists
          const updatedFilteredResults = state.filteredResults.map((item) =>
            item.id === state.selectedItem?.id
              ? { ...item, quantity: newQuantity }
              : item
          );

          // Update the item in searchResults if it's an IItemSummary
          const updatedSearchResults = state.searchResults.map((item) => {
            if (item.id === state.selectedItem?.id && "quantity" in item) {
              return { ...item, quantity: newQuantity };
            }
            return item;
          });

          return {
            selectedItem: {
              ...state.selectedItem,
              quantity: newQuantity,
            },
            items: updatedItems,
            filteredResults: updatedFilteredResults,
            searchResults: updatedSearchResults,
          };
        }),
      addRfqToSelectedItem: (rfqId: string) =>
        set((state) => {
          if (!state.selectedItem) return state;

          const currentRfqs = state.selectedItem.addedToRfqs || [];

          if (currentRfqs.includes(rfqId)) {
            return state;
          }

          return {
            selectedItem: {
              ...state.selectedItem,
              addedToRfqs: [...currentRfqs, rfqId],
            },
          };
        }),
      setView: (view: StockPageViewType) => set({ currentView: view }),
      setIsSearchStockLoading: (isLoading: boolean) =>
        set({ isSearchStockLoading: isLoading }),
      setIsSearchStockError: (isError: boolean) =>
        set({ isSearchStockError: isError }),
      setCurrentFolder: (folderId, folderName) =>
        set({ currentFolderId: folderId, currentFolderName: folderName }),
      setFolders: (folders: IFolder[]) => set({ folders }),
      setItems: (items: IItemSummary[]) => set({ items }),
      setSearchResults: (results: (ISearchableItem | IItemSummary)[]) =>
        set({ searchResults: results }),
      setFilters: (filters: FilterState) => set({ filters }),
      setIsFilterVisible: (isVisible: boolean) =>
        set({ isFilterVisible: isVisible }),
      setFilteredResults: (items: IItemSummary[]) =>
        set({ filteredResults: items }),
      setSelectedItem: (item: IItemSummary | ISelectedItem | null) =>
        set({ selectedItem: item }),
      toggleViewMode: () => set((state) => ({ isGridView: !state.isGridView })),
      updateStockItemDetails: (itemId, updates) =>
        set((state) => {
          // Update items in main collections
          const updatedItems = state.items.map((item) =>
            item.id === itemId ? { ...item, ...updates } : item
          );

          // Update filtered results
          const updatedFilteredResults = state.filteredResults.map((item) =>
            item.id === itemId ? { ...item, ...updates } : item
          );

          // Update search results
          const updatedSearchResults = state.searchResults.map((item) => {
            if (item.id === itemId) {
              // Apply updates for properties that exist in the item
              // Using a simpler approach to avoid TypeScript errors with dynamic keys
              const updatedItem = { ...item };
              Object.keys(updates).forEach((key) => {
                if (key in item) {
                  // Type assertion to handle dynamic property access
                  (updatedItem as any)[key] = (updates as any)[key];
                }
              });

              return updatedItem;
            }
            return item;
          });

          // Check if this is the currently selected item
          const updatedSelectedItem =
            state.selectedItem && state.selectedItem.id === itemId
              ? { ...state.selectedItem, ...updates }
              : state.selectedItem;

          return {
            items: updatedItems,
            filteredResults: updatedFilteredResults,
            searchResults: updatedSearchResults,
            selectedItem: updatedSelectedItem,
          };
        }),
    }),
    {
      name: "stock-store", // Key used in localStorage for persistence
    }
  )
);
