import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./DropdownFilter.module.css";
import useScreenSize from "hooks/useScreenSize/useScreenSize";

interface DropdownFilterProps {
  label: string;
  selectedValue: string | null;
  onClear: () => void;
  onSelect: (id: string, name: string) => void;
  fetchOptions: () => Promise<{ id: string; name: string }[]>;
  noSearch?: boolean; // ✅ New prop to hide search bar
}

const DropdownFilter = ({
  label,
  selectedValue,
  onClear,
  onSelect,
  fetchOptions,
  noSearch = false,
}: DropdownFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [options, setOptions] = useState<{ id: string; name: string }[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    setLoading(true);
    fetchOptions()
      .then((data) => setOptions(data))
      .finally(() => setLoading(false));
  }, [fetchOptions]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (id: string, name: string) => {
    onSelect(id, name);
    setAnchorEl(null);
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClear();
  };

  return (
    <Box sx={{ width: isMobile ? "100%" : "auto" }}>
      {/* Styled Dropdown Button - Updated to match ToggleFilter */}
      <Button
        variant="outlined"
        onClick={handleClick}
        size="small"
        className={styles.dropdownButton}
        fullWidth={isMobile}
        sx={{
          borderRadius: "24px",
          padding: "6px 16px",
          fontWeight: selectedValue ? "bold" : "normal",
          minWidth: "120px",
          height: "32px",
          textTransform: "none",
          width: isMobile ? "100%" : "auto",
        }}
        endIcon={
          selectedValue ? (
            <IconButton
              size="small"
              onClick={handleClear}
              className={styles.clearButton}
              sx={{ padding: 0 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : (
            <ArrowDropDownIcon />
          )
        }
      >
        {selectedValue || label}
      </Button>

      {/* ✅ Wrap MenuItems in a valid container */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ width: "250px" }}>
          {/* Conditionally Render Search Bar */}
          {!noSearch && (
            <Box sx={{ padding: "8px" }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder={`Search ${label.toLowerCase()}...`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}

          {!noSearch && <Divider />}

          {/* Loading / No Results / Options List */}
          {loading ? (
            <MenuItem disabled>Loading...</MenuItem>
          ) : options.length === 0 ? (
            <MenuItem disabled>No results found</MenuItem>
          ) : (
            <List>
              {options
                .filter((option) =>
                  noSearch
                    ? true
                    : option.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((option) => (
                  <ListItem
                    sx={{
                      width: "100%", // ✅ Makes sure the item stretches full width
                      minWidth: "150px", // ✅ Ensures a reasonable width
                    }}
                    key={option.id}
                    onClick={() => handleSelect(option.id, option.name)}
                    className={styles.listItem}
                  >
                    <ListItemText primary={option.name} />
                  </ListItem>
                ))}
            </List>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

export default DropdownFilter;
