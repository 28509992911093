import { Box, Card, CardContent, Container, Typography } from "@mui/material";

const AuthenticationErrorPage = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        px: 2, // Padding for smaller screens
      }}
    >
      {/* Friendly Message at the Top */}
      <Box sx={{ width: "100%", textAlign: "center", py: 2 }}>
        <Typography variant="body1" fontWeight="bold" color="primary">
          Oops! Looks like we need a quick check.
        </Typography>
      </Box>

      {/* Main Card */}
      <Card sx={{ textAlign: "center", p: 3, boxShadow: 3, width: "100%" }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Almost there! 🚀
          </Typography>
          <Typography variant="body1">
            It looks like we need a little extra step to confirm your access. No
            worries! Just check in with your administrator, and they’ll help
            sort this out in no time.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AuthenticationErrorPage;
