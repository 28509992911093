import { ITenant } from "interfaces/graphql";

export const emptyCurrentTenant: ITenant = {
  externalId: "",
  statuses: [],
  id: "",
  logoUrl: "",
  name: "",
  ordersEmail: "",
  ordersEmailName: "",
};

export const roleMapping: Record<
  string,
  {
    label: string;
    color: "success" | "info" | "warning" | "primary" | "secondary";
  }
> = {
  practice_owner: { label: "Practice Owner", color: "success" },
  practicioner: { label: "Practitioner", color: "primary" },
  practise_manager: { label: "Practice Manager", color: "info" },
  staff_member: { label: "Staff Member", color: "warning" },
};
